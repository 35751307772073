@import '/src/styles/colors/primary.scss';

.ModalBody__NoContentModal {
  padding: 9px 24px 24px 24px;

  margin: 24px 20px;

  height: 500px;
  overflow-y: auto;

  background: #f2fbfb;
  border-radius: 8px;

  div {
    margin-bottom: 10px;
  }

  h1 {
    font-family: 'Inter' !important;
    font-weight: 400;
    font-style: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #101828;
    margin-bottom: 10px;

    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }
  h2 {
    font-family: 'Inter' !important;
    font-weight: 400;
    font-style: normal;
    // font-weight: 600;
    font-size: 24px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;

    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }
  h3 {
    font-family: 'Inter' !important;
    font-weight: 400;
    font-style: normal;
    font-size: 18.72px;
    line-height: 38px;
    color: #101828;

    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }

  p {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #475467;
    text-align: justify;
    word-spacing: -0.1em;
  }

  li {
    font-family: 'Inter' !important;
    list-style: disc;
    color: #475467;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  ul {
    padding: 0 2rem;
    font-family: 'Inter' !important;
    font-weight: 400;
    list-style: disc;
  }
}
.contentWrapper {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.Button {
  padding: 10px 18px;
  border-radius: 8px;
}
.Button__light {
  border: 1px solid #d0d5dd;
}

.textarea_subtitle {
  color: gray;
}
