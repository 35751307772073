.loader__wrapper {
  h3 {
    color: #000000;
  }
  p {
    color: #475467;
  }
}
.preloader {
  &__textSection {
    padding-top: 24px;
  }
}
#ts-preloader-absolute14 {
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // height: 150px;
  // width: 150px;
  // margin-top: -75px;
  // margin-left: -75px;
}
.ts_preloading_box {
  min-width: 592px;
  min-height: 192px;
  display: flex;
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.backdrop-loader {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0.4;
  z-index: 999;
  user-select: none;
  pointer-events: none;
}
.tsperloader14 {
  width: 20px;
  height: 20px;
  background-color: #7f56d9;
  float: left;
  margin-right: 20px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
#tsperloader14_one {
  -webkit-animation: tsperloader14_one 1.5s infinite;
  animation: tsperloader14_one 1s infinite;
}
#tsperloader14_two {
  -webkit-animation: tsperloader14_two 1.5s infinite;
  animation: tsperloader14_two 1s infinite;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
#tsperloader14_three {
  -webkit-animation: tsperloader14_three 1.5s infinite;
  animation: tsperloader14_three 1s infinite;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
@-webkit-keyframes tsperloader14_one {
  75% {
    -webkit-transform: scale(0);
  }
}
@keyframes tsperloader14_one {
  75% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
}
@-webkit-keyframes tsperloader14_two {
  75% {
    -webkit-transform: scale(0);
  }
}
@keyframes tsperloader14_two {
  75% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
}
@-webkit-keyframes tsperloader14_three {
  75% {
    -webkit-transform: scale(0);
  }
}
@keyframes tsperloader14_three {
  75% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
}
