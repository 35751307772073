.table_container {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  width: 100%;
}

.styled_table {
  border: none;
  width: 100%;
  //   border-collapse: collapse;
}

.styled_table th {
  text-align: left;
  padding: 16px 16px;
  border: none !important;
  border-radius: 10px;
}

.styled_table th,
.styled_table td {
  text-align: left;
  padding: 16px 16px;
}

.styled_table thead {
  border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0.08);
  border-radius: 10px 10px 0 0;
}
.styled_table thead th {
  color: var(
    --neutral-colors-Gray-neutral-700,
    var(--colors-gray-light-mode-700, #344054)
  );
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.styled_table tbody tr {
  border-bottom: 1px solid #eaecf0;
}

.styled_table tbody tr:last-child {
  border: none;
}

.styled_table tbody td {
  overflow: hidden;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  text-overflow: ellipsis;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 18px !important;
}

.styled_table thead th {
  &:last-child {
    display: flex;
    justify-content: end;
    align-items: end;
  }
}
.styled_table tbody td {
  &:last-child {
    display: flex;
    justify-content: end;
    align-items: end;
  }
}

.action_button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #6b7280;
}
