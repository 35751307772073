.Onboarding_header {
  z-index: 999 !important;
  max-height: 68px;
  background-color: #fff;
  position: sticky;
  top: 0;
  padding: 14px 24px;
  font-family: 'Poppins' !important;
  font-weight: 400;
  font-size: 16px !important;
  border-bottom: 1px solid #e4e7ec;
  justify-content: space-between;
}
