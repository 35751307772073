@import '/src/styles/colors/primary.scss';

.mainTitle {
  color: gray(900);
  font-family: 'Poppins';
  font-weight: 600;
}

.subTitle {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: gray(700);
  max-width: 680px;
  padding-top: 4px;
}
