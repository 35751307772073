.settings_container {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  height: calc(100vh - 205px);
  overflow-y: auto;

  &__form {
    display: flex;
    width: 650px;
    padding: var(--spacing-2xl, 20px) var(--spacing-3xl, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-2xl, 20px);
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);
    padding-bottom: 40px;
    &__heading_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}

@media (max-width: 1280px) {
  .form {
    width: 100%; /* Make the form responsive */
  }
}

@media (max-width: 1260px) {
  .settings_container__form {
    width: 550px; /* Set form width to 600px */
  }
}

@media (max-width: 1024px) {
  .settings_container__form {
    width: 100%; /* Set form width to 600px */
  }
}

.btn_group {
  display: flex;
  flex-direction: column;
  border: 1px solid #eaecf0;
  border-radius: 10px;
  height: fit-content;
  position: sticky;
  top: 0;
  a {
    display: flex;
    text-decoration: none;
    padding: var(--spacing-lg, 12px);
    align-items: center;
    gap: var(--spacing-md, 8px);
    align-self: stretch;
    border: none;
    border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);

    background: var(--neutral-colors-black-white-white, #fff);
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );

    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 176px;
    &:first-child {
      border-radius: 10px 10px 0 0;
    }
    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
}

.active_btn {
  background-color: #00a7b7 !important;
  color: #fff !important;
}

.outer_form_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
