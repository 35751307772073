.documentHeathSection {
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  z-index: 99;
  position: relative;
  h1 {
    font-family: 'Poppins' !important;
    font-weight: 600 !important;
    font-style: normal;
    font-size: 32px;
    margin: 10px 0;
    letter-spacing: -0.02em;
    color: #101828;
  }
  h2 {
    color: var(--Black, #000);
    font-family: 'Poppins' !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin: 10px 0;
  }

  h3 {
    font-family: 'Inter' !important;
    font-style: normal;
    font-size: 18.72px;
    color: #101828;
    margin: 10px 0;
  }
  p {
    font-style: normal;
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'DM Sans' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 10px 0;
  }
  ul {
    list-style: disc;
    li {
      font-style: normal;
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans' !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
  ol {
    list-style: disc;
    li {
      font-style: normal;
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans' !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.menuBar__refresh {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d0d5dd;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.btns__dots {
  border: none;
  background: none;
}
