@import './../../styles/variables/colors';
.TemplateCardWrapper {
  display: flex;
  width: 100%;
  min-height: 142px;
  padding: var(--spacing-xl, 16px);
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--neutral-colors-black-white-white, #fff);
  cursor: pointer;
}
.ImageSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 12px;
  img {
    height: 20px;
    width: 20px;
  }
}
.Articles {
  background: font_clr(Articles-bg);
}
.Descriptions {
  background: font_clr(Description-bg);
}
.PressReleases {
  background: font_clr(PressRelease-bg);
}
.PressRelease {
  background: font_clr(PressRelease-bg);
}
.Captions {
  background: font_clr(Captions-bg);
}
.Letters {
  background: font_clr(Letters-bg);
}
.Pitches {
  background: font_clr(Pitches-bg);
}
.Emails {
  background: font_clr(Email-bg);
}
.cardHeadingText {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardDetailsText {
  margin-top: 8px;
  color: var(
    --neutral-colors-Gray-neutral-600,
    var(--colors-gray-light-mode-600, #475467)
  );
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-overflow-style: none !important;
  // / IE and Edge /
  scrollbar-width: none !important;
  // / Firefox /

  &::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 768px) {
  .TemplateCardWrapper {
    width: 264px;
  }
}
