.toolkit {
  padding: 100px 0;
  // mobile
  @media (max-width: 625px) {
    padding: 32px 0;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 1006px;
    margin: 0 auto;
    @media (max-width: 1280px) {
      width: 100%;
      margin: 0;
      padding: 0 88px;
    }
    @media (max-width: 625px) {
      padding: 0 24px;
    }
    &__heading {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins';
      font-size: 60px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
      letter-spacing: -1.2px;
      @media (max-width: 625px) {
        font-size: 32px;
        line-height: 40px !important;
      }
    }
    &__description {
      color: var(
        --neutral-colors-Gray-neutral-600,
        var(--colors-gray-light-mode-600, #475467)
      );
      text-align: center;
      font-family: 'DM Sans';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      @media (max-width: 1280px) {
        text-align: left;
      }
    }
  }

  &__grid {
    display: flex;
    justify-content: center;
    gap: 72px;
    margin-top: 56px;
    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__left {
      display: flex;
      width: 362px;
      padding: 20px 0px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      @media (max-width: 1200px) {
        width: 100%;
      }
      &__navigation {
      }
      &__button_section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: center;
        @media (max-width: 1200px) {
          display: none;
          text-align: center;
          &__btn {
            margin-left: auto;
            margin-right: auto;
          }
        }
        &__text {
          color: var(
            --neutral-colors-Gray-neutral-600,
            var(--colors-gray-light-mode-600, #475467)
          );
          font-family: 'DM Sans';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
        &__btn {
          display: flex;
          height: 56px;
          padding: 16px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
          color: var(--Colors-Text-text-white, #fff);
          font-family: 'Inter';
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          border: none;
          max-width: fit-content;
        }
      }
    }
    &__right {
      height: 612px;
      width: 592px;
      border-radius: 24px;
      border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
      background: linear-gradient(312deg, #fff 19.59%, #ffeaea 100%);
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: auto;
      @media (max-width: 1200px) {
        width: calc(100% - 176px);
        margin: auto;
      }
      @media (max-width: 625px) {
        width: calc(100% - 48px);
        margin: auto;
      }
      &__header {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        &__heading {
          color: var(
            --neutral-colors-Gray-neutral-800,
            var(--colors-gray-light-mode-800, #182230)
          );
          font-family: 'Poppins';
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }
        &__text {
          color: var(
            --neutral-colors-Gray-neutral-800,
            var(--colors-gray-light-mode-800, #182230)
          );
          font-family: 'DM Sans';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      &__image {
        height: 395px;
        width: 551px;
        margin: auto auto 0;
        @media (max-width: 1200px) {
          width: 100%;
        }
        &__video {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 24px;
          @media (max-width: 1200px) {
            object-fit: contain;
          }
        }
      }
      &__new {
        border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
        background: linear-gradient(45deg, #fff 3.71%, #faffde 98.32%);
      }
    }
  }
}

.visible_on_mobile {
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    width: 100%;
    padding: 0 88px;
  }
  @media (max-width: 625px) {
    display: flex;
    width: 100%;
    padding: 0 24px;
  }
}
