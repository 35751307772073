@import '/src/styles/functions/@mixin-module';
@import '/src/styles/variables/colors';

.KanbanContainer {
  position: relative;
  min-width: 360px;
  height: fit-content;
  animation: slideDown 0.7s ease-in-out;
  height: calc(100vh - 153px);
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid #e3e8ef;
  gap: 12px;
  &:first-child {
    border-left: none;
  }
  @keyframes slideDown {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &__Header {
    padding: 16px;
    position: sticky;
    top: 0;
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);
    box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0.08);
    svg {
      cursor: pointer;
    }
    p {
      @include text-md-lh24;
      color: font_clr(grey-800);
      font-family: 'Poppins';
      line-height: 20px !important;
    }
    span {
      @include text-md;
      font-family: 'Inter';
      font-weight: 400;
      color: font_clr(grey-800);
    }
  }

  &__AddNewBtn {
    @include place-holder-styles;
    background-color: #f9f9f9;
    min-height: 36px;
    width: calc(100% - 50px);
    color: #1d2939;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
  }
}

.innerCradContainer {
  height: calc(100vh - 240px);
  padding: 2px 20px;
  overflow-y: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  gap: 12px;
  &::-webkit-scrollbar {
    display: none;
  }
  .KanbanCard_Card__nIVh3 {
    border: 1px solid #eaecf0;
    display: none !important;
  }
}

.count {
  display: flex;
  min-width: 24px;
  width: fit-content;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  background: var(--colors-gray-light-mode-100, #f2f4f7);
  color: var(
    --neutral-colors-Gray-neutral-700,
    var(--colors-gray-light-mode-700, #344054)
  );
  padding: 4px;
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
