.ShareModalHeading {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.collaboratorSelect {
  width: fit-content;
  background: #f2f4f7;
  border-radius: 16px;
  font-size: 14px;
  color: #344054;
  span {
    display: none;
  }
  font-weight: 500;

  &__single-value {
    span {
      display: none;
    }
  }
}
.OptionsHeading {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  &__remove {
    color: #b42318;
    cursor: pointer;
  }
}
.OptionRole {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #101828;
}
.InviteInputSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  input {
    border: none;
    // width: 258px;
  }
}

.Input__Icon {
  background-color: transparent;
  border: none;
}
.GroupedInputs {
  justify-content: flex-start;
  max-width: 100%;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin: 10px;
}
.DialogWrapper {
  max-width: 100%;
}
.labelsDropDowns {
  padding: 8px;
  width: 100%;
}
.SendBtn {
  background: #101828;
  border: 1px solid #101828;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 18px;
  font-size: 16px;
  color: #ffffff;
}
.Collaborator {
  padding-top: 18px;
  font-family: 'Inter';
  font-weight: 400;
  &__Info {
    gap: 12px;
    &_Name {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #344054;
    }
    &_Email {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
    }
  }
}
.ShareModalFooter {
  justify-content: space-between;
}
.CopyLink {
  font-family: 'Inter';
  font-weight: 500;
  display: flex;
  gap: 8px;
  color: #00a7b7;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  user-select: none;
}
.linkLabel {
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  user-select: none;
  color: #667085;
}

.inputWrapperClass {
  width: auto !important;
}

.InviteButtonSection {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

@media (min-width: 768px) {
  .DialogWrapper {
    max-width: 430px;
  }
  .InviteButtonSection {
    flex-direction: row;
    justify-content: space-between;
  }
}
