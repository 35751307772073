.heading {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.desc {
  color: var(
    --neutral-colors-Gray-neutral-600,
    var(--colors-gray-light-mode-600, #475467)
  );
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
