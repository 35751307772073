@import '../../../../styles/variables/colors';

.DropDownlistItem {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  width: 276px;
  height: 48px;

  img {
    width: 32px;
    object-fit: scale-down;
  }

  @media screen and (min-width: 340px) and (max-width: 768px) {
    width: fit-content !important;
    gap: 8px;
    padding: 4px 8px;
    font-size: 12px !important;
    height: fit-content;

    img {
      width: 12px !important;
    }
  }

  &:hover {
    background-color: font_clr(list_bg);
    border-radius: 8px;
  }
}

.DropDownlistItemLink {
  text-decoration: none;
  color: #212529;

  &:hover {
    color: #212529;
  }
}

.active_listItem {
  background-color: font_clr(list_bg);
  border-radius: 8px;
}
