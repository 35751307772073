@import '/src/styles/colors/primary.scss';
@keyframes BackToOnboarding {
  0% {
    transform: translateY(100%);
  }
  80% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

.BackToOnboarding {
  display: flex;
  width: 409px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 8px;
  background: #f2fbfb;
  box-shadow: 0px 4px 12px -4px rgba(16, 24, 40, 0.16);

  animation: 1.5s BackToOnboarding ease-in-out;

  &:hover {
    transform: translateY(0);
  }

  &_Title {
    flex: 1 0 0;
    color: #2c3e50;

    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  &_BackButton {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    font-weight: 200 !important;

    border-radius: 8px;
    border: 1px solid #00a7b7;
    background: #00a7b7;

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: white;
  }

  &_CloseButton {
    color: gray(500);
    opacity: 0.3;
  }
}
