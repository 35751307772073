.Templates {
  gap: 16px;
  // padding: 24px;
  &_InputSection {
    position: relative;
    display: flex;
    width: 100%;
    padding: 12px 24px;
    svg {
      stroke: #667085;
      filter: brightness(100%);
    }
  }
  &_Tabs {
    padding: 0.5rem 1.5rem;
    button: {
      padding: 0.5rem 1rem;
    }
  }
  @media (min-width: 768px) {
    &_InputSection {
      position: absolute;
      top: 10px;
      right: 24px;
      width: fit-content;
    }
    &_Tabs {
      padding: 1rem 1.5rem;
      button: {
        padding: 1.5rem 1rem;
      }
    }
  }
}
