@import '../../styles/base/fonts';
@import '../../styles/base/typography';
@import '/src/styles/colors/primary.scss';
@import '../../styles/functions/@mixin-module';

.Dashboard {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  &__Header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: fontsize('s12');
    background: white;
    top: 68px;
    background-color: #f8f1e4;
    padding: 20px 24px;

    &_Greets {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 4px;

      &_Date {
        color: #101828;
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &_Greeting {
        @include Heading-lg-medium;
        font-family: 'Poppins';
        font-size: 24px;
        line-height: 32px;
        color: gray(900);
      }
      &_Subtitle {
        @include text-18-lh28;
        font-weight: 400;
        color: gray(700);
      }
    }

    &_Navigation {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 8px;

      &_Select {
        max-width: 170px;
        min-width: max-content;
      }

      &_DocumentsDetails {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-direction: column;
        width: 100%;
        @media screen and (min-width: 1024px) {
          flex-direction: row;
          justify-content: space-between;
        }

        &_Status {
          padding: 16px;
          background: #fff;
          border-radius: 12px;
          @include text-md;
          color: gray(700);
          cursor: pointer;
          font-family: 'DM Sans';

          // &:hover {
          //   filter: brightness(95%);
          // }
          > * {
            color: inherit;
            text-decoration: none;
            &:hover {
              color: inherit;
            }
          }

          &_Documents {
            background-color: #fff;
            display: flex;
            align-items: center;
            gap: 12px;
            &__card {
              display: flex;
              flex-direction: column-reverse;
              & > strong {
                font-family: 'Poppins';
                line-height: 25px;
              }
            }
          }
          &_Briefs {
            background-color: #fff;
          }
        }
      }
    }

    &_Documents {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }
  }

  &__Banner {
    padding: 10px 14px 10px 12px;
    background: linear-gradient(
      121.93deg,
      rgba(0, 168, 183, 0.151) 3.55%,
      rgba(0, 168, 183, 0.555) 87.37%
    );
    border-radius: 8px;
    margin-top: 24px;

    &_Text {
      color: gray(900);
    }

    &_Btn {
      padding: 8px 14px;
    }
  }

  &__TableContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 0 0;
    min-width: 400px;
    min-height: 100%;
    padding: 24px 16px 16px 16px;
    border-radius: 8px;
    animation: OpacityBreak 0.7s ease-in-out;
    background: gray(50);
    font-family: 'Poppins';

    @keyframes OpacityBreak {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    &_Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      &_TitleContainer {
        display: flex;
        width: 373.5px;
        align-items: center;
        gap: 8px;
        &_Title {
          @include text-18-lh28;
          font-weight: 600;
          color: gray(900);
          margin-right: 1rem;
          font-family: 'Poppins';
        }
      }

      &_Navigation {
        cursor: pointer;
        @include text-md;
        color: #d0d5dd;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        text-decoration: none;
        &:hover {
          color: #00a7b7;
        }
      }
    }

    &_Table {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      th:first-child {
        text-align: left;
        padding-left: 12px;
      }
      th {
        text-align: center;
      }
      th:last-child {
        text-align: right;
        padding-right: 12px;
      }

      &_Header {
        @include text-sm-lh18;
        font-family: 'Poppins';
        font-weight: 600;
        color: #667085;
      }

      &_Row {
        cursor: pointer;
        vertical-align: middle;
        background: white;

        &:hover {
          background-color: gray(25);
        }
        td:first-child {
          padding: 12px 6px 12px 12px;
          border-radius: 4px 0px 0px 4px;
          text-align: left;
          max-width: 1px;
        }
        td {
          max-height: 44px !important;
          padding: 12px 6px;
          width: 1px;
        }
        td:last-child {
          padding: 12px 12px 12px 6px;
          border-radius: 0 4px 4px 0;
          text-align: right;
        }
        &_Spacer {
          height: 4px;
        }
        &_Name {
          @include text-md;
          font-weight: 500;
          color: gray(900);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: 'DM Sans';
        }

        &_Date {
          @include text-md;
          font-weight: 400;
          color: #344054;
          font-size: 12px;
          font-family: 'DM Sans';
        }
      }
    }
  }

  &__EmptyDashboardState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 144px;
    padding: 20px;
    gap: 16px;
    border-radius: 8px;
    // box-shadow: 0px 4px 12px -4px rgba(16, 24, 40, 0.16);
    margin: 20px 0;
    background: white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    &_Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: gray(700);

      &_Title {
        @include Heading-sm;
        font-weight: 700;
        text-align: center;
        font-family: 'Poppins';
      }
      &_Description {
        @include text-md;
        font-weight: 400;
        text-align: center;
        font-family: 'DM Sans';
      }
    }

    &_Button {
      @include text-md;
      padding: 8px 14px;
    }
  }

  &__Stepper {
    padding: 24px;
    background: primary(25);
    border: 1px solid gray(200);
    border-radius: 8px;
  }

  &__UserStep {
    padding: 12px;
    background: #ffffff;
    border: 1px solid primary(600);
    transition: box-shadow 0.2s ease-in;

    &:focus {
      box-shadow:
        0px 1px 2px rgba(16, 24, 40, 0.05),
        0px 0px 0px 4px primary(200);

      .Dashboard__UserStep__Icon {
        background-color: primary(50);

        svg {
          stroke: primary(700);

          path {
            stroke: primary(700);
          }
        }
      }
    }

    border-radius: 8px;

    &__Icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 8px;
      gap: 8px;
      width: 36px;
      height: 36px;
      background: gray(50);
      border-radius: 8px;
    }

    &_item {
      cursor: pointer;
      padding: 8px 14px;
      width: fit-content;
      border: 1px solid #f2fbfb;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: gray(500);
      @include text-md;

      &:hover {
        background: #f2fbfb;
        color: primary(700);

        svg {
          stroke: primary(700);
        }
      }

      &active {
        background: #f2fbfb;
        color: primary(700);

        svg {
          stroke: primary(700);
        }
      }
    }

    &_stepNumber {
      width: 20px;
      height: 20px;
      background: #ffffff;
      border: 1px solid gray(300);
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Inter';
      font-weight: 700;
      font-size: 11px;
      line-height: 20px;
    }

    &_active {
      background-color: #f2fbfb;

      .Dashboard__UserStep__Icon {
        background-color: #f2fbfb;

        svg {
          stroke: primary(700);

          path {
            stroke: primary(700);
          }
        }
      }
    }
  }

  &_NoContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;

    span {
      color: primary(700);
    }

    p {
      color: gray(500);
    }

    &_Btn {
      cursor: pointer;
    }
  }

  &__Priorities {
    border: 1px solid gray(200);
    border-radius: 8px;
    padding: fontsize('s24');
    animation: OpacityBreak 0.7s ease-in-out;
    @keyframes OpacityBreak {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    &_Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_Heading {
        @include Modal-Title;
        font-size: fontsize('s24');
        color: gray(900);
      }
      &_Navigation {
        display: flex;
        cursor: pointer;
        @include text-md;
        color: gray(500);
        font-weight: 700;
        gap: fontsize('s34');

        &Text {
          color: primary(700);
        }
      }
    }
  }

  &__compaigns {
    padding: 20px 12px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
  @media (min-width: 768px) {
    &__compaigns {
      grid-template-columns: repeat(4, 1fr);
      padding: 20px 24px;
      gap: 16px;
    }
    &__Header {
      &_Documents {
        flex-direction: row;
      }
    }
    &__Header {
      &_Documents {
        flex-direction: row;
      }
    }
  }
}

.label {
  width: fit-content;
  padding-left: 12px;

  span {
    @include text-md;
    color: gray(700);
    font-family: 'Poppins';
  }
}

.Dashboard__UserStep__Focus {
  box-shadow:
    0px 1px 2px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px #00a8b71c;

  .Dashboard__UserStep__Icon {
    background-color: #f2fbfb;

    svg {
      stroke: primary(700);

      path {
        stroke: primary(700);
      }
    }
  }
}

.add_collabrators {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  border: 2px solid var(--Colors-Base-white, #fff);
  background: var(--Colors-Base-white, #fff);
  cursor: pointer;
  font-family: 'Poppins';
}
.divider {
  width: 1px;
  height: 16px;
  margin-bottom: 8px;
  background: var(--colors-gray-light-mode-300, #d0d5dd);
}
.divider_noavatar {
  width: 1px;
  height: 24px;
  background: var(--colors-gray-light-mode-300, #d0d5dd);
}

.dashed_border {
  display: flex;
  padding: var(--spacing-xl, 16px) var(--spacing-2xl, 20px);
  justify-content: center;
  align-items: center !important;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  border-radius: 12px;
  border: 1px dashed var(--Brand-Colors-Secondary-Sunset-600, #f8c864);
  background: var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
  @media screen and (max-width: 1440px) {
    padding: 8px 12px;
  }
}

.rounded_plus_btn {
  background-color: #1d2939;
  border-radius: 100%;
  position: absolute;
  bottom: 1vh;
  right: 14px;
  width: 65px;
  height: 65px;
  color: white;
  button {
    background-color: #1d2939;
    height: 65px;
    width: 65px;

    border-radius: 100%;
    &:active,
    &:visited,
    &:focus,
    &:focus-visible {
      background-color: #1d2939;
      height: 65px;
      width: 65px;
      border-radius: 100%;
      color: white;
    }
    & > .btn.show {
      color: white !important;
    }
  }

  div {
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);
    box-shadow: 0px 12px 24px -8px rgba(16, 24, 40, 0.2);
    margin-right: 1rem;
    transform: translate(-105px, -66px) !important;
  }
  @media (min-width: 768px) {
    bottom: 8vh;
  }
}

.list_item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 8px;
  background: var(--Colors-Base-white, #fff);
  span {
    font-family: 'Poppins' !important;
  }
}

.list_item:not(:last-child) {
  border-bottom: 1px solid var(--colors-gray-light-mode-100, #f2f4f7);
  box-shadow: 0px 12px 24px -8px rgba(16, 24, 40, 0.2);
}

.list_item:not(:first-child) {
  padding-top: 12px;
}

.list_item:not(:last-child) {
  padding-bottom: 12px;
}

.list_item:hover {
  background-color: white;
}

.plus_icon {
  height: 30px;
  width: 30px;
  transition: transform 0.3s ease;
}

[aria-expanded='true'] .plus_icon {
  transform: rotate(-45deg);
}
