.imgTicker {
  display: flex;
  gap: 6px;
  margin-bottom: 20px;
}

.tickerlogo {
  width: 8rem;
  flex: none;
  align-self: flex-start;
  max-width: 100%;
  height: auto;
}

.ticker_item {
  display: inline-block;
}
