.black_btn {
  background-color: #1d2939;
  border: 1px solid #1d2939;
  &:hover {
    background-color: #1d2939 !important;
    border: 1px solid #1d2939 !important;
    color: #fff;
  }
  &:active {
    background-color: #1d2939 !important;
    border: 1px solid #1d2939 !important;
    color: #fff !important;
  }
}
