.tab {
  display: flex;
  width: 320px;
  height: 220px;
  padding: var(--spacing-4xl, 32px) var(--spacing-3xl, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--spacing-lg, 12px);
  cursor: pointer;
}
.url {
  border: 1px solid var(--Brand-Colors-Secondary-Sunset-300, #feeacd);
  background: var(--Brand-Colors-Secondary-Sunset-100, #fff9f0);
}
.file {
  border: 1px solid var(--Brand-Colors-Secondary-Periwinkle-300, #d0deff);
  background: var(--Brand-Colors-Secondary-Periwinkle-50, #f4f7ff);
}
.text {
  border: 1px solid var(--Color-Bg, #e3ebff);
  background: var(--Brand-Colors-Secondary-Moonstone-100, #f2fbfb);
}

.heading {
  color: var(--Colors-Text-text-secondary_hover, #182230);
  text-align: center;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 12px;
  margin-bottom: 6px;
}

.description {
  color: var(
    --neutral-colors-Gray-neutral-500,
    var(--colors-gray-light-mode-500, #667085)
  );
  text-align: center;
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.tabs_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-direction: column;
  padding-bottom: 32px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.active {
  &.url {
    border: 2px solid #f8c864;
  }

  &.file {
    border: 2px solid #528bff;
  }

  &.text {
    border: 2px solid #00a7b7;
  }
}
