@keyframes ticker-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-60rem);
  }
}

.leftToRight {
  animation: ticker-left 10s linear infinite;
}

.CustomerReview {
  background-image: url('../../../../public/Images/CustomerReviewBG.svg');
  background-origin: content-box;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 70px 0 100px !important;
  gap: 60px;

  @media (max-width: 768px) {
    padding: 40px 0 !important;
  }
  &__header {
    width: 640px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      width: 100%;
      padding: 0 8px;
    }
    &__heading {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      text-align: center;
      font-family: 'Poppins';
      font-size: 60px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
      letter-spacing: -1.2px;
      margin-top: 24px;
      @media (max-width: 768px) {
        font-size: 32px;
        line-height: 40px !important;
      }
    }
    &__description {
      color: var(
        --neutral-colors-Gray-neutral-600,
        var(--colors-gray-light-mode-600, #475467)
      );
      text-align: center;
      font-family: 'DM Sans';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin-top: 16px;
    }
  }
}

.QuoteIcon {
  width: 56px;
  height: 56px;
  padding: 16px;
  background-color: #fddcab;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.ReviewCard {
  display: flex;
  width: 234.001px;
  height: 234px;
  // transform: rotate(15deg);
  padding: 20.8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 13px;
  background: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
  &__text {
    font-family: 'Poppins' !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 19.5px !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 130px;
    width: 100%;
  }
  &[data-color='sunset'] {
    background-color: #fbb956;
    // position: absolute;
    // transform: rotate(-15deg);
    // top: 20%;
    // left: -300px;

    span,
    p {
      color: #101828;
    }

    svg {
      color: #ffffff;
    }
  }

  &[data-color='white'] {
    background-color: #ffffff;
    border: 1px solid #fddcab;
    // position: absolute;
    // transform: rotate(-11.706deg);
    // left: -70px;
    // top: 28%;

    svg {
      color: #f99806;
    }
  }

  &[data-color='periwinkle'] {
    background-color: #c7d6fe;
    // position: absolute;
    // transform: rotate(-11.21deg);
    // left: 50%;

    svg {
      color: #f99806;
    }
  }

  &[data-color='moonstone'] {
    background-color: #00a7b7;
    // position: absolute;
    // transform: rotate(15deg);
    // left: 300px;
    // top: 17%;

    span {
      color: #ffffff;
    }

    p {
      color: #99f6ff;
    }

    svg {
      color: #f99806;
    }
  }
}

.stars_container {
  display: flex;
  align-items: center;
  gap: 2.3px;
}

.name {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'DM Sans' !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18.2px !important;
}

.review_sec {
  // width: 100%;
  // overflow-x: hidden;

  &__image_container {
    display: flex;
    justify-content: center;
    gap: 16px;
    // position: relative;
    // z-index: 0;
  }
}
