@import '/src/styles/colors/primary.scss';

.TableHeadTitle {
  // display: flex;
  align-items: center;
  width: 100%;
  gap: 4px;
  color: gray(400);
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.table_header {
  width: 100%;
  padding: 16px 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0.08);
}
.customHeader {
  // width: 100%;
  padding: 16px 24px !important;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0.08);
}
.TableHeadTitle {
  color: var(
    --neutral-colors-Gray-neutral-700,
    var(--colors-gray-light-mode-700, #344054)
  );
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 4px;
}
/* Add this to your TableHead.module.scss file */

.sortableColumn {
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.TableHeadTitle {
  display: flex;
  align-items: center;
  
  /* Gap between icon and text */
  > span + span {
    margin-left: 8px;
  }
  
  /* Style for sort icons */
  svg {
    margin-left: 8px;
  }
}c
// Columns Hide and show modal
.outer_container {
  display: inline-flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2xl, 20px);
  border-radius: 8px;
  min-width: 280px;
  // border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  // box-shadow: 0px 12px 24px -8px rgba(16, 24, 40, 0.2);
  &__title {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    text-align: left;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}
