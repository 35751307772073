.DocumentContainer {
  width: 100%;
  padding: 24px;
  border-right: 1px solid #e4e7ec;

  &_Header {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    &_Title {
      display: flex;
      align-items: flex-start;

      svg {
        cursor: pointer;
      }
    }
  }
}

.DocumentContainer_Header_Title {
  display: flex;
  gap: 12px;
}

.VersionDiff {
  margin-top: 24px;
  padding: 24px;
  background: #f7f7f7 !important;
  border-radius: 8px;
}

.VersionHistory {
  padding: 24px 12px;
  min-width: 330px;
  overflow-x: hidden !important;
}
