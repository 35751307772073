  .modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }
  
  .modal-button {
    padding: 10px 20px;
    border: none;
    margin-left: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .modal-button:hover {
    background-color: #bbbbbb; /* Darker shade */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  