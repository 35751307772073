.ModalBody__PickTemplateModal {
  padding: 20px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.contentWrapper {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.ModalFooter {
  padding: 0px 16px 16px 16px;
  border: none;
  justify-content: space-between;
}
.Button {
  padding: 10px 18px;
  border-radius: 8px;
  width: 48%;
}
.Button__light {
  border: 1px solid #d0d5dd;
}

.textarea_subtitle {
  margin-top: 8px;
  color: #667085;
}
.AIMessage {
  padding: 8px 12px;
  height: 44px;
  background: #f2f4f7;
  border-radius: 18px;
  position: relative;
  &::before {
    content: url('../../../../public/Images/Chatbox-clip.svg');
    position: absolute;
    bottom: -9px;
    left: -2px;
  }
}
