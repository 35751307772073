.FolderView {
  padding: 24px 24px 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  animation: OpacityBreak 0.7s ease-in-out;
  @media (min-width: 1660px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @keyframes OpacityBreak {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
