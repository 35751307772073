@keyframes loaderSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.SpinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 16px;
  padding: var(--spacing-2xl, 20px) var(--spacing-3xl, 24px);
  background-image: url('../../assets/svg/loading.svg');
  background-size: cover;
  svg {
    animation: loaderSpinner 1.5s infinite linear;
  }
  &__inner {
    background: white;
    display: flex;
    gap: 16px;
    align-items: center;
    width: 245px;
  }
}

.SpinnerText {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  // line-height: 20px;
  color: #344054;
  font-family: 'DM Sans';
}
