.badge {
  display: flex;
  @media (max-width: 1200px) {
    display: none;
  }
  padding: var(--spacing-sm, 6px) var(--spacing-xl, 16px) var(--spacing-sm, 6px)
    var(--spacing-lg, 12px);
  align-items: center;
  gap: var(--spacing-sm, 6px);
  border-radius: 9999px;
  background: var(--Brand-Colors-Secondary-Moonstone-200, #e2fbfb);
  p {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  &__btn {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &__btn_child {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 6px;
    color: #667085;
    p {
      color: #667085;
    }
  }
}
