.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.fullWidth > div > div > div {
  width: 100% !important;
}
.fullWidth > div > div > div > div > div::after {
  display: none !important;
}

.btn_container {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
}

.TimePicker_container {
  position: relative;
  width: 100%;
}

.TimePicker_container > div > div > div {
  width: 100%;
}
