.contenttoll {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 58px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }

  &__heading {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    // desktop
    @media (min-width: 1200px) {
      font-size: 48px;
      line-height: 60px !important;
    }
    font-size: 32px;
    line-height: 40px !important;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.96px;
    width: 662px;
    @media (max-width: 1200px) {
      width: 100%;
    }
    @media (max-width: 625px) {
      padding: 0 24px;
    }
  }
  &__right_box {
    position: relative;
    width: 718px;
    height: 125px;
    padding: 24px;
    border-radius: var(--spacing-xl, 16px);
    border: 1px solid var(--Brand-Colors-Secondary-Periwinkle-300, #d0deff);
    background: var(--Colors-Base-white, #fff);
    color: var(
      --neutral-colors-Gray-neutral-800,
      var(--colors-gray-light-mode-800, #182230)
    );
    font-family: 'DM Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    @media (max-width: 1200px) {
      height: 100%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 625px) {
      padding: 0 24px;
    }

    &__after_effect {
      background-color: #d0deff;
      padding-left: 4px;
      padding-right: 10px;
    }
    &__cursor {
      display: inline-flex;
      position: absolute;
      bottom: 7%;
    }
    &__avatar {
      margin-left: 90px;
    }
  }
}

.frame_image {
  width: 100%;
  height: 702.66px;
  @media (max-width: 1200px) {
    height: 100%;
  }

  video {
    margin: 0 !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.section_responsive {
  @media (max-width: 625px) {
    padding: 24px;
  }
}
