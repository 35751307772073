.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 32px;

  &__container {
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;

    &__step {
      height: 8px;
      flex: 1 0 0;
      border-radius: 999px;
      background: var(--colors-gray-light-mode-100, #f2f4f7);

      &.active,
      &.completed {
        background: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
      }
    }
  }
}
