@import '/src/styles/colors/primary.scss';

.TableData {
  color: gray(900);
  padding: 12px 24px !important;

  &_Title {
    vertical-align: middle;
    padding: 16px 24px !important;

    @media screen and (max-width: 1200px) {
      max-width: 330px;
    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 14px;
    color: #667085;
    line-height: 20px;

    > span {
      margin-right: 8px;
    }

    &_Items {
      color: gray(500);
    }

    // &:hover {
    //   .TableData_Title__edit_icon {
    //     visibility: visible;
    //   }
    // }
    &__edit_icon {
      visibility: hidden;
      display: flex;
      width: 28px;
      height: 28px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid var(--colors-gray-light-mode-300, #d0d5dd);
      background: var(--Colors-Base-white, #fff);
      padding: 6px;
      cursor: pointer;
      color: #101828;
      &:hover,
      &:active {
        color: #101828;
      }
    }
    &__edit_btn {
      visibility: hidden;
      display: flex;
      height: 32px;
      padding: 8px 14px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid var(--colors-gray-light-mode-300, #d0d5dd);
      background: var(--Colors-Base-white, #fff);
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
  }

  &_Collaborators {
    display: flex;
    align-items: center;
    height: 100%;

    &_Avatar {
      width: 32px;
      height: 32px;
      font-size: 10px;
    }
  }

  &_KanbanTitle {
    cursor: pointer;
    padding: 16px 24px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 420px;
    min-width: 280px;

    *:hover {
      color: skyblue;
    }
  }

  &_ListTitle {
    vertical-align: middle;
    white-space: nowrap;
    &:hover {
      color: #00a7b7 !important;
    }
  }

  &__Extensive {
    max-width: 100% !important;
    border-bottom: none;
  }

  &_Link {
    cursor: pointer;
    color: #00a7b7;
    user-select: none;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-mode-300, #d0d5dd);
    background: var(--Colors-Base-white, #fff);
    color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
    text-align: center;
    font-family: 'DM Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
    width: 100px;
    &:hover {
      color: #00a7b7;
    }
  }
}

.TableRow {
  border-color: gray(200);
  vertical-align: middle;
  color: gray(900);
  border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);

  &_Star {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg:hover {
      fill: #ffcf51;
      stroke: #ffcf51;
    }
  }

  &_StarActive {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      fill: #ffcf51;
      stroke: #ffcf51;
    }
  }

  &_Name {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    svg {
      color: #00a7b7;
    }
  }
}
/* Add these styles to your TableBody.module.scss file */

.editableCell {
  position: relative;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: rgba(0, 123, 255, 0.05);
    
    .editHint {
      opacity: 1;
    }
  }
}

.editHint {
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.2s ease;
  white-space: nowrap;
  pointer-events: none;
  z-index: 10;
}

.inlineEditWrapper {
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  min-width: 200px;
  
  button {
    font-size: 12px;
    padding: 3px 8px;
  }
  
  /* Handle dropdown positioning */
  :global(.dropdown-menu) {
    min-width: 100%;
  }
  
  /* Style for save button */
  :global(.btn-save) {
    background-color: #202020;
    color: white;
    border-color: #202020;
    
    &:hover {
      background-color: #000000;
      border-color: #000000;
    }
    
    &:disabled {
      background-color: #6c757d;
      border-color: #6c757d;
      opacity: 0.65;
    }
  }
}

/* Ensure the editing form has enough room */
.TableData {
  &:has(.inlineEditWrapper) {
    overflow: visible !important;
    z-index: 500; /* Increased z-index to ensure visibility */
    position: relative;
  }
}

/* Make editing form fit into the space properly */
.inlineEditWrapper {
  width: 250px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -5px;
}
.TableData_Hidden_icon:hover {
  .TableData_Title__edit_icon {
    visibility: visible;
  }
  .TableData_Title__edit_btn {
    visibility: visible;
    display: flex;
    height: 32px;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-mode-300, #d0d5dd);
    background: var(--Colors-Base-white, #fff);
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

//
// Columns Hide and show modal
.outer_container {
  display: inline-flex;
  padding: 7px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2xl, 10px);
  border-radius: 8px;
  min-width: 280px;
  background: var(--Colors-Base-white, #fff);
  border-radius: 8px;

  &__title {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.drop_btn_new > div {
  border-radius: 8px !important;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0) !important;
  border-radius: 8px;
  background: var(--Colors-Base-white, #fff);
  box-shadow: 0px 12px 24px -8px rgba(16, 24, 40, 0.2);
  border: none !important;
}

.TableData_first_column {
  padding-right: 0 !important;
}
.dynamicTitle {
  white-space: nowrap !important;
  overflow: visible !important;
  text-overflow: clip !important;
  max-width: none !important;
  min-width: 0 !important;
}


.TableData_Title_library {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  text-overflow: ellipsis;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
