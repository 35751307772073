.Collaborator {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding-top: 18px;
  font-family: 'Inter';
  font-weight: 400;

  &__Info {
    gap: 12px;
    &_Name {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #344054;
    }
    &_Email {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
    }
  }
}
.labelsDropDowns {
  padding: 8px;
  width: 100%;
}
.OptionsHeading {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  &__remove {
    color: #b42318;
    cursor: pointer;
  }
}
.OptionRole {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #101828;
}

@media (min-width: 768px) {
  .Collaborator {
    flex-direction: row;
  }
}
