.navigation {
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 88px;
  }
  @media (max-width: 625px) {
    padding: 0 24px;
    width: 100%;
  }

  &__btn {
    padding: 16px;
    border-radius: 12px;
    border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #fff;
    width: 100%;
    cursor: pointer;
    transition:
      background-color 0.3s,
      border-color 0.3s;

    &__text {
      color: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
      font-family: 'DM Sans';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      // mobile
      @media (max-width: 625px) {
        font-size: 16px;
      }
    }

    &__badge {
      display: flex;
      height: 24px;
      padding: 0px var(--spacing-md, 8px);
      justify-content: center;
      align-items: center;
      gap: var(--spacing-xs, 4px);
      border-radius: 9999px;
      background: var(--Colors-Success-100, #dcfae6);
      color: var(
        --Semantic-Colors-Green-600,
        var(--Colors-Success-600, #079455)
      );
      text-align: center;
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      min-width: max-content;
    }

    &--active-0 {
      border: 1px solid var(--Brand-Colors-Secondary-Sunset-400, #fde2b9);
      background: var(--Brand-Colors-Secondary-Sunset-50, #fffbf5);
    }

    &--active-1 {
      border: 1px solid var(--Brand-Colors-Secondary-Periwinkle-300, #d0deff);
      background: var(--Brand-Colors-Secondary-Periwinkle-50, #f4f7ff);
    }

    &--active-2 {
    }

    &--active-3 {
    }
  }
}
