.TitleWrapper {
  gap: 10px;
  padding: 10px 14px;
}
.SaveAsModal {
  &Header {
    padding: 24px 24px 8px 24px;
    border: none;
  }
  &Body {
    gap: 24px;
    padding: 24px;
  }
}
.SaveAsModalBackdrop {
  z-index: 1000;
}
