.CardIntegration {
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  border-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.CardIntegration:last-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
}

.CardImageAndTitle {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 16px;
}

.CardImage {
  height: 32px;
  object-fit: contain;
  width: fit-content;
  white-space: nowrap;
  max-width: 128px;
}

.CardTitle {
  margin: 0;
  font-size: 0.875rem;
  text-overflow: ellipsis;
}

.CardBody {
  display: flex;
  flex-direction: row;
  max-width: fit-content;
  gap: 16px;
  padding-right: 0px;
}
