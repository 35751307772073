.ProfileImageSection {
  width: fit-content;
}

.profileImage {
  display: flex;
  width: 80px;
  height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  object-fit: cover;

  &_Input {
    z-index: 99;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 200px;
    cursor: pointer;
    opacity: 0;
    &_text {
      z-index: 1;
      opacity: 0.8;
      font-size: 12px;
      font-family: 'Inter';
      font-weight: 600;
      background-color: #ffffff;
    }
  }
}
