@import '/src/styles/colors/primary.scss';

.Badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  padding: 2px 8px;
  border-radius: 16px;
  background-color: gray(100);

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'DM Sans';

  &[data-status='not_started'] {
    background: var(--colors-gray-light-mode-200, #eaecf0);
    color: #344054;
  }

  &[data-status='in_progress'] {
    color: #1c8aca;
    background: #dceffa;
  }

  &[data-status='in_review'] {
    color: #a46404;
    background: var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
  }

  &[data-status='published'] {
    color: #b54708;
    background: var(--Colors-Warning-100, #fef0c7);
  }

  &[data-status='approved'] {
    color: #00a7b7;
    background: var(--Brand-Colors-Secondary-Moonstone-200, #e2fbfb);
  }
}
