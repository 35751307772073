@keyframes loading {
  40% {
    background-position: 100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.row-class {
  &:hover {
    background-color: none !important;
  }
}
.loading {
  padding: 12px 0;
  min-width: 150px;
  &:last-child {
    border-radius: 0 8px 8px 0;
  }
}
.loading {
  position: relative;

  .bar {
    background-color: #e7e7e7;
    height: 14px;
    border-radius: 7px;
    width: 80%;
  }
  .circle {
    background-color: #e7e7e7;
    height: 18px;
    width: 18px;
    border-radius: 100%;
    margin: 10px 0;
  }

  &:after {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 24px;
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 80%
    );
    background-size: 200px 24px;
    background-position: -100px 0;
    background-repeat: no-repeat;
    animation: loading 1s infinite;
  }
}
