@import './../../styles/functions/@mixin-module';
@import './../../styles/variables/colors';
@import '/src/styles/colors/primary.scss';

.activeItem {
  background: #f9f5ff;
  border: 1px solid #b692f6;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.ListItem {
  background: #ffffff;
  border: 1px solid #e4e7ec;
}
.Listing {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  color: #101828;
  padding: 10px 14px;
  cursor: pointer;
}
.listWrapper {
  gap: 8px;
  margin-top: 16px;
  list-style: none;
}
.contentSettingBtn {
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 10px 18px;
  svg {
    max-width: 16px;
  }
}
.contentSettingForm {
  gap: 24px;
  padding: 24px 0px;
}
.editor_chevronLeft {
  position: absolute;
  left: -13px;
  z-index: 100;
}
.sideBarChevron {
  border: 1px solid font_clr(l-grey);
  background-color: #fff;
  z-index: 1;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.editor_chevronRight {
  position: absolute;
  right: -13px;
  z-index: 100;
}
.EditorSettingsMenu {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: flex-end;
  svg {
    cursor: pointer;
    color: #344054;
  }
}

.optionsLabel {
  justify-content: flex-start;
  align-items: center;
  padding: 6px 10px;
  gap: 8px;
  p {
    color: #1d2939 !important;
  }
}
.reactSelectEditorHeader {
  width: 168px;
  background: #f2f4f7;
  border-radius: 16px;
  font-size: 14px;
  color: #344054;
  span {
    display: none;
  }
  font-weight: 500;
  div {
    border-radius: 16px !important;
  }
}
.documentStatusWrapper {
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 100%;
  //max-width: 420px;
  min-width: 270px;
}

.documentStatus {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
}

.textInput {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  border: 1px solid transparent;
  width: 100%;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-style: normal;

  &:focus,
  :focus-visible,
  :focus-within {
    border: 1px solid #00a7b7;
    border-radius: 4px;
  }
}

.ContentSettingsAccordion {
  button {
    border: none !important;
  }
  .accordion-button:not(.collapsed) {
    background: none !important;
    box-shadow: none;
  }
}
.Comments {
  &__Header {
    padding: 20px 24px 16px;
    &_title {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    &_Menu {
      gap: 20px;
      img {
        width: 15px;
        cursor: pointer;
      }
    }
  }
  &__noCommentSection {
    padding: 32px 28px 0px 28px;
    &_message {
      @include Heading-md;
      color: font_clr(d-grey);
    }
  }
}

.DropdownListItem {
  padding: 10px 14px;
  @include Heading-md;
  &:hover {
    background-color: #f2fbfb;
    color: #00a7b7;
  }
}

.Comment {
  padding: 13px 24px;
  border-bottom: 1px solid #eaecf0;
  &_authorName {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    text-align: left;
    font-family: 'DM Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  &_timeStamp {
    color: var(
      --neutral-colors-Gray-neutral-500,
      var(--colors-gray-light-mode-500, #667085)
    );
    text-align: left;
    font-family: 'DM Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  &__Content {
    width: 100%;
    word-break: break-word;
    margin-top: 20px;
    &_text {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  &_dropdown {
    p {
      color: font_clr(d-grey);
    }
  }
  &_Replies {
    font-size: 12px;
    padding-top: 4px;
    color: font_clr(light-grey);
  }
}

.CustomAccordion {
  &_Button {
    button {
      padding: 0 !important;
      margin: 0 !important;
      border: none !important;
      background-color: transparent !important;
    }
  }
  &_Item {
    border: none;
  }
}

.Spinner {
  border: none;
}

.placeholderContainer {
  transition: 0.4s ease-in;
  position: absolute;
  color: #98a2b3;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  z-index: 10;
  margin-top: 13px;
  margin-left: 10px;
}

.promptBorder {
  border-bottom: 1px solid #98a2b3;

  animation: blink 1s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  50% {
    border-bottom: 2px solid #98a2b3;
  }
}

.commentBadge {
  position: absolute;
  top: -8px;
  right: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  padding: 10px;
  border: 1px solid #f4fdff;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  z-index: 1;
  transition:
    transform 0.15s,
    background-color 0.5s;
  background: #00a7b7;
}

.closeButton {
  text-decoration: none;
  color: font-clr(light-grey);
  border: 1px solid font-clr(light-grey);
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-family: Inter;

  &:hover {
    color: font-clr(d-grey-700);
  }
}

.publishButton:disabled {
  color: white !important;
  background-color: #e9d7fe !important;
  border-width: 0 !important;
  pointer-events: all !important;
  cursor: not-allowed !important;
}

.publishButton,
.publishButton:hover {
  color: white;
  background-color: #7f56d9;
  border-width: 0;
}

.questionsContainer {
  background-color: primary(25);
  padding: 24px 20px 24px 20px;
  border-radius: 8px;
  gap: 20px;
}

.health_section {
  display: flex;
  align-items: center;
  gap: 8px;
  &__heading {
    color: var(
      --neutral-colors-Gray-neutral-600,
      var(--colors-gray-light-mode-600, #475467)
    );

    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  &__health {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );

    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  &__preview {
    color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);

    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
  }
}

.rounded_avatar_bordered {
  border-radius: 200px;
  border: 1.5px solid var(--neutral-colors-black-white-white, #fff);
  display: flex;
  padding: 10px;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

// .arrow {
//   position: relative;
//   margin-left: 10px;
//   &__toggle_health {
//     width: 26px;
//     height: 26px;
//     background-color: white;
//     border-radius: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
//     position: absolute;
//     right: -10px;
//     z-index: 99999;
//   }
// }
