.BannerBtn {
  min-width: 181px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 14px !important;
  color: #000;
  border: 1px solid #ced4da;
  &__sm {
    min-width: 46px;
    max-width: 98px;
    font-size: 12px;
    padding: 8px !important;
    gap: 4px;
    svg {
      min-height: 12px;
      min-width: 12px;
    }
  }
  &__Header {
    min-width: 96px;
    height: 42px;
    font-weight: 500;
    border: 0;
    color: #ffffff;
  }
  svg {
    path {
      stroke: #000;
    }
  }
  &:hover {
    background-color: transparent !important;
    color: #000;
    border: 1px solid #ced4da;
  }
}
.InputGroup {
  flex-wrap: nowrap !important;
}

.input_container {
  display: flex;
  width: 100%;
  gap: 8px;
}
