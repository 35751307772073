.vocable-calender {
  .fc-scroller.fc-day-grid-container {
    overflow: auto !important;
    height: auto !important;
  }
  .fc-daygrid-day-events {
    aspect-ratio: 1/0.5;
  }
  .fc-daygrid-body,
  .fc-scrollgrid-sync-table,
  .fc-col-header {
    width: 100% !important;
  }
}
.fc-col-header {
  border-collapse: collapse;
}
.fc-theme-standard th {
  box-shadow: 0 1px 4px 0 #10182814;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #eaecf0;
}
.fc .fc-button-primary {
  background-color: transparent;
  color: #101828;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #00a7b7 !important;
    color: #fff !important;
  }
  &:focus,
  :focus-visible,
  :focus-within {
    box-shadow:
      0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px #00a8b715;
  }
  &:hover {
    background-color: transparent;
    color: #101828;
  }
  &:active {
    background-color: #00a7b7 !important;
  }
}
.fc .fc-col-header-cell-cushion {
  font-family: 'Poppins';
  font-weight: 500;
  color: #101828;
  font-size: 14px;
  text-align: start;
  text-decoration: none;
  line-height: 20px;
}
.fc-scrollgrid-sync-inner {
  text-align: start;
}
.fc-col-header-cell {
  .fc-scrollgrid-sync-inner {
    text-align: start;
    min-height: 48px;
    padding: 12px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: #fff;
  }
}
.fc .fc-daygrid-day-number {
  font-family: 'DM Sans';
  font-weight: 700;
  color: #101828;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  line-height: 20px;
}
.fc .fc-daygrid-day-top {
  justify-content: center;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #f2fbfb !important;
}
.fc-toolbar-title {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px !important;
  line-height: 28px;
  color: #1d2939;
}
.fc-daygrid-more-link {
  color: #00a7b7;
  min-width: 85%;
  margin-left: 11px;
  border-radius: 4px;
  background: var(--Brand-Colors-Secondary-Moonstone-100, #f2fbfb);
  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  min-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-decoration: none;
  &:hover {
    color: #00a7b7;
  }
}
.fc-popover {
  opacity: 0 !important;
}

.fc-event {
  box-shadow:
    0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 4px;
  background: #fff;
  border: none;
  border-left: 3px solid;
  width: 100% !important;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  filter: none !important;
  min-height: 28px;

  &::after,
  &::before {
    display: none;
  }
  .fc-event-title {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #101828;
  }
  &*:focus {
    background-color: #fff !important;
    filter: brightness(100);
  }
}
.fc .fc-daygrid-event {
  z-index: inherit !important;
}
.fc .fc-scroller-harness {
  overflow: auto !important;
}
.fc-toolbar-chunk {
  display: flex;
  align-items: center;
  gap: 10px;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin: 0;
}
.event-vocable-calender-Inreview {
  border-color: #a46404;
}
.event-vocable-calender-Inprogress {
  border-color: #1c8aca;
}
.event-vocable-calender-NotStarted {
  border-color: #344054;
}
.event-vocable-calender-Published {
  border-color: #b54708;
}
.event-vocable-calender-Approved {
  border-color: #00a7b7;
}

.event-card-avatar {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  font-size: 8px !important;
  color: #00a7b7;
  background-color: #f9f5ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc-daygrid-event-harness {
  margin-bottom: 2px;
}
.fc .fc-daygrid-event-harness {
  margin-top: 2px !important;
}

.fc-event {
  width: 85% !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 7px !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 11px !important;
}

.draftButton {
  padding: 10px;
}
