.form_group {
  display: flex;
  align-items: center;
}

.form_group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form_group label {
  position: relative;
  cursor: pointer;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.form_group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 12px;
  width: 20px;
  height: 20px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
  background: var(--Brand-Colors-Secondary-Moonstone-100, #f2fbfb);
}

.form_group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 8px;
  width: 5px;
  height: 11px;
  border: solid #00a7b7;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
