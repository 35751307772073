@import '/src/styles/colors/primary.scss';
@import '/src/styles/base/typography';
@import '/src/styles/functions/@mixin-module';
@import '/src/styles/variables/colors';

.AIProjectContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &_Body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &_Step {
      display: flex;
      padding: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 8px;
      margin-bottom: 32px;
      gap: 40px;

      &_Wrapper {
        display: flex;
        flex-direction: column;
        max-width: 600px;
        width: 100%;
        gap: 16px;
      }

      &_ButtonActions {
        display: flex;
        justify-content: space-between;
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
      }

      b {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        font-family: 'Poppins';
        color: gray(900);
      }

      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        font-family: 'DM Sans';
        color: gray(900);
      }
    }

    &_GetStarted {
      position: absolute;
      top: 255px;
    }
  }
}

.Optional {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 1rem;

  margin-top: 16px;
  padding: 24px;
  background: var(--White, #fff);

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: gray(900);
    font-family: 'Poppins';
  }

  svg {
    cursor: pointer;
  }

  input {
    width: 100%;
    margin-right: 12px;
  }

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }
}
