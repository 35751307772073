@import '/src/styles/functions/@mixin-module';
@import '/src/styles/variables/colors';
@import '/src/styles/base/typography';
.HeaderInputSection {
  min-width: 350px !important;
}
.AIBSTool {
  &__Header {
    color: font_clr(d-grey);
  }
  &__Wrapper {
    border: 1px solid font_clr(l-grey);
    border-radius: 8px;
    &_InputSection {
      width: 352px;
      border-right: 1px solid font_clr(l-grey);
      min-height: 700px;
      &_inputs {
        gap: 32px;
        padding: 16px;
        animation: OpacityBreak 0.7s ease-in-out;
        @keyframes OpacityBreak {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        &_Tags {
          gap: 12px;
          p {
            color: font_clr(d-grey-700);
          }
          &Wrapper {
            gap: 8px;
          }
        }
      }
      &_RadioBtns {
        span {
          color: font_clr(light-grey);
          font-family: 'Inter';
          font-weight: 400;
        }
      }
      &_Footer {
        border-top: 1px solid font_clr(l-grey);
        &_btnDark {
          width: 214px;
          height: 44px;
        }
        &_btnLight {
          width: 98px;
          height: 44px;
          border: 1px solid #e4e7ec;
        }
      }
    }
  }
  &__SelectLabel {
    @include text-md-Regular;
    padding: 14px 0px;
    color: font_clr(d-grey);
    cursor: pointer;
  }
  &__ContentSection {
    width: calc(100% - 352px);
    & {
      &_Header {
        padding: 16px 24px;
        border-bottom: 1px solid #e4e7ec;
      }
      &_Content {
        padding: 24px;
        max-height: 683px;
        overflow-y: auto;
      }
    }
    &_Content_Header_Nav {
      cursor: pointer;
      span {
        @include text-sm;
        line-height: 18px;
        color: #000;
      }
    }
  }
}
.AISTool {
  &__TextArea {
    height: 467px;
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
    }
  }
  &__URL {
    height: 52px;
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
    }
  }
  &__DragDropArea {
    position: relative;
    border-radius: 8px;
    height: 126px;
    padding: 16px 0px;
    border: 1px dashed #00a7b7;
    img {
      width: 44px;
    }
    input {
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0;
    }
    &__fileMsg {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
      &_highlight {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #6941c6;
      }
      &_format {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #667085;
      }
    }
  }
}
.AIRTOOL {
  min-height: calc(100vh - 152px);
  padding-bottom: 200px;
  &__Heading {
    font-family: 'Poppins';
    color: #101828;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  &__Message {
    color: #344054;
    text-align: center;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  &__InputSection {
    padding-top: 32px;
    &_Btn {
      width: 156px;
    }
    &__Suggestions {
      row-gap: 9px;
      span {
        color: #101828;
        text-align: center;
        font-family: 'DM Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  &__top_container {
    background: #f8f1e4;
    padding: 40px;
    &__heading {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
    }
    &__description {
      color: var(
        --neutral-colors-Gray-neutral-700,
        var(--colors-gray-light-mode-700, #344054)
      );
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
.CardSection {
  // @media screen and (max-width: 1440px) {
  //   justify-content: space-between;
  // }
  &__Card {
    padding: 16px;
    min-width: 264px;
    // border: 1px solid font_clr(l-grey);
    display: flex;
    align-items: center;
    color: #00a7b7;
    border-radius: 12px;
    background: var(--Colors-Base-white, #fff);

    svg {
      &:hover {
        stroke: #00a7b7;
      }
    }
    &_Icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 14px 16px;
      background: #f2fbfb;
      border-radius: 12px;
      color: #00a7b7;
      svg {
        width: 20px;
        height: 20px;
        path {
          stroke: #00a7b7;
        }
      }
    }

    &_Headline {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    &__Stats {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
    &__Onboarding {
      padding: 16px 24px;
      border: 0px;
      border-right: 1px solid white;
      &:first-of-type {
        padding: 16px 24px 16px 0px;
      }
      &:last-of-type {
        border-right: 0px;
        padding: 16px 0px 16px 24px;
      }
    }

    &_AiPoweredBriefHeadline {
      font-size: 12px;
      color: font_clr(d-grey);
      min-width: fit-content;
    }
  }
}

.tag_container {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  &__tag {
    display: flex;
    padding: 4px 12px;
    align-items: flex-start;
    gap: 4.058px;
    flex-shrink: 0;
    border-radius: 36.09px;
    background: var(--Brand-Colors-Secondary-Sunset-400, #fde2b9);
    color: var(--colors-gray-light-mode-900, #101828);
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.56px;
  }
}

.card_container {
  gap: 12px !important;
}

.search_col {
  background: #dcfae6;
  svg {
    stroke: #079455 !important;
    path {
      stroke: #079455 !important;
    }
  }
}
.keyword_diff {
  background: #dbeffa;
  svg {
    path {
      stroke: #1c8aca !important;
    }
  }
}
.results {
  background: #f3f4f6;
  svg {
    stroke: #6c737f !important;
    path {
      stroke: #6c737f !important;
    }
  }
}
.cpc {
  background: #fee4e2;
  svg {
    path {
      stroke: #d92d20;
    }
  }
}

.tabs_container {
  position: relative;
  &__filters {
    position: absolute;
    right: 24px;
    top: 15px;
  }
}

.filters {
  display: flex;
  padding: 6px 8px;
  align-items: flex-start;
  gap: 8px;
  color: var(
    --neutral-colors-Gray-neutral-800,
    var(--colors-gray-light-mode-800, #182230)
  );
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  &:hover {
    color: #00a7b7;
  }
}
