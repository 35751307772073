@import '../../styles/functions/@mixin-module';
// Hero Section
.container {
  background-color: #fff9f0;
  border: 1px solid rgb(253, 220, 171);
  border-radius: 24px;
  padding: 12px;
  padding-bottom: 40px;
  margin-bottom: 40px;
  position: relative;
  z-index: 0;

  &__heading {
    padding-top: 80px;
    position: relative;
    h2 {
      font-family: 'Poppins';
      font-size: 64px;
      line-height: 71.68px;
      text-align: center;
      text-transform: capitalize;
      color: #1d2939;
      letter-spacing: -4%;
      text-wrap: nowrap;
      @media screen and (min-width: 768px) and (max-width: 992px) {
        font-size: 32px;
        line-height: 48px;
      }
      @media screen and (min-width: 340px) and (max-width: 768px) {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
      }
      span {
        color: #553402;
        position: absolute;
        z-index: 0;
        left: 50%;
        transform: translateX(-50%);
        &::after {
          content: url('../../../public/Images/wave.svg');
          @include background-position;
          right: -2%;
          rotate: 2deg;
          top: 10px;
          @media screen and (max-width: 992px) {
            content: '';
          }
        }
      }
    }
  }
  &__toggleSection {
    text-align: center;
    margin-top: 60px;
    // desktop
    @media (min-width: 1200px) {
      margin: 100px 0 32px 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    span {
      color: #334155;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.3px;
      font-family: 'DM Sans';
    }
  }
  &__cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 13px;
    height: 100%;
    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }
}

.RightNavigationFlex {
  display: flex;
  align-items: center;
}

.headerItems__Gap {
  gap: 30px;
}

.tryForFreeNavBtn {
  background-color: #1d2939;
  border: 1px solid #7f56d9;
  padding: 12px 20px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20.3px;
}

// utilities
.bg_utility {
  background-color: #fff9f0 !important;
  border-radius: 24px 24px 0 0 !important;
}
// .gradient {
//   background: rgb(253, 220, 171);
//   background: linear-gradient(
//     90deg,
//     rgba(253, 220, 171, 1) 0%,
//     rgba(253, 220, 171, 0) 0%,
//     rgba(253, 220, 171, 1) 50%,
//     rgba(253, 220, 171, 0) 100%
//   );
//   height: 1px;
//   margin-top: 10px;
// }
