@import '/src/styles/colors/primary.scss';

.TileContainer {
  display: flex;
  // min-width: 264px;
  height: 180px;
  padding: var(--spacing-xl, 16px);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--neutral-colors-black-white-white, #fff);
  &:hover {
    border-radius: 12px;
    border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);
    box-shadow: 0px 8px 16px -4px rgba(16, 24, 40, 0.12);
  }

  cursor: pointer;
  // grid-column: span 2;

  font-family: 'DM Sans';
  // width: 264px;
}

.TileContent {
  display: flex;
  flex-direction: column;
  // gap: 12px;
  width: 100%;

  &_Marker {
    width: 40px;
    height: 4px;
    border-radius: 12px;
    background-color: #00a7b7;
  }

  &_Title {
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    font-family: 'Poppins';
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @supports (-webkit-line-clamp: 2) {
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &_Objective {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #667085;
    font-family: 'DM Sans';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @supports (-webkit-line-clamp: 3) {
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

.TileInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  &_AvatarGroup {
    display: flex;
    padding: var(--spacing-xs, 4px);
    align-items: center;
    gap: -6px;
    border-radius: 9999px;
    background: var(--Brand-Colors-Secondary-SandStone-900, #f8f1e4);
    padding: 4px;

    img {
      // width: 24px;
      // height: 24px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #fff;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      // width: 24px;
      // height: 24px;
      border-radius: 50%;
      // border: 1px solid #fff;

      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      color: #00a7b7;
      // background-color: #00a8b711;
    }

    & > :not(:first-child) {
      margin-left: -8px;
    }
  }

  &_Actions {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    color: #667085;

    &_Date {
      display: flex;
      align-items: center;
      gap: 4px;

      &_Text {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        font-family: 'DM Sans';
        color: #667085;
      }
    }

    svg {
      color: gray(500);
    }
  }
}

.DropdownItem {
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    color: gray(900);
  }

  &_Text {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
  }
}

.card {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    &__badge {
      display: flex;
      height: 24px;
      padding: 0px var(--spacing-md, 8px);
      justify-content: center;
      align-items: center;
      gap: var(--spacing-xs, 4px);
      border-radius: 4px;
      text-align: center;
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  &_heading {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    text-overflow: ellipsis;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-top: 12px;
  }
  &__date {
    display: flex;
    align-items: end;
    gap: 6px;
    span {
      color: var(
        --neutral-colors-Gray-neutral-500,
        var(--colors-gray-light-mode-500, #667085)
      );
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin-top: 6px;
    }
  }
}

.lead_generation {
  border: 1px solid rgba(28, 138, 202, 0.4);
  color: #1c8aca;
}
.brand_awareness {
  border: 1px solid rgba(52, 64, 84, 0.3);
  color: #344054;
}
.engagement {
  border: 1px solid rgba(8, 93, 58, 0.3);
  color: #085d3a;
}

.sales_conversion {
  border: 1px solid rgba(164, 100, 4, 0.3);
  color: #a46404;
}

.ProjectDetail_titleContainer_editorCount {
  width: 32px;
  height: 32px;
  color: #98a2b3;
  position: relative;
  background: #f9f5ff;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 200px;
  color: #7f56d9;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.collaborator_name {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  text-align: center;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
