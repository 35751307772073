@import '/src/styles/colors/primary.scss';

.Ideate {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &_Generator {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: 32px;

    p {
      color: var(
        --neutral-colors-Gray-neutral-700,
        var(--colors-gray-light-mode-700, #344054)
      );
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  &_Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));
    grid-auto-rows: 1fr;
    grid-auto-flow: row dense;
    gap: 16px;
    padding: 0 24px 24px;
  }
  &__top_container {
    background: #f8f1e4;
    padding: 40px;
    &__heading {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
    }
    &__description {
      color: var(
        --neutral-colors-Gray-neutral-700,
        var(--colors-gray-light-mode-700, #344054)
      );
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.ulity_height {
  height: 42px !important;
}

.tag_container {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  &__tag {
    width: 100%;
    display: flex;
    padding: 4px 12px;
    align-items: flex-start;
    gap: 4.058px;
    flex-shrink: 0;
    border-radius: 36.09px;
    background: var(--Brand-Colors-Secondary-Sunset-400, #fde2b9);
    color: var(--colors-gray-light-mode-900, #101828);
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.56px;
  }
}

@media (min-width: 768px) {
  .tag_container {
    flex-direction: row;
    &__tag {
      width: fit-content;
    }
  }
}
