.heading {
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  &__title {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  &__desc {
    display: none;
    color: var(
      --neutral-colors-Gray-neutral-600,
      var(--colors-gray-light-mode-600, #475467)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    // desktop
    @media (min-width: 769px) {
      display: block;
    }
  }
}
