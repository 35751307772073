@import './../../styles//base/typography';
@import './../../styles//base/fonts';
@import '../../styles/variables/colors';
@import '/src/styles/colors/primary.scss';

.Questionaire {
  max-width: 852px;
  margin: 0 auto;
  padding-top: fontsize('s48');
  &__Wrapper {
    background-color: #fff;
  }
  &__Header {
    background-color: #353535;
  }
  &__Heading_Section {
    gap: fontsize('s12');
    &_heading {
      font-family: 'Poppins';
      font-weight: 600;
      color: #101828;
      line-height: 38px;
      font-size: 30px;
    }
  }
  &__Section {
    margin-top: fontsize('s19');
    gap: 56px;
    margin-bottom: fontsize('s32');
    &_guideline {
      font-family: 'Poppins';
      font-weight: 600;
      font-weight: 400;
      font-size: fontsize('s18');
      line-height: 28px;
      // margin-bottom: fontsize("s48");
      color: font_clr('light-grey');
    }
  }
}
.Questionaire_InnerWrapper {
  padding: fontsize('s32');
  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: fontsize('s12');
}
.Question {
  gap: 16px;
  &_Number {
    font-family: 'Inter';
    font-weight: 700;
    font-size: fontsize('s24');
    line-height: fontsize('s32');
    color: font_clr('link-blue');
  }
  &_questionText {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  &_subStr {
    color: var(
      --neutral-colors-Gray-neutral-700,
      var(--colors-gray-light-mode-700, #344054)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
  }
  &_questionPromo {
    font-family: 'Inter';
    font-weight: 400;
    font-size: fontsize('s16');
    line-height: fontsize('s24');
    color: font_clr('light-grey');
  }
  &_Form {
    padding-top: fontsize('s24');
    gap: 8px;
  }
}
.formCheckboxes {
  max-height: 690px;
  overflow-y: auto;
}
.FormContinueBtn {
  margin-top: fontsize('s32');
  padding: 10px 16px;
  font-weight: 500;
  line-height: fontsize('s20');
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: fit-content;
  font-size: fontsize('s14');
  &_sm {
    background-color: #00a7b7;
    font-size: 14px;
  }
}

// .react-tagsinput--focused {
//   box-shadow: none;
//   border-color: #7f56d9;
// }

.header_container {
  display: flex;
  height: 60px;
  padding: var(--spacing-none, 0px) var(--spacing-xl, 16px)
    var(--spacing-none, 0px) var(--spacing-3xl, 24px);
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  &__icon {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    background: var(--Brand-Colors-Secondary-Moonstone-100, #f2fbfb);
  }

  &__heading {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
}
