.card {
  display: flex;
  // width: 264px;
  height: 180px;
  padding: var(--spacing-xl, 16px);
  flex-direction: column;
  //   justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--neutral-colors-black-white-white, #fff);
  &:hover {
    cursor: pointer;
    flex-shrink: 0;
    box-shadow: 0px 8px 16px -4px rgba(16, 24, 40, 0.12);
    &:hover {
      .card__footer__dots {
        display: block;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    &__badge {
      display: flex;
      height: 24px;
      padding: 0px var(--spacing-md, 8px);
      justify-content: center;
      align-items: center;
      gap: var(--spacing-xs, 4px);
      border-radius: 4px;
      text-align: center;
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  &_heading {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    text-overflow: ellipsis;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-top: 12px;
  }
  &__date {
    display: flex;
    align-items: end;
    gap: 6px;
    span {
      color: var(
        --neutral-colors-Gray-neutral-500,
        var(--colors-gray-light-mode-500, #667085)
      );
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin-top: 6px;
    }
  }
  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    margin-top: 30px;
    &__avatars {
      border-radius: 9999px;
      background: var(--Brand-Colors-Secondary-SandStone-900, #f8f1e4);
      display: flex;
      padding: var(--spacing-xs, 4px);
      align-items: center;
      gap: -6px;
    }
    &__dots {
      display: none;
    }
  }
}

.lead_generation {
  border: 1px solid rgba(28, 138, 202, 0.4);
  color: #1c8aca;
}
.brand_awareness {
  border: 1px solid rgba(52, 64, 84, 0.3);
  color: #344054;
}
.engagement {
  border: 1px solid rgba(8, 93, 58, 0.3);
  color: #085d3a;
}

.sales_conversion {
  border: 1px solid rgba(164, 100, 4, 0.3);
  color: #a46404;
}
