.Onboarding_background {
  background: linear-gradient(
    251.25deg,
    rgba(0, 167, 183, 0.2) 0%,
    rgba(0, 167, 183, 0.08) 46.88%,
    rgba(0, 167, 183, 0.1) 100%
  );

  height: 100vh;

  &_container {
    height: calc(100vh - 68px);
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
