.CheckboxWrapper {
  padding: 0px !important;
  position: relative;
  margin-right: 0.5rem;
}
.CheckboxInput {
  display: none;
  visibility: none;
}
.CheckboxInput:checked + label {
  background: #f2fbfb;
  border: 1px solid #00a7b7;
}
.CheckboxInputLabel {
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 14px 10px 14px;
  margin-top: 6px;
  cursor: pointer;
  &__sm {
    padding: 8px 16px;
    font-size: 12px;
  }
  &__CustomLabel {
    max-height: 52px;
    padding: 16px;
    overflow: hidden;
  }
}
.CheckboxInput[type='checkbox'] {
  display: block !important;
  position: absolute;
  right: 9px;
  top: 15px;
  background: #f9f5ff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: unset;
  width: 16px !important;
  height: 16px !important;
  border: 1px solid #7f56d9;
  border-radius: 4px;
  padding: 5px;
}
.CheckboxInput:checked[type='checkbox'] {
  background-image: url('../../../public/Images/tick.svg') !important;
  background-color: transparent !important;
  width: 16px !important;
  height: 16px !important;
}
.CheckboxInput[type='checkbox'] + label {
  width: 100%;
}
// input {
//   position: absolute;
//   right: 9px;
//   top: 9px;
// }
.fullWidthRadioBtn {
  display: block !important;
  position: absolute;
  right: 10px;
  top: 33%;
  border-color: #7f56d9 !important;
  cursor: pointer;
  &__ViewTop {
    top: 21px;
    right: 17px;
  }
}
.fullWidthRadioBtn + label {
  width: 100%;
}
.fullWidthRadioBtn:checked {
  background-image: url('../../../public/Images/Check-bg.svg') !important;
  background-color: #fff;
  border: 1px solid #7f56d9;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 50% 50%;
}
.fullWidthRadioBtn:checked + label {
  background: #f9f5ff;
  border: 1px solid #d6bbfb;
}
.fullWidthRadioBtn {
  &__ViewTop {
    &:checked + label {
      max-height: fit-content;
      background: transparent;
      border: 1px solid #d6bbfb;
      animation: animateHeight 0.3s ease-in;
      @keyframes animateHeight {
        from {
          max-height: inherit;
          opacity: 0;
        }
        to {
          max-height: fit-content;
          opacity: 1;
        }
      }
    }
  }
}
