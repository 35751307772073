@import '/src/styles/colors/primary.scss';

.aiGroup {
  position: fixed;
  z-index: 10;
  bottom: 16px;
  max-width: 820px;
  width: 75%;
  background-color: #f2fbfb;
  border-radius: 16px;
  padding: 12px;
}

.askBar {
  display: flex;
  align-items: center;
  gap: 16px;
}

.askLabel {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  color: primary(700);
  margin-right: 4px;
  min-width: fit-content;
}

.askInputGroup {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 4px;
}

.askInput {
  width: 100%;
  background-color: transparent;
  border: none;
  color: gray(900);
  &::placeholder {
    color: gray(500);
  }
  &:focus {
    background-color: transparent;
    border: 1px solid #00a7b7;
    box-shadow: none;
  }
}

.aiAnswer {
  display: flex;
  margin-top: 16px;
  background: white;
  color: gray(900);
  padding: 0.375rem 0.75rem;
  border-radius: 16px;
  max-height: 226px;
  overflow-y: auto;
  justify-content: space-between;

  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  &:empty {
    padding: 0;
    display: none;
  }

  .content {
    max-width: 650px;
  }

  .content ul,
  .content ol {
    padding-left: 1.75em;
  }
}

.copyDiv {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  gap: 8px;
  position: sticky;
  top: 0;
}

.copyDiv button {
  background-color: #f2fbfb;
  color: primary(700);
  border-radius: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  min-height: 36px;
  min-width: 36px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: #00a7b7;
    color: white;
  }
}
