@import '/src/styles/colors/primary.scss';
@import '/src/styles/functions/@mixin-module';

.ContentPlanner {
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 32px;
  &_Toast {
    display: none;
  }
  &_Heading {
    color: gray(900);
  }

  &__Wrapper {
    display: flex;
    flex-direction: column;

    position: relative;

    &_InputSection {
      position: absolute;
      top: -12px;
      right: 0;

      svg {
        stroke: gray(500);
        filter: brightness(100%);
      }

      &_Filters {
        display: flex;
        align-items: center;
      }

      &_DropDown {
        width: 100vw;
        padding: 12px;
        animation: OpacityBreak 0.5s ease-in-out;
        @keyframes OpacityBreak {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @media (min-width: 768px) {
          width: 800px;
        }
      }
    }

    &_Navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      padding: 0 12px 10px;
      gap: 12px;
      border-bottom: 1px solid var(--Neutral-100, #e2e8f0);
      background: var(--Colors-Base-white, #fff);

      &__left {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &__right {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &_Filter {
        font-weight: 700;
        font-size: 14px;
        color: #182230;
        cursor: pointer;
        font-family: 'DM Sans';
        padding: 8px !important;

        &:hover {
          color: #00a7b7;
          svg {
            color: #00a7b7;
          }
        }
      }

      &_Icon {
        a {
          padding: 8px;
        }

        a[aria-selected='true'] {
          stroke: #00a7b7;
          color: #00a7b7 !important;
          border-bottom: 1px solid #00a7b7;
        }
        &__Item {
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(
            --neutral-colors-Gray-neutral-700,
            var(--colors-gray-light-mode-700, #344054)
          );
          text-align: center;
          font-family: 'DM Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          border-bottom: 1px solid transparent;
          height: 60px;
          &:hover {
            border-bottom: 1px solid #00a7b7;
            color: #00a7b7 !important;
          }
        }
      }

      &__Calendar {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 8px;
        border-top: 1px solid #0003;
        margin-top: 4px;
        &_Large {
          display: none;
        }
      }
      @media (min-width: 768px) {
        padding: 0px 24px;
        &__Calendar {
          display: none;
          &_Large {
            display: flex;
          }
        }
      }
    }
  }

  &__Loading {
    max-height: 49vh;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 768px) {
    &_Wrapper {
      &_Navigation {
        padding: 0 24px;
      }
    }
  }
}

.SelectLabel {
  @include text-md;
  color: gray(800);
  gap: 10px;
  padding: 4px 4px 4px 8px;
  width: fit-content;

  svg {
    stroke: gray(500);
  }

  &_View {
    min-width: 72px !important;
    padding: 10px !important;
    position: relative;
    font-family: 'DM Sans';
    font-size: 14px;
    font-weight: 700;
    color: #101828;
    line-height: 20px;
  }

  &_NotStarted {
    @include text-md;
    border-radius: 16px;
    background: #f5e3de;
    color: red;
    padding: 4px 12px;
  }

  &_InProgress {
    @include text-md;
    color: #175cd3;
    border-radius: 16px;
    background: #dbe8f7;
    padding: 4px 12px;
  }

  &_InReview {
    @include text-md;
    border-radius: 16px;
    color: #800080;
    background: #fbe1fb;
    padding: 4px 12px;
  }

  &_Approved {
    @include text-md;
    border-radius: 16px;
    color: #039855;
    background: #ebf8dc;
    padding: 4px 12px;
  }

  &_Published {
    @include text-md;
    border-radius: 16px;
    color: yellow;
    background: #f7f7f7;
    padding: 4px 12px;
  }

  input:checked[type='checkbox'] {
    max-width: 16px !important;
    margin: 0;
  }
  input[type='checkbox'] {
    max-width: 16px !important;
    height: 16px !important;
    margin: 0;
  }
}

.Count {
  width: 26px;
  height: 22px;
  background: gray(100);
  border-radius: 16px;
  color: gray(800);
  @include text-sm-lh18;
}

.Drawer {
  position: relative;
  display: flex;
  gap: 10px;

  &_Toast {
    width: 172px;
    position: absolute;
    top: 150px;
    left: -20px;
    background-color: white;
    z-index: 999;
    overflow: hidden;
    border: none;
    background: #000;

    &__Body {
      padding: 0px;
      background-color: white;

      &_list {
        list-style: none;

        li {
          @include text-md-Regular;
          color: gray(900);
          padding: 10px 14px;
          cursor: default;
          &:hover {
            background-color: #f2fbfb;
          }
        }
      }
    }
  }
}

.filterDatePicker {
  max-width: 176px !important;
  overflow: hidden;
  input {
    border-radius: 4px;
    font-size: 14px !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}

.brief_btn {
  border: 1px solid #00a7b7;
  color: #00a7b7;
  font-family: 'Poppins';
  font-weight: 600;
  &:hover {
    border: 1px solid #00a7b7;
    color: #00a7b7;
  }
  &:active {
    border: 1px solid #00a7b7;
    color: #00a7b7;
  }
  &:visited {
    border: 1px solid #00a7b7;
    color: #00a7b7;
  }
  &:focus {
    border: 1px solid #00a7b7;
    color: #00a7b7;
  }
}

.calendar_btn {
  border: none;
  background-color: transparent;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.calendar_month_text {
  color: var(
    --neutral-colors-Gray-neutral-800,
    var(--colors-gray-light-mode-800, #182230)
  );
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.utility_left_margin {
  margin-left: 12px;
}

.rounded_plus_btn {
  background-color: #1d2939;
  border-radius: 100%;
  position: absolute;
  bottom: 26px;
  right: 36px;
  width: 56px;
  height: 56px;
  color: white;
  button {
    background-color: #1d2939;
    height: 56px;
    width: 56px;
    border-radius: 100%;
    &:active,
    &:visited,
    &:focus,
    &:focus-visible {
      background-color: #1d2939;
      height: 56px;
      width: 56px;
      border-radius: 100%;
      color: white;
    }
    & > .btn.show {
      color: white !important;
    }
  }

  div {
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);
    box-shadow: 0px 12px 24px -8px rgba(16, 24, 40, 0.2);
    margin-right: 1rem;
    transform: translate(-105px, -66px) !important;
  }
}

.list_item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 8px;
  background: var(--Colors-Base-white, #fff);
  span {
    font-family: 'Poppins' !important;
  }
}

.list_item:not(:last-child) {
  border-bottom: 1px solid var(--colors-gray-light-mode-100, #f2f4f7);
  box-shadow: 0px 12px 24px -8px rgba(16, 24, 40, 0.2);
}

.list_item:not(:first-child) {
  padding-top: 12px;
}

.list_item:not(:last-child) {
  padding-bottom: 12px;
}

.list_item:hover {
  background-color: white;
}

.plus_icon {
  height: 30px;
  width: 30px;
  transition: transform 0.3s ease;
}

[aria-expanded='true'] .plus_icon {
  transform: rotate(-45deg);
}

@media (min-width: 768px) {
  .Toast {
    right: 0px;
  }
}
