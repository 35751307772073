@keyframes loaderSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.SpinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #f2f4f7;
  border-radius: 8px;
  gap: 16px;
  min-width: 318px;
  min-height: 158px;
  padding: var(--spacing-2xl, 20px) var(--spacing-3xl, 24px);

  svg {
    animation: loaderSpinner 1.5s infinite linear;
  }
}

.SpinnerText {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #344054;
}
