.card_container {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  &__card {
    display: flex;
    max-width: 400px;
    padding: 0;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xl, 16px);
    align-self: stretch;
    border-radius: 16px;
    &__inner {
      &__text {
        color: var(
          --neutral-colors-Gray-neutral-700,
          var(--colors-gray-light-mode-700, #344054)
        );
        font-family: 'DM Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        span {
          color: var(
            --neutral-colors-Gray-neutral-900,
            var(--colors-gray-light-mode-900, #101828)
          );
          font-family: 'DM Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
        }
      }
      button {
        margin-top: 16px;
      }
    }
  }
}

.basic_btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));

  /* Headings/sm/Medium */
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.pro_btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Brand-Colors-Primary-Oxford-900, #1d2939);
  background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(
    --neutral-colors-black-white-white,
    var(--Colors-Base-white, #fff)
  );

  /* Headings/sm/Medium */
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  &:hover {
    background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);

    border: 1px solid var(--Brand-Colors-Primary-Oxford-900, #1d2939);
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(
      --neutral-colors-black-white-white,
      var(--Colors-Base-white, #fff)
    );
  }
}
.business_btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Brand-Colors-Primary-Oxford-900, #1d2939);
  background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(
    --neutral-colors-black-white-white,
    var(--Colors-Base-white, #fff)
  );

  /* Headings/sm/Medium */
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  &:hover {
    background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);

    border: 1px solid var(--Brand-Colors-Primary-Oxford-900, #1d2939);
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(
      --neutral-colors-black-white-white,
      var(--Colors-Base-white, #fff)
    );
  }
}

.new_user_btns {
}
