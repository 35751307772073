.card_container {
  padding-bottom: 2rem;
  border-bottom: 1px solid #eaecf0;
  margin-left: auto;
  margin-right: auto;
  // desktop
  @media (min-width: 1024px) {
    margin: 0 auto 2rem;
    padding: 0 8px;
  }
  // height: 87%;
  &__card {
    display: flex;
    max-width: 400px;
    padding: var(--spacing-xl, 16px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xl, 16px);
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #ffe4b9;
    background: var(--Colors-Base-white, #fff);
    height: 100%;
    &__inner {
      display: flex;
      padding: var(--spacing-md, 8px);
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-lg, 12px);
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
      background: var(--Brand-Colors-Secondary-Sunset-100, #fff9f0);
      &__text {
        color: var(
          --neutral-colors-Gray-neutral-700,
          var(--colors-gray-light-mode-700, #344054)
        );
        font-family: 'DM Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding: 0 16px;
        min-height: 60px;
      }
      &__bottom_container {
        display: flex;
        padding: var(--spacing-xl, 16px) var(--spacing-3xl, 24px);
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
        background: var(--Colors-Base-white, #fff);
        height: 100%;

        &__badge {
          display: flex;
          padding: var(--spacing-lg, 12px);
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: var(--spacing-lg, 12px);
          align-self: stretch;
          border-radius: 8px;
          background: var(--Brand-Colors-Secondary-Sunset-100, #fff9f0);
          color: var(
            --neutral-colors-Gray-neutral-800,
            var(--colors-gray-light-mode-800, #182230)
          );
          font-family: 'DM Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
        &__heading {
          color: var(--Neutral-300, #94a3b8);
          font-family: 'DM Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        &__list_container {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          &__list {
            width: 100%;
            margin-bottom: 12px;
            display: flex;
            align-items: flex-start;
            gap: 12px;
            color: var(
              --neutral-colors-Gray-neutral-900,
              var(--colors-gray-light-mode-900, #101828)
            );

            font-family: 'DM Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.billingCardHeadingWrapper {
  display: flex;
  padding: var(--spacing-none, 0px) var(--spacing-md, 8px);
  justify-content: space-between;
  align-items: start;
  align-self: stretch;
  &__heading {
    display: flex;
    align-items: center;
    gap: 16px;

    &__name {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      text-align: center;
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    &__subscription {
      display: flex;
      text-align: center;
      align-items: center;
      gap: 2px;
      color: var(
        --neutral-colors-Gray-neutral-500,
        var(--colors-gray-light-mode-500, #667085)
      );
      text-align: center;
      font-family: 'Poppins';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      &__bold {
        color: var(
          --neutral-colors-Gray-neutral-900,
          var(--colors-gray-light-mode-900, #101828)
        );
        text-align: center;
        font-family: 'Poppins';
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
      }
      &__old_price {
        color: var(
          --neutral-colors-Gray-neutral-900,
          var(--colors-gray-light-mode-900, #101828af)
        );
        text-align: center;
        font-family: 'Poppins';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        text-decoration: line-through;
      }
    }
  }
  &__badge {
    text-align: center;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    height: 24px;
    padding: 0px var(--spacing-md, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xs, 4px);
    border-radius: 999px;
    background: #dcfae6;
    color: #067647;
    text-wrap: nowrap;
  }
}

.pro_border {
  border: 1px solid #e3ebff;
}
.pro_bg {
  border: 1px solid #e3ebff;
  background: #ecf2ff;
}
.business_border {
  border: 1px solid #b6f9ff;
}
.business_bg {
  border: 1px solid #b6f9ff;
  background: #ebfdff;
}

.basic_bg_feature {
  background: #fff9f0;
}
.pro_bg_feature {
  background: #ecf2ff;
}
.business_bg_feature {
  background: #ebfdff;
}

.duration_and_badge {
  display: flex;
  justify-content: start;
  width: 100%;
  margin-top: -10px;
  padding-left: 80px;
  gap: 20px;
  color: var(
    --neutral-colors-Gray-neutral-700,
    var(--colors-gray-light-mode-700, #344054)
  );
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.full_height {
  height: auto;
}
