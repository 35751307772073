@import '/src/styles/colors/primary.scss';

.NoOptionsMessage {
  color: gray(400);
  text-align: center;
  padding: 4px 0;
}

.orMessage {
  padding: 12px 0;
  font-size: 16px;
}

.inputsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
