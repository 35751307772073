.TrialFeatures_container {
  margin: 20px 0 24px 0;
  &__heading {
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    &__item {
      display: flex;
      align-items: center;
      gap: 12px;
      &__icon {
        height: 28px;
        width: 28px;
      }
      &__text {
        color: var(
          --neutral-colors-Gray-neutral-900,
          var(--colors-gray-light-mode-900, #101828)
        );
        font-family: 'DM Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
