@font-face {
  font-family: 'Poppins Semibold';
  src: url('./font-files/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('./font-files/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

$fonts_family: (
  'poppins_semibold': 'Poppins SemiBold',
  'poppins_bold': 'Poppins Bold',
);

@function font_fam($font_fam) {
  @return map-get($fonts_family, $font_fam);
}

.poppins_semibold {
  font-family: 'Poppins Semibold' !important;
}

.poppins_bold {
  font-family: 'Poppins Bold' !important;
}

@each $fonts, $font_val in $fonts_family {
  .font-#{$fonts} {
    font-family: $font_val !important;
  }
  @media (max-width: 767.98px) {
    .font-sm-#{$fonts} {
      font-family: $font_val !important;
    }
  }
}
