.form_container {
  max-width: 992px;
  margin: 32px auto;
  padding: 0 1.5rem;
}

.helper_text {
  color: var(--Colors-Foreground-fg-disabled, #98a2b3);
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 8px;
}

.label {
  color: var(
    --neutral-colors-Gray-neutral-700,
    var(--colors-gray-light-mode-700, #344054)
  );
  margin-bottom: 8px;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.DragDropArea {
  background: #ffffff;
  border: 1px dashed #e4e7ec;
  border-radius: 8px;
  height: 126px;
  position: relative;
  padding: 16px 0px;
  img {
    width: 44px;
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
  }
  &__fileMsg {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    &_highlight {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #00a7b7;
    }
    &_format {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #667085;
    }
  }
}
