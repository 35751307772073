@import '/src/styles/colors/primary.scss';

.Sidebar {
  flex-direction: column;
  gap: auto;
  justify-content: space-between;

  position: fixed;
  padding: 16px;
  overflow-y: auto;

  z-index: 100;
  width: 100%;
  // desktop
  @media screen and (min-width: 1024px) {
    width: 240px;
    border-radius: 20px 0 0 20px;
    border-right: 1px solid #0f172a;
  }
  border-radius: 20px;
  height: calc(100vh);
  background-color: #0f172a;
  user-select: none;
  // top: 0;
}

.sidebarListItem {
  display: flex;
  align-items: center;

  padding: 10px 12px;
  cursor: pointer;
  border-radius: 8px;
  gap: 1rem;

  animation: all 0.5s;
  // color: gray(900);
  color: #fff;
  text-decoration: none;

  width: 100%;

  span {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: gray(900);
  }
}

.sidebarListItemActive {
  // @extend .sidebarListItem;
  display: flex;
  align-items: center;

  padding: 10px 12px;
  cursor: pointer;
  border-radius: 8px;
  gap: 1rem;

  animation: all 0.5s;
  // color: gray(900);
  color: #fff;
  text-decoration: none;

  width: 100%;
  color: white !important;
  background: #334155;
  &::after {
    display: none !important;
  }
  span {
    color: white;
  }
  svg {
    color: white;
  }
}

.sidebarListItemInactive {
  color: white !important;
  display: flex;
  align-items: center;

  padding: 10px 12px;
  cursor: pointer;
  border-radius: 8px;
  gap: 1rem;

  animation: all 0.5s;
  // color: gray(900);
  color: #fff;
  text-decoration: none;

  width: 100%;
  // @extend .sidebarListItem;
  &:hover {
    background: #334155;
    span {
      color: white;
    }
    svg {
      color: white;
    }
  }
}
.Others_text {
  color: #94a3b8;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 16.2px */
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 20px 12px 0px;
  display: block;
}
.Accordion {
  display: flex;
  flex-direction: column;
  gap: 2px;

  &_Item {
    border: none;
    border-radius: 0px;
    // border-bottom: 1px solid gray(200);
    padding: 8px 0px;
  }

  &_Header {
    margin-bottom: 4px;

    button {
      padding: 0 !important;
      margin: 0 !important;
      border: none !important;
      background-color: transparent !important;
    }
  }
}

.listItemText {
  font-family: 'DM Sans';
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

/* Add these styles to your existing CSS module */
.sidebarListItemDisabled {
  pointer-events: none;
  // opacity: 0.5;
  color: white !important;
  display: flex;
  align-items: center;

  padding: 10px 12px;
  cursor: pointer;
  border-radius: 8px;
  gap: 1rem;

  animation: all 0.5s;
  // color: gray(900);
  color: #fff;
  text-decoration: none;

  width: 100%;
}

.sidebarListItemProPlan {
  color: white !important;
  display: flex;
  align-items: center;

  padding: 10px 12px;
  cursor: pointer;
  border-radius: 8px;
  gap: 1rem;

  animation: all 0.5s;
  color: #fff;
  text-decoration: none;

  width: 100%;
}

.comming_soon {
  display: flex;
  height: 24px;
  padding: 0px var(--spacing-md, 7px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs, 4px);
  border-radius: 9999px;
  background: var(--Colors-Success-950, #053321);
  color: var(--Semantic-Colors-Green-400, var(--Colors-Success-400, #47cd89));
  text-align: center;
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-wrap: nowrap;
}

.item_icon {
  width: 18px;
}
