.ImageWrapper {
  width: 488px;
  height: 400px;

  background-color: #e3ebff;
  border: 1px solid #c7d6fe;
  border-radius: 16px;

  padding: 2rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.StepsWrapper {
  position: relative;
  width: 384px;
  height: 400px;

  &_Step {
    display: flex;
    flex-direction: column;

    padding: 8px 12px;
    border-radius: 8px;
    background: #fddcab;
    margin-bottom: 16px;

    &::before {
      content: '';
      position: absolute;
      left: -32px;
      width: 14px;
      height: 14px;
      background-color: #fddcab;
      border: 2px solid #0f172a;
      border-radius: 50%;
      z-index: 1;
    }
  }

  &:first-child {
    div {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 14px;
        left: -26px;
        width: 1px;
        height: 963px;
        background-color: #c7d6fe;
        z-index: 0;
      }
    }
  }
}

// new

.outer_container {
  display: flex;
  padding: var(--spacing-9xl, 72px) var(--spacing-312-px, 12px)
    var(--spacing-lg, 12px) var(--spacing-312-px, 12px);
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--spacing-3xl, 24px);
  border: 1px solid var(--Brand-Colors-Secondary-Periwinkle-500, #c7d6fe);
  background: var(--Brand-Colors-Secondary-Periwinkle-50, #f4f7ff);
  margin: 0 20px;
  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__heading_1,
    &__heading_2 {
      // desktop
      @media (min-width: 1200px) {
        font-size: 48px;
        line-height: 60px !important;
      }
    }
    &__heading_1 {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      text-align: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      line-height: 112%;
      letter-spacing: -2.56px;
      @media (max-width: 625px) {
        font-size: 32px;
        line-height: 40px !important;
        letter-spacing: -0.96px;
      }
    }
    &__heading_2 {
      color: var(--Brand-Colors-Secondary-Periwinkle-700, #858faa);
      text-align: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      letter-spacing: -2.56px;
      margin-top: 6px;
      @media (max-width: 625px) {
        font-size: 32px;
        line-height: 40px !important;
        letter-spacing: -0.96px;
      }
    }
    &__description {
      color: var(
        --neutral-colors-Gray-neutral-600,
        var(--colors-gray-light-mode-600, #475467)
      );
      text-align: center;
      font-family: 'Poppins';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      margin-top: 24px;
    }
    &__btn {
      display: flex;
      padding: 16px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
      color: var(--Colors-Text-text-white, #fff);
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      border: none;
      margin-top: 24px;
    }
  }

  &__bottom {
    margin-top: 48px;
    display: flex;
    // desktop
    @media (min-width: 1200px) {
      padding: var(--spacing-1164-px, 64px) var(--spacing-732-px, 32px);
      gap: 56px;
    }
    gap: 32px;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--spacing-2xl, 20px);
    border: 1px solid #d0deff;
    background: var(--Color-Bg, #e3ebff);
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.08);
    &__buttons {
      display: flex;
      align-items: center;
      gap: 16px;
      @media (max-width: 1024px) {
        width: 100%;
        flex-direction: column;
      }
      &__btn {
        display: flex;
        padding: 20px 70px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;
        border-radius: var(--radius-full, 9999px);
        border: 1px solid var(--Brand-Colors-Secondary-Periwinkle-500, #c7d6fe);
        background: var(--Brand-Colors-Secondary-Periwinkle-50, #f4f7ff);
        color: var(--colors-gray-light-mode-900, #101828);
        font-family: 'DM Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        min-width: max-content;
        width: 303px;
        @media (max-width: 1280px) {
          width: 100%;
          padding: 20px 40px;
        }
        @media (max-width: 1100px) {
          width: 100%;
          padding: 20px;
        }
        @media (max-width: 1024px) {
          width: 100%;
        }
      }
      span {
        @media (max-width: 1024px) {
          svg {
            rotate: 90deg;
          }
        }
      }
    }
    &__text {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins';
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -0.8px;
      text-align: left;
      align-items: flex-start;
      @media (max-width: 625px) {
        font-size: 32px;
        line-height: 40px !important;
        letter-spacing: -0.96px;
      }
    }
    &__video {
      height: 679px;
      border-radius: 8px;
      border: 1px solid var(--Brand-Colors-Secondary-Periwinkle-500, #c7d6fe);
      width: 100%;
      max-width: 1192px;
      @media (max-width: 625px) {
        width: 100%;
        height: 100%;
      }
      video {
        height: 100%;
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }
}
