.footer {
  display: flex;
  padding: 96px var(--spacing-520-px, 20px) var(--spacing-520-px, 20px)
    var(--spacing-2xl, 20px);
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  border: 1px solid var(--colors-gray-light-mode-800, #182230);
  background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
  margin: 0 20px;
  @media (max-width: 1200px) {
    margin: 0 8px;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    width: 100%;
    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 40px;
      padding: 0 50px;
    }
    @media (max-width: 625px) {
      padding: 0 8px;
    }
    &__left {
      width: 560px;
      @media (max-width: 1024px) {
        width: 100%;
      }
      &__title {
        color: var(--Brand-Colors-Secondary-Sunset-600, #f8c864);
        font-family: 'DM Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        @media (max-width: 625px) {
          font-size: 14px;
        }
      }
      &__heading {
        color: var(
          --neutral-colors-black-white-white,
          var(--Colors-Base-white, #fff)
        );
        font-family: 'Poppins';
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: -0.96px;
        margin-top: 12px;
        margin-bottom: 64px;
        @media (max-width: 625px) {
          font-size: 28px;
          line-height: 36px;
        }
      }
      &__btn {
        display: flex;
        height: 56px;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
        color: var(--Colors-Text-text-white, #fff);
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        width: max-content;
        border: none;
        text-decoration: none;
        @media (max-width: 625px) {
          width: 100%;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    &__right {
      width: 560px;
      margin-left: auto;
      @media (max-width: 1024px) {
        width: 100%;
      }
      &__image {
        margin-left: auto;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__bottom {
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 80px var(--spacing-8xl, 64px) 48px var(--spacing-8xl, 64px);
    align-items: center;
    gap: 8px;
    border-radius: 12px 12px 0 0;
    background: var(--colors-gray-light-mode-600, #475467);
    backdrop-filter: blur(4px);
    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 40px;
    }
    @media (max-width: 625px) {
      padding: 24px;
    }

    &__left {
      width: 447px;
      @media (max-width: 1024px) {
        width: 100%;
      }
      p {
        color: var(--Colors-Base-white, #fff);

        font-family: 'DM Sans';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        margin-top: 21px;
        margin-bottom: 6px;
      }
      span {
        color: var(--Brand-Colors-Secondary-Sunset-100, #fff9f0);

        font-family: 'DM Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
      }
      &__input {
        display: flex;
        width: 447px;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        margin-top: 20px;
        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;
          padding: 4px 4px 4px 4px;
        }
        input {
          width: 100%;
          padding: 17px 20px;
          border: none;
          border-radius: 8px;
          @media (max-width: 768px) {
            width: 100%;
            padding: 17px 20px;
          }
          &::-ms-input-placeholder {
            color: var(
              --neutral-colors-Gray-neutral-400,
              var(--colors-gray-light-mode-400, #98a2b3)
            );
            text-align: left;
            font-family: 'DM Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 145%;
            @media (max-width: 768px) {
              text-align: left;
            }
          }

          &::placeholder {
            color: var(
              --neutral-colors-Gray-neutral-400,
              var(--colors-gray-light-mode-400, #98a2b3)
            );
            text-align: left;
            font-family: 'DM Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 145%;
            @media (max-width: 768px) {
              text-align: left;
            }
          }
        }
        button {
          display: flex;
          padding: 16px 24px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
          color: var(--Colors-Text-text-white, #fff);
          text-align: center;
          font-family: 'DM Sans';
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          border: none;
          min-width: max-content;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
    &__right {
      width: 452px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      @media (max-width: 1024px) {
        width: 100%;
      }

      &__official {
        color: var(--Colors-Base-white, #fff);

        font-family: 'DM Sans';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
      }

      &__btns {
        display: flex;
        align-items: center;
        gap: 16px;
        @media (max-width: 1024px) {
          order: 3;
        }
        button {
          border: none;
          border-radius: 100%;
        }
      }

      &__text {
        color: var(--Brand-Colors-Secondary-Sunset-100, #fff9f0);

        font-family: 'DM Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
      }
      &__privicy {
        display: flex;
        align-items: center;
        gap: 20px;
        a {
          color: #fff9f0;
          font-family: 'DM Sans';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
      }
    }
  }

  &__copy {
    color: var(--Ash-White, #f8f8f8);
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: var(--colors-gray-light-mode-600, #475467);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 12px 12px;
    text-align: center;
    &__line {
      width: calc(100% - 128px);
      height: 1px;
      background-color: #6e7f98;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 48px 0;
    }
  }
}

.subscribe_btn {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
  color: var(--Colors-Text-text-white, #fff);
  text-align: center;
  font-family: 'DM Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border: none;
  min-width: 100%;
  margin-top: 10px;
}
