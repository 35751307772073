@import '/src/styles/colors/primary.scss';

.FileCard {
  position: relative;
  align-items: flex-start;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 160px;
  justify-content: space-between;
  padding: 16px;
  .drop_icon {
    visibility: hidden;
  }
  &:hover {
    border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);
    box-shadow: 0px 8px 16px -4px rgba(16, 24, 40, 0.12);
    .drop_icon {
      visibility: visible;
    }
  }

  &_Top {
    cursor: pointer;

    &_Title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      align-self: stretch;
      overflow: hidden;
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      text-overflow: ellipsis;
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      padding-top: 12px;
    }
  }

  &_Bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    margin-top: 20px;

    &_Info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      flex: 1;
      margin-top: 6px;
      &_Date {
        display: flex;
        align-items: center;
        gap: 6px;

        span {
          color: var(
            --neutral-colors-Gray-neutral-500,
            var(--colors-gray-light-mode-500, #667085)
          );
          font-family: 'DM Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
  }
}

.card {
  display: flex;
  // width: 264px;
  height: 160px;
  padding: var(--spacing-xl, 16px);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 12px;
  // background: var(--Brand-Colors-Secondary-Periwinkle-100, #ecf2ff);
  // border: 1px solid var(--Brand-Colors-Secondary-Periwinkle-100, #ecf2ff);
  border: 1px solid #eaecf0;
  &:hover {
    cursor: pointer;
    // border: 1px solid var(--Brand-Colors-Secondary-Periwinkle-300, #d0deff);
    // background: var(--Brand-Colors-Secondary-Periwinkle-200, #e3ebff);
    border: 1px solid #e2fbfb;
    box-shadow: 0px 8px 16px -4px rgba(16, 24, 40, 0.12);
    // &:hover {
    //   .card__footer__dots {
    //     display: block;
    //   }
    // }
  }
  &__header {
    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      border-radius: 100%;
      object-fit: cover;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    &__badge {
      display: flex;
      height: 24px;
      padding: 0px var(--spacing-md, 8px);
      justify-content: center;
      align-items: center;
      gap: var(--spacing-xs, 4px);
      border-radius: 16px;
      text-align: center;
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  &_heading {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    text-overflow: ellipsis;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-top: 12px;
  }
  &__date {
    display: flex;
    align-items: end;
    gap: 6px;
    span {
      color: var(
        --neutral-colors-Gray-neutral-500,
        var(--colors-gray-light-mode-500, #667085)
      );
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin-top: 6px;
    }
  }
  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    margin-top: auto;
    &__avatars {
      border-radius: 9999px;
      background: var(--Brand-Colors-Secondary-SandStone-900, #f8f1e4);
      display: flex;
      padding: var(--spacing-xs, 4px);
      align-items: center;
      gap: -6px;
    }
    &__dots {
      display: none;
    }
  }
}

.inprogress {
  background: #dbe8f7;
  color: #2e90fa;
}
.not_started {
  background: var(--colors-gray-light-mode-200, #eaecf0);
  color: #344054;
}
.in_review {
  background: var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
  color: #a46404;
}

.published {
  color: #b54708;
  background: var(--Colors-Warning-100, #fef0c7);
}

.completed {
  background: var(--Brand-Colors-Secondary-Moonstone-200, #e2fbfb);
  color: #00a7b7;
}
.DropdownItem {
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    color: gray(900);
  }

  &_Text {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
  }
}
.DotsColor {
  .btn.show {
    svg {
      stroke: #101828;
    }
  }
  svg {
    stroke: #101828;
  }
}

.TileInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  &_AvatarGroup {
    display: flex;
    padding: var(--spacing-xs, 4px);
    align-items: center;
    gap: -6px;
    border-radius: 9999px;
    background: var(--Brand-Colors-Secondary-SandStone-900, #f8f1e4);
    padding: 4px;

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #fff;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      // width: 24px;
      // height: 24px;
      border-radius: 50%;
      // border: 1px solid #fff;

      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      color: #00a7b7;
      // background-color: #00a8b711;
    }

    & > :not(:first-child) {
      margin-left: -8px;
    }
  }
}

.collaborator_name {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  text-align: center;
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.rounded_profile {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  font-size: fontsize('s16');
  line-height: fontsize('s24');
  color: #00a7b7;
  background-color: #f2fbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
