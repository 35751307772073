@import '/src/styles/base/fonts';
@import '/src/styles/base/typography';
@import '/src/styles/variables/colors';
@import '/src/styles/functions/@mixin-module';

.CreateBriefHeader {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  background: linear-gradient(180deg, #d0d4dd 0%, #e4e7ec 16.15%, #e4e7ec 100%);
  margin-bottom: 16px;
  margin: 0 18px;

  &_Tab {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    @include text-md-Regular;
    color: font_clr(d-grey-700) !important;
  }
  :global(.nav-link.active) {
    background-color: white;
    box-shadow: 0px 8px 16px -4px rgba(16, 24, 40, 0.24);
  }
}
