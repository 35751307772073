@import '/src/styles/colors/primary.scss';

.CreationCard {
  position: relative;
  display: flex;
  padding: 22px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  margin-right: 24px;

  border-radius: 8px;
  border: 1px solid gray(100);
  background: gray(25);

  box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.12);

  &_IconContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f2fbfb;
  }

  &_Icon {
    color: #00a7b7;
  }

  &_Title {
    color: gray(900);
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  &_Description {
    color: gray(500);
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    font-weight: 300;
  }

  &_ButtonContainer {
    margin-top: auto;
  }
}
