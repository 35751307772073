@import '/src/styles/functions/@mixin-module';
@import '/src/styles/colors/primary.scss';
.Card {
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 8px 16px -4px #1018281f;
    border-color: #e2fbfb;
  }
  &__CardWrapper {
    gap: 8px;
    padding: 16px 16px 14px;
    // border-bottom: 1px solid #e4e7ec;
    min-height: 102px;
    &_BottomSection {
      span {
        color: #475467;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        font-family: 'Poppins';
      }
    }
    &__footer {
      padding: 14px 16px;
    }
  }

  &__Title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
    text-overflow: ellipsis;

    /* Headings/sm/Semibold */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
  &__Description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: gray(500);
  }
}
.DropdownList {
  @include text-md-Regular;
  padding: 10px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #101828;
}
.CardCalenderEvent {
  position: fixed !important;
  width: 350px !important;
  background-color: #ffffff;
  min-height: 204px !important;
  padding: 16px;
  box-shadow:
    0px 4px 16px -4px rgba(16, 24, 40, 0.1),
    0px 1px 6px -2px rgba(16, 24, 40, 0.09);
  border-radius: 8px;
  gap: 12px;
  z-index: 99999 !important;
  p,
  span {
    @include text-md-Regular;
    display: flex;
  }
  &__Title {
    margin-left: 10px;
    color: #101828;
    max-width: 129px;
    height: 16px;
    overflow: hidden;
    white-space: break-spaces;
    font-size: 12px;
    font-weight: 500;
    font-family: 'DM Sans';
  }
  &__CardTitle {
    margin-left: 10px;
    color: #101828;
    max-width: 200px;
    height: 18px;
  }
  &__TaskDetails {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &_Heading {
      color: #98a2b3;
      min-width: 67px;
    }
    &_Info {
      color: #475467;
      gap: 4px;
      align-items: center;
    }
  }
  @media (min-width: 768px) {
    left: auto;
  }
}

.DropRotate {
  button {
    svg {
      transform: rotate(90deg);
    }
  }
}

.card_divider {
  height: 1px;
  border-radius: 12px;
  background-color: #eaecf0;
  width: 90%;
  margin: 0 auto;
}

.card_footer_name_section {
  display: flex;
  align-items: center;
  gap: 8px;
  &__name {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  &__avatar {
    height: 24px;
    width: 24px;
    background-color: #f2fbfb;
    border-radius: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 100%;
      width: 100%;
      color: #00a7b7;
    }
  }
  &__userInitials {
    height: 24px;
    width: 24px;
    background-color: #f2fbfb;
    border-radius: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00a7b7;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  &__image {
    height: 24px;
    width: 24px;
    &__img {
      border-radius: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.link_card_draft {
  color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
