.TeamWrapper {
  padding: 24px;
  &_Heading {
    color: #101828;
  }
  &_Table > div {
    min-height: 50vh;
  }
}

.TeamMembersWrapper {
  background-color: #fff;
  position: sticky;
  top: 0;
  &__Header {
    gap: 16px;
    padding: 20px 24px;
    &_Arrow {
      margin-top: 5px;
      cursor: pointer;
      animation: SlideLeft 0.5s ease-in-out;

      @keyframes SlideLeft {
        from {
          transform: translateX(100%);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
    &_Details {
      animation: SlideDown 0.5s ease-in-out;
      @keyframes SlideDown {
        from {
          transform: translateY(-100%);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }
      flex: 1;
      gap: 12px;
      &_TeamAvatar {
        width: 64px;
        height: 64px;
        border-radius: 200px;
      }
    }
  }
  &__TeamDetails {
    flex: 1;
    gap: 4px;
    &_CreatedOn {
      color: #667085;
      font-size: 12px;
      font-weight: 400;
      font-family: 'DM Sans';
      line-height: 16px;
    }
    &_Name {
      color: #101828;
      font-size: 16px;
      font-family: 'Poppins';
      line-height: 24px;
      font-weight: 600;
    }
    &_Description {
      color: #101828;
      max-width: 717px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      font-family: 'DM Sans';
      line-height: 20px;
    }
  }
}

.TeamsData {
  &__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid #e4e7ec;
    padding-bottom: 8px;
    background-color: #f9fafb;
    padding: 8px 16px;
    border-radius: 4px;
    span {
      color: #667085;
      font-family: 'DM Sans';
    }
    .teamMamber {
      font-size: 14px;
      font-weight: 400;
      font-family: 'DM Sans';
      line-height: 20px;
      color: #101828;
    }
  }
}
