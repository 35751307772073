.SocialButton{
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 50px !important;
    margin: 0px !important;
    font-size: '0' !important;
}
.SocialWrapper{
    display: flex;
}
.Dropdown {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 1000;
    color: black;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
    margin-top: 10px;
  }
  
  .DropdownItem {
    padding: 8px 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .DropdownItem:hover {
    background: #f0f0f0;
    
    color: black;
  }
  