.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-cs {
  background: #f4f9fa;
  border-radius: 15px;
  width: 450px;
  // min-width: 450px;

  // padding: 1.5rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
  position: relative;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

  background: linear-gradient(180deg, #e2fbfb 0%, #ffffff 100%);
}

.close-button-container {
  padding: 1rem 0;
  display: flex;
  justify-content: flex-end;
}

.close-button {
  // position: absolute;
  // top: 10px;
  // right: 10px;
  background: white;
  // border: none;
  // font-size: 1.5rem;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  cursor: pointer;
}

.modal-icon {
  margin-bottom: 1.5rem;
}

.icon-placeholder {
  width: 100px;
  height: 100px;
  background-color: #47adb7;
  border-radius: 15px;
  margin: 0 auto;
}

.model-container {
  padding: 1.5rem;
  border: 1px solid;
  /* Adjust the border thickness as needed */
  border-image: linear-gradient(
    180deg,
    rgba(0, 167, 183, 0.2) 0%,
    #ffffff 100%
  );
  border-image-slice: 1;
  border-radius: 20px;
}

.modal-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #101828;
  margin-bottom: 0.5rem;
  font-family: 'Poppins';
}

.modal-message {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-family: 'DM Sans';
}

.dashboard-button {
  background-color: #1a1a1a;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 500;
  line-height: 20px;
  font-family: 'Poppins';

  &:hover {
    background-color: #333;
  }
}
