.Popover {
  max-width: fit-content;
}

.PopoverContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  padding: 1.5rem;
}
