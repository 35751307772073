@import '/src/styles/colors/primary.scss';

.TableRow {
  font-family: 'DM Sans';
  cursor: pointer;
  vertical-align: middle;
  font-size: 14px;

  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: gray(900);
    padding: 12px 8px;
  }
}

.contents {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: gray(900);
  padding: 16px 24px !important;
}

.customAvatarGroup {
  display: flex;
  justify-content: start;
}

.Title {
  display: '-webkit-box';
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  text-overflow: ellipsis;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 24px;
}
.table_other_fields {
  padding: 12px 24px;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.editors {
  display: flex;
  align-items: center;
  height: 100%;
}

.avatar {
  width: 32px;
  height: 32px;
  font-size: 10px;
}

.DropdownItem {
  display: flex;
  align-items: center;
  gap: 12px;

  &_Text {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: #101828;
  }
}

.card__header__badge {
  display: flex;
  height: 24px;
  padding: 0px var(--spacing-md, 8px);
  align-items: center;
  gap: var(--spacing-xs, 4px);
  border-radius: 4px;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: fit-content;
}

.lead_generation {
  border: 1px solid rgba(28, 138, 202, 0.4);
  color: #1c8aca;
}
.brand_awareness {
  border: 1px solid rgba(52, 64, 84, 0.3);
  color: #344054;
}
.engagement {
  border: 1px solid rgba(8, 93, 58, 0.3);
  color: #085d3a;
}

.sales_conversion {
  border: 1px solid rgba(164, 100, 4, 0.3);
  color: #a46404;
}
