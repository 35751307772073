.documentHeader {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 16px;
  flex-direction: column;
  margin: 30px auto;
  width: 60%;

  .documentTitle {
    display: flex;
    align-items: flex-start;

    .backIcon {
      cursor: pointer;
      margin: 5px;
    }

    .documentName {
      padding: 0 10px;
    }

    .created-by {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #101828;
    }
  }

  .content {
    display: flex;
  }
}

.content ul li {
  list-style: disc;
}

.content p {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
  color: #475467;
}

.content h1 {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  /* or 150% */

  letter-spacing: -0.02em;

  /* Gray/900 */

  color: #101828;
}

.content h3 {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height, or 127% */

  /* Gray/900 */

  color: #101828;
}

.content ul,
.content ol {
  padding-left: 1.2em;
}

.content code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.content pre {
  background: #0d0d0d;
  color: #fff;
  font-family: 'Inter' !important;
  font-weight: 400;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.content pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.content img {
  max-width: 100%;
  height: auto;
}

.content blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.content hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

.content ul,
.content ol {
  padding: 0 2rem;
  font-family: 'Inter' !important;
  font-weight: 400;
}

.content h3,
.content h4,
.content h5,
.content h6 {
  line-height: 1.1;
  font-family: 'Inter' !important;
  font-weight: 500;
}

.content code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
  font-family: 'Inter' !important;
  font-weight: 400;
}

.content pre {
  background: #0d0d0d;
  color: #fff;
  font-family: 'Inter' !important;
  font-weight: 400;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.content code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.content img {
  max-width: 100%;
  height: auto;
}

.content blockquote {
  padding-left: 1rem;
  border-left: 3px solid #999999;
  font-family: 'Inter' !important;
  font-weight: 400;
}

.content hr {
  border: none;
  border-top: 3px solid #999999;
  margin: 2rem 0;
}
