.relative_meter {
  position: relative;
}
.custom_class {
  position: absolute !important;

  background: rgb(221, 55, 55);
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  z-index: 1;
}

.outoff {
  color: var(
    --neutral-colors-Gray-neutral-500,
    var(--colors-gray-light-mode-500, #667085)
  );
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
