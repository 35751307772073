.buttons {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  color: #101828 !important;
  &__skip_btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #101828 !important;
    font-family: 'DM Sans' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    &:hover {
      color: #101828 !important;
    }
  }
}
