@keyframes loading {
  40% {
    background-position: 100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.card {
  display: flex;
  width: 264px;
  height: 180px;
  padding: var(--spacing-xl, 16px);
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--neutral-colors-black-white-white, #fff);
  position: relative;
  &:after {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 180px;
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 80%
    );
    background-size: 200px 180px;
    background-position: -100px 0;
    background-repeat: no-repeat;
    animation: loading 1s infinite;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    &__badge {
      display: flex;
      height: 24px;
      padding: 0px var(--spacing-md, 8px);
      justify-content: center;
      align-items: center;
      gap: var(--spacing-xs, 4px);
      border-radius: 20px;
      text-align: center;
      background-color: #e7e7e7;
      width: 60%;
      position: relative;
    }
    &__avatar {
      height: 24px;
      width: 24px;
      padding: 0px var(--spacing-md, 8px);
      border-radius: 100%;
      background-color: #e7e7e7;
    }
  }
  &_heading {
    height: 15px;
    width: 15px;
    padding: 0px var(--spacing-md, 8px);
    border-radius: 20px;
    width: 80%;
    background-color: #e7e7e7;
    margin-top: 15px;
  }
  &__date {
    height: 10px;
    width: 10px;
    padding: 0px var(--spacing-md, 8px);
    border-radius: 20px;
    width: 70%;
    background-color: #e7e7e7;
    margin-top: 12px;
  }
  &__footer_document {
    margin-top: 15px !important;
  }
  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 6px;
    margin-top: 30px;

    &__avatars {
      height: 34px;
      width: 34px;
      padding: 0px var(--spacing-md, 8px);
      border-radius: 100%;
      background-color: #e7e7e7;
    }
    &__dots {
      height: 24px;
      width: 1px;
      padding: 0px var(--spacing-md, 3px);
      border-radius: 20px;
      background-color: #e7e7e7;
    }
  }
}

.document_card_header {
  display: flex;
  flex-direction: row-reverse;
}

.document_card_header_badge {
  width: 40%;
}

.document_card {
  width: 264px;
  height: 160px;
  position: relative;
  &:after {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 160px;
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 80%
    );
    background-size: 200px 160px;
    background-position: -100px 0;
    background-repeat: no-repeat;
    animation: loading 1s infinite;
  }
}
