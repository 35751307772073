.StatusOption {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &[data-label='not_started'] {
    svg {
      color: #f63804;
    }
  }

  &[data-label='in_progress'] {
    svg {
      color: #2e90fa;
    }
  }

  &[data-label='in_review'] {
    svg {
      color: #800080;
    }
  }

  &[data-label='published'] {
    svg {
      color: #f5dc04;
    }
  }

  &[data-label='approved'] {
    svg {
      color: #008000;
    }
  }
}
