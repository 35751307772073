.outerContainer {
  width: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    &__heading {
      color: #000;

      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
    }
    &__btn {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      border: none;
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: white;
    }
  }
  &__card {
    display: flex;
    height: 80px;
    padding: 0px var(--spacing-3xl, 24px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1.5px solid var(--colors-gray-light-mode-900, #101828);
    background: var(--colors-gray-light-mode-50, #f9fafb);
    margin-top: 8px;

    &__left {
      &__top {
        display: flex;
        align-items: center;
        gap: 4px;
        &__heading {
          color: var(
            --neutral-colors-Gray-neutral-900,
            var(--colors-gray-light-mode-900, #101828)
          );
          font-family: 'Poppins';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
        &__detail {
          color: var(
            --neutral-colors-Gray-neutral-500,
            var(--colors-gray-light-mode-500, #667085)
          );
          font-family: 'Poppins';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
      &__bottom {
        display: flex;
        align-items: center;
        gap: 4px;
        &__heading {
          color: var(
            --neutral-colors-Gray-neutral-900,
            var(--colors-gray-light-mode-900, #101828)
          );
          font-family: 'Poppins';
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }
        &__detail {
          color: var(
            --neutral-colors-Gray-neutral-500,
            var(--colors-gray-light-mode-500, #667085)
          );
          font-family: 'Poppins';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          text-transform: capitalize;
        }
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 8px;
      &__switch {
        width: 100%;
        & > input {
          width: 2.3em !important;
        }
      }
      span {
        color: #000;
        font-family: 'DM Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

.trail_days {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #e2fbfbc7;
  border-radius: 100px;
  padding: 3px 10px;
  font-size: 13px;
  font-family: 'DM Sans';
  color: #00a7b7;
}
