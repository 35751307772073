@import '../../styles/functions/@mixin-module';
.dropDownItemWrapper {
  padding: 10px 14px;
  &__disabled {
    opacity: 0.65;
  }
}
.DropdownWrapper .dropdown-menu.show {
  padding: 0;
}

.listItem {
  gap: 10px;
  min-width: 245px;
  align-items: center;
  p {
    @include text-md;
    color: #101828;
  }
  img {
    width: 14px;
  }
}
.listItem_sm {
  display: flex;
  gap: 10px;
  max-width: 187px;
  align-items: center;
  p {
    @include text-md;
    color: #101828;
  }
  img {
    width: 14px;
  }
}
.list {
  list-style: none;
  li {
    @include text-md;
    color: #101828;
    padding: 10px 14px;
    display: flex;
    justify-content: flex-start;
    gap: 13px;
    width: 245px;

    &:hover {
      background-color: #e8ecef;
    }
  }
}
.Toast__Body {
  position: absolute;
  top: 156px;
  left: -120px;
  max-width: fit-content;
  background-color: #fff;
}

@media (min-width: 768px) {
  .Toast__Body {
    top: 60px;
    right: 0;
    left: auto;
  }
}
