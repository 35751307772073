.container {
  display: grid;
  gap: 8px;
}

.row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 8px;
}

.col {
  display: flex;
  justify-content: center;
}

// Gauge meter

/* src/Meter.css */
.gauge_container {
  position: relative;
  min-width: 300px;
  svg {
    width: 100%;
  }
}

.gauge_text {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  span {
    color: var(
      --neutral-colors-Gray-neutral-500,
      var(--colors-gray-light-mode-500, #667085)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.zero {
  color: var(
    --neutral-colors-Gray-neutral-400,
    var(--colors-gray-light-mode-400, #98a2b3)
  );
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  bottom: 7%;
  left: 8%;
}

.full {
  color: var(
    --neutral-colors-Gray-neutral-400,
    var(--colors-gray-light-mode-400, #98a2b3)
  );
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  bottom: 7%;
  right: 4%;
}

text {
  visibility: hidden;
}
