.contentFlexWrapper {
  width: 100%;
  // height: calc(100vh - 68px);
  // margin-top: 68px;
  // overflow-y: auto;
}

.contanet_container {
  // desktop
  @media (min-width: 1024px) {
    width: calc(100vw - 241px);
    margin-left: 240px;
    padding: 8px;
  }
  height: 100vh;
  background-color: #0f172a;
  border-radius: 0 20px 20px 0;
  &__header {
    display: flex;
    padding: var(--spacing-lg, 12px) var(--spacing-3xl, 24px);
    align-items: center;
    gap: var(--spacing-xs, 4px);
    align-self: stretch;
    background: var(--Brand-Colors-Secondary-Sunset-200, #fdc8c8);
    color: var(
      --Semantic-Colors-Yellow-700,
      var(--Colors-Warning-700, #74061e)
    );
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 9;
    span {
      svg {
        height: 16px;
        width: 16px;
        transform: rotate(180deg);
      }
    }
  }
}

.content {
  background-color: #ffffff;
  // padding: 2.5rem;
  margin-top: 68px;
  // margin-bottom: 8px;
  border-radius: 0 0 20px 20px;
  height: calc(100vh - 85px);
  overflow-y: auto;

  &[data-diff-style='true'] {
    padding: 0;
  }
}

.content_hidescroll {
  background-color: #ffffff;
  // padding: 2.5rem;
  margin-top: 68px;
  // margin-bottom: 8px;
  border-radius: 0 0 20px 20px;
  height: calc(100vh - 85px);
  overflow-y: hidden !important;

  &[data-diff-style='true'] {
    padding: 0;
  }
  & > div {
    overflow: hidden;
  }
}

.past_due_header {
  display: flex;
  padding: var(--spacing-lg, 12px) var(--spacing-3xl, 24px);
  align-items: center;
  gap: var(--spacing-xs, 4px);
  align-self: stretch;
  // background: var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
  // color: var(
  //   --Semantic-Colors-Yellow-700,
  //   var(--Colors-Warning-700, #b54708)
  // );

  background-color: red;
  color: #ffffff;

  /* Body/sm/Regular */
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  span {
    svg {
      height: 16px;
      width: 16px;
      transform: rotate(180deg);
    }
  }
}

.mobileModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;

  .mobileModalContent {
    background-color: white;
    padding: 24px;
    border-radius: 12px;
    position: relative;
    max-width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;

    h3 {
      margin: 0 0 12px 0;
      color: #1a1a1a;
      font-size: 18px;
      font-weight: 600;
    }

    p {
      margin: 0 0 20px 0;
      color: #4a4a4a;
      font-size: 14px;
      line-height: 1.5;
    }

    .gotItButton {
      background-color: #0f172a;
      color: white;
      border: none;
      padding: 12px 24px;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #1e293b;
      }

      &:active {
        transform: translateY(1px);
      }
    }
  }
}
