@import '/src/styles/colors/primary.scss';
@import '/src/styles/variables/colors';
@import '/src/styles/functions/@mixin-module';

.Projects {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0;

  &_Wrapper {
    display: flex;
    flex-direction: column;

    &_Navigation {
      display: flex;
      background-color: gray(50);
      align-items: center;
      gap: 16px;
      border-radius: 8px;
      padding: 8px 16px;
      margin-bottom: 8px;

      &_Icon {
        a {
          padding: 0;
        }

        a:hover > svg {
          stroke: #00a7b7;
        }

        a[aria-selected='true'] > svg {
          stroke: #00a7b7;
        }
      }
    }
  }
}

.ProjectDetail {
  &_titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 24px 12px;
    border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);

    &_arrow {
      cursor: pointer;
      width: 20px;
    }

    &_title {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      width: 100%;
    }

    &_description {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      color: #667085;
      font-size: 12px;
      line-height: 20px;

      width: 75%;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @supports (-webkit-line-clamp: 1) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    &_editors {
      cursor: pointer;
      display: flex;
      height: fit-content;
      margin-right: 1rem;
    }

    &_editorCount {
      width: 32px;
      height: 32px;
      color: #98a2b3;
      position: relative;
      background: #f9f5ff;
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 200px;
      color: #7f56d9;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
    }

    &_editorAdd {
      cursor: pointer;
      width: 32px;
      height: 32px;
      color: #98a2b3;

      border-radius: 200px;
      border: 1px dotted #d0d5dd;

      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (min-width: 768px) {
    &_titleContainer {
      padding: 24px;
      gap: 1rem;
    }
  }
}

.grid {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-auto-rows: 1fr;
  grid-auto-flow: row dense;
  grid-gap: 20px;
  counter-reset: boxes;
}

@media (max-width: 500px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .wide {
    grid-column: span 1;
  }
}

.avatar {
  width: 32px;
  height: 32px;
  font-size: 10px;
}

.ContentPlanner {
  display: flex;
  flex-direction: column;
  gap: 32px;
  &_Toast {
    display: none;
  }
  &_Heading {
    color: gray(900);
  }

  &__Wrapper {
    display: flex;
    flex-direction: column;

    position: relative;

    &_InputSection {
      position: absolute;
      top: -12px;
      right: 0;

      svg {
        stroke: gray(500);
        filter: brightness(100%);
      }

      &_Filters {
        display: flex;
        align-items: center;
      }

      &_DropDown {
        width: 600px;
        padding: 12px;
        animation: OpacityBreak 0.5s ease-in-out;
        @keyframes OpacityBreak {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }

    &_Navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      height: auto;
      padding: 0 24px;
      border-bottom: 1px solid var(--Neutral-100, #e2e8f0);
      background: var(--Colors-Base-white, #fff);

      &__left {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &__right {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px 2px;
        border-radius: 4px;
        margin: 6px 0;
      }

      &_Filter {
        font-weight: 700;
        font-size: 14px;
        color: #182230;
        cursor: pointer;
        font-family: 'DM Sans';
        padding: 8px !important;

        &:hover {
          color: #00a7b7;
          svg {
            color: #00a7b7;
          }
        }
      }

      &_Icon {
        a {
          padding: 8px;
        }

        a[aria-selected='true'] {
          stroke: #00a7b7;
          color: #00a7b7 !important;
          border-bottom: 1px solid #00a7b7;
        }
        &__Item {
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(
            --neutral-colors-Gray-neutral-700,
            var(--colors-gray-light-mode-700, #344054)
          );
          text-align: center;
          font-family: 'DM Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          border-bottom: 1px solid transparent;
          height: 60px;
          &:hover {
            border-bottom: 1px solid #00a7b7;
            color: #00a7b7 !important;
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    &__Wrapper {
      &_Navigation {
        &__right {
          margin: 0;
        }
      }
    }
  }
}

.project_tiles_coontainer {
  padding: 24px 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  @media (min-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
  }
}
