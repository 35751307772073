.container {
  display: flex;
  gap: 3rem;
  width: 100%;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 3rem;
  }
}
.plans_container {
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
    padding-top: 0;
  }
}
.subscription {
  &__root {
    .stickyContent {
      // position: sticky;
      // top: 68px;
      padding-bottom: 24px;
      background-color: #fff;
      z-index: 99;

      .headingBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        .subHeading {
          color: #cf6117;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 0.12px;
        }

        .heading {
          color: #101828;
          text-align: center;
          font-size: 48px;
          font-weight: 600;
          line-height: 60px;
          letter-spacing: -0.96px;
          margin-bottom: 16px;
          font-family: 'Inter';
        }

        .desc {
          color: var(
            --neutral-colors-Gray-neutral-900,
            var(--colors-gray-light-mode-900, #101828)
          );
          font-family: 'DM Sans';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
      }

      .btnAction {
        &,
        &:focus,
        &:active {
          margin-top: auto;
          padding: 12px 20px;
          border-radius: 8px;
          border: 1px solid #7f56d9;
          background: #7f56d9;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          cursor: pointer;
          outline: none;
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #fff;
        }
      }

      .tabsBtnWrapper {
        padding: 6px;
        border-radius: 8px;
        border: 1px solid #eaecf0;
        background: #f9fafb;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-start;
        width: max-content;

        .tabBtn {
          padding: 10px 14px;
          font-size: 16px;
          color: #667085;
          font-weight: 600;
          line-height: 24px;
          cursor: pointer;
          transition: all 0.5s;
        }

        .tabBtn.active,
        .tabBtn:hover {
          color: #344054;
          border-radius: 6px;
          background: #fff;
          box-shadow:
            0px 1px 2px 0px rgba(16, 24, 40, 0.06),
            0px 1px 3px 0px rgba(16, 24, 40, 0.1);
        }
      }
    }

    .tabsContainer {
      .tabsContentWrapper {
        display: flex;
        gap: 32px;
        overflow: auto;
        justify-content: center;

        .planCard {
          display: flex;
          flex-direction: column;
          align-items: center;
          // flex: 1;

          border-radius: 20px;
          border: 1px solid #fddcab;
          background: #fff;
          box-shadow:
            0px 4px 6px -2px rgba(16, 24, 40, 0.03),
            0px 12px 16px -4px rgba(16, 24, 40, 0.08);
          overflow: hidden;

          .upperContentBox {
            padding: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            & img {
              min-width: 40px;
              min-height: 40px;
              object-fit: contain;
              margin-bottom: 20px;
            }

            .planName {
              font-family: 'Inter';
              font-size: 16px;

              font-weight: 500;
              // line-height: 30px;
              color: #94a3b8;
              font-style: normal;
              font-weight: 600;
              line-height: 30px;
            }

            .heading {
              color: #101828;
              font-size: 48px;
              font-weight: 600;
              line-height: 60px;
              letter-spacing: -0.96px;
              margin-bottom: 8px 0;
              font-family: 'Inter';
            }
            .perDuration {
              color: #667085;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
          .listWrapper {
            padding: 32px;
            padding-top: 0;
            display: flex;
            width: 100%;
            flex: 1;
            flex-direction: column;
            align-items: flex-start;

            .listItem {
              display: flex;
              align-items: center;
              font-family: 'Inter';

              &:not(:last-child) {
                margin-bottom: 16px;
              }

              img {
                min-width: 24px;
                min-height: 24px;
                margin-right: 12px;
              }
              span {
                color: #1d2939;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
          .btnWrapper {
            width: 100%;
            padding: 32px 32px 24px 32px;
            // background-color: #f9fafb;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .btnAction {
            &,
            &:focus,
            &:active {
              margin-top: auto;
              padding: 12px 20px;
              border-radius: 8px;

              border: 1px solid #1d2939;
              background: #fff;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              cursor: pointer;
              outline: none;
              width: 100%;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              color: #1d2939;
            }
          }

          .btnCancel {
            &,
            &:focus,
            &:active {
              margin-top: auto;
              padding: 12px 20px;
              border-radius: 8px;
              border: 1px solid red;
              background: red;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              cursor: pointer;
              outline: none;
              width: 100%;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.confrimation {
  &__root {
    .headingWrapper {
      display: flex;
      flex-direction: column;
      position: sticky;
      top: 68px;
      background-color: #fff;
      padding-bottom: 24px;
      margin-bottom: 76px;
      z-index: 99;

      .backLinkWrapper {
        color: #6941c6;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin-right: 8px;
        }
        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
      }

      .headingBox {
        margin-top: 24px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eaecf0;
        .heading {
          margin-bottom: 4px;
          color: #101828;
          font-size: 30px;
          font-weight: 600;
          line-height: 38px;
        }
        .desc {
          color: #475467;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
        .paymentDetailHeading {
          color: #475467;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          font-family: 'DM Sans';
        }
      }
    }

    .thankYouWrapper {
      .confrimIcon {
        min-width: 48px;
        max-width: 48px;
        min-height: 48px;
        max-height: 48px;
        object-fit: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 8px solid #ecfdf3;
        background: #dcfae6;
        img {
          height: 24px;
          width: 24px;
        }
      }
      .heading {
        color: #11142c;
        font-feature-settings:
          'clig' off,
          'liga' off;
        font-size: 40px;
        font-weight: 600;
        line-height: 40px;
        margin: 42px 0;
      }
      .desc {
        color: #475467;
        font-feature-settings:
          'clig' off,
          'liga' off;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        max-width: 807px;
      }
    }

    .subscriptionCardsWrapper {
      margin-top: 96px;
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      max-width: 380px;
      width: 100%;

      .card {
        display: flex;
        flex-direction: column;
        padding: 24px;
        border-radius: 8px;
        border: 1px solid #d0d5dd;

        .headingBox {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;
          .heading {
            color: #101828;
            font-size: 24px;
            font-weight: 600;
            line-height: 38px;
            margin-bottom: 4px;
          }
          .desc {
            color: #475467;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
        .cardDetailBox {
          display: flex;
          flex-direction: column;
          .detailList {
            color: #475467;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            &:not(:last-child) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}

.billing {
  &__root {
    &__header {
      display: flex;
      padding: var(--spacing-lg, 12px) var(--spacing-3xl, 24px);
      align-items: center;
      gap: var(--spacing-xs, 4px);
      align-self: stretch;
      background: var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
      color: var(
        --Semantic-Colors-Yellow-700,
        var(--Colors-Warning-700, #b54708)
      );

      /* Body/sm/Regular */
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      span {
        svg {
          height: 16px;
          width: 16px;
          transform: rotate(180deg);
        }
      }
    }
    .headingWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 16px;
        padding-bottom: 1rem;
      }
      // position: sticky;
      // top: 68px;
      background-color: #fff;
      // margin-bottom: 16px;
      // z-index: 99;
      border-bottom: 1px solid #eaecf0;

      .backLinkWrapper {
        color: #00a7b7;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin-right: 8px;
        }
        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
      }

      .headingBox {
        // border-bottom: 1px solid #eaecf0;
        .heading {
          margin-bottom: 4px;
          color: #101828;
          font-size: 30px;
          font-weight: 600;
          line-height: 38px;
        }
        .desc {
          color: #475467;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .outer_container_plantype_card {
      display: flex;
      padding: var(--spacing-xl, 16px);
      align-items: center;
      gap: var(--spacing-xl, 16px);
      align-self: stretch;
      border-radius: 16px;
      border: 1px solid var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
      background: var(--Colors-Base-white, #fff);
    }

    .planTypeCardWrapper {
      flex: 1;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      // padding-bottom: 40px;
      // margin-bottom: 24px;
      // border-bottom: 1px solid #eaecf0;

      .planTypeCard {
        display: flex;
        padding: var(--spacing-md, 8px);
        flex-direction: column;
        // align-items: center;
        gap: var(--spacing-lg, 12px);
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
        background: var(--Brand-Colors-Secondary-Sunset-100, #fff9f0);

        .cardDetailsBox {
          display: flex;
          flex-direction: column;
          // padding: 24px;
          // padding-bottom: 8px;

          .billingCardHeadingWrapper {
            display: flex;
            padding: var(--spacing-none, 0px) var(--spacing-md, 8px);
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            flex-direction: column;
            gap: 1rem;
            @media (min-width: 1024px) {
              flex-direction: row;
            }

            &__heading {
              display: flex;
              align-items: center;
              gap: 16px;

              &__dropicon {
                padding: 16px 20px;
                border-radius: 100px;
                background: var(--Brand-Colors-Secondary-Sunset-500, #fddcab);
              }
              &__name {
                color: var(
                  --neutral-colors-Gray-neutral-900,
                  var(--colors-gray-light-mode-900, #101828)
                );
                text-align: center;

                font-family: 'DM Sans';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
              }
              &__subscription {
                color: var(
                  --neutral-colors-Gray-neutral-500,
                  var(--colors-gray-light-mode-500, #667085)
                );
                text-align: center;
                display: flex;
                align-items: baseline;
                gap: 2px;
                font-family: 'Poppins';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                &__bold {
                  color: var(
                    --neutral-colors-Gray-neutral-900,
                    var(--colors-gray-light-mode-900, #101828)
                  );
                  text-align: center;

                  font-family: 'Poppins';
                  font-size: 30px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 38px;
                }
              }
            }

            .headingBox {
              display: flex;
              flex-direction: column;
              .heading {
                color: var(--gray-900, #101828);
                font-size: 18px;
                font-weight: 500;
                line-height: 28px;
                margin-bottom: 4px;

                .planAccordingTo {
                  display: inline-flex;
                  margin-left: 8px;
                  text-transform: capitalize;
                  padding: 2px 8px;
                  border-radius: 6px;
                  border: 1px solid #d0d5dd;
                  background: #fff;
                  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                  color: #344054;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                }
              }

              .desc {
                color: #475467;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
              }
            }

            .amountBox {
              display: flex;
              align-items: baseline;
              .dollarSign {
                color: #101828;
                font-size: 36px;
                font-weight: 600;
                line-height: 44px;
                letter-spacing: -0.72px;
                align-self: flex-end;
                margin-bottom: 10px;
                margin-right: 2px;
              }

              .value {
                font-size: 48px;
                font-weight: 600;
                line-height: 60px;
                letter-spacing: -0.96px;
              }

              .duration {
                color: #475467;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                align-self: baseline;
                margin-left: 2px;
              }
            }
            .manage_button_container {
              width: 100%;
              // desktop
              @media (min-width: 1024px) {
                max-width: fit-content;
              }
            }
          }

          .optionListWrapper {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            max-width: 517px;

            .listItem {
              display: flex;
              align-items: flex-start;
              max-width: calc(50% - 4px);
              min-width: calc(50% - 4px);

              img {
                margin-right: 12px;
                min-width: 24px;
                max-width: 24px;
                min-height: 24px;
                max-height: 24px;
                object-fit: contain;
              }
              span {
                color: #667085;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
              }
            }
          }
          &__bottom_container {
            display: flex;
            padding: var(--spacing-xl, 16px) var(--spacing-3xl, 24px);
            flex-direction: column;
            align-items: flex-start;
            gap: var(--spacing-3xl, 24px);
            align-self: stretch;
            border-radius: 12px;
            border: 1px solid var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
            background: var(--Colors-Base-white, #fff);
            margin-top: 12px;
            &__heading {
              color: var(--Neutral-300, #94a3b8);

              font-family: 'DM Sans';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
            &__list_container {
              display: flex;
              width: 100%;
              gap: 16px;
              flex-wrap: wrap;
              &__list {
                @media (min-width: 1024px) {
                  width: calc(50% - 16px);
                }
                display: flex;
                align-items: flex-start;
                gap: 12px;
                color: var(
                  --neutral-colors-Gray-neutral-900,
                  var(--colors-gray-light-mode-900, #101828)
                );

                font-family: 'DM Sans';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
              }
            }
          }
        }

        .cardActionBox {
          display: flex;
          padding: 16px 24px;
          border-top: 1px solid #eaecf0;

          .upgradeBtn {
            &,
            &:focus,
            &:active {
              outline: none;
              border: none;
              background-color: transparent;
              margin-left: auto;
              color: #475467;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              cursor: pointer;

              img {
                transform: rotate(135deg);
                margin-left: 8px;
                min-width: 24px;
                max-width: 24px;
                min-height: 24px;
                max-height: 24px;
                object-fit: contain;
              }
            }
          }
        }
      }
    }

    .addedCardsWrapper {
      flex: 1;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      // margin-bottom: 56px;
      font-family: 'Poppins';
      .addedCardsArea {
        gap: 16px;
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;

        .addNewPaymentMethodBtnWrapper {
          display: flex;

          .addNewPaymentMethodBtn {
            color: var(
              --neutral-colors-Gray-neutral-900,
              var(--colors-gray-light-mode-900, #101828)
            );
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            &,
            &:focus,
            &:active {
              display: flex;
              align-items: center;
              color: #475467;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              cursor: pointer;
              border: none;
              outline: none;
              background-color: transparent;

              img,
              svg {
                min-width: 20px;
                max-width: 20px;
                min-height: 20px;
                max-height: 20px;
                object-fit: contain;
                margin-right: 8px;
              }
            }
          }
        }

        .paymentMethodCardsWrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;

          .paymentCard {
            display: flex;
            flex: 1;
            border: 2px solid #eaecf0;
            border-radius: 12px;
            overflow: hidden;
            padding: 18px;
            padding-right: 36px;
            position: relative;

            .checkIconWrapper {
              position: absolute;
              right: 16px;
              top: 16px;
              border: 1px solid #d0d5dd;
              cursor: pointer;
              border-radius: 50%;
              min-width: 16px;
              max-width: 16px;
              min-height: 16px;
              max-height: 16px;
              display: flex;

              &::selection {
                background-color: transparent;
                color: currentColor;
              }

              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                display: none;

                &::selection {
                  background-color: transparent;
                  color: currentColor;
                }
              }
            }

            .cardTypeImgWrapper {
              max-width: 46px;
              min-width: 46px;
              min-height: 32px;
              max-height: 32px;
              border-radius: 6px;
              overflow: hidden;
              margin-right: 12px;
              background-color: #fff;

              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
              }
            }

            .cardDetailWrapper {
              display: flex;
              flex-direction: column;

              .cardDetailBox {
                display: flex;
                flex-direction: column;
                .visaText {
                  color: #101828 !important;
                  font-family: 'Poppins';
                }
                .dateExpired {
                  color: #667085 !important;
                  font-family: 'Poppins';
                }
                .cardTypeText,
                .expiryDate {
                  color: #344054;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;

                  span {
                    text-transform: capitalize;
                  }
                }

                .expiryDate {
                  color: #475467;
                  font-weight: 400;
                }
              }
            }

            .cardActionsWrapper {
              display: flex;
              align-items: center;
              gap: 16px;

              .cardBtn {
                &,
                &:focus,
                &:active {
                  background-color: transparent;
                  border: none;
                  outline: noe;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 20px;
                  cursor: pointer;
                }
              }

              .editBtn {
                &,
                &:focus,
                &:active {
                  color: #6941c6;
                }
              }
              .removeBtn {
                &,
                &:focus,
                &:active {
                  color: #d92d20;
                }
              }
            }
          }

          .paymentCard.active {
            // border: 2px solid #fbb956 !important;
            // background-color: #fbb9563b !important;
            // border-radius: 8px;
            // border: 1.5px solid
            //   var(--Brand-Colors-Secondary-Moonstone-200, #e2fbfb);
            border: 1.5px solid #00a7b7;
            background: var(--Brand-Colors-Secondary-Moonstone-100, #f2fbfb);

            .checkIconWrapper {
              border: 1px solid #00a7b7 !important;
              img {
                display: inline-flex !important;
              }
            }

            .cardTypeText {
              color: #0f172a !important;
            }
            .expiryDate {
              color: #0f172a !important;
            }
          }
        }

        .addPaymentActionWrapper {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .viewAllBtn,
          .viewLessBtn {
            &,
            &:focus,
            &:active {
              display: flex;
              align-items: center;
              color: #98a2b3;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              border: none;
              outline: none;
              background-color: transparent;

              img,
              svg {
                min-width: 20px;
                max-width: 20px;
                min-height: 20px;
                max-height: 20px;
                object-fit: contain;
              }
            }
          }
          .viewLessBtn {
            &,
            &:focus,
            &:active {
              img,
              svg {
                transform: rotate(180deg) !important;
              }
            }
          }
          .nextPaymentbtn {
            &,
            &:focus,
            &:active {
              border-radius: 8px;
              border: 1px solid #000;
              background: #000;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              cursor: pointer;
              padding: 8px 14px;
              color: #fff;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;

              img,
              svg {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }

    .billingTableWrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      font-family: 'Inter';

      .billingHeaderBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .dropdownBtn {
          &,
          &:active,
          &:focus {
            background-color: transparent;
            height: 20px;
            width: 20px;
            color: #98a2b3;
          }
        }
        .dropdownBtn.show {
          color: #475467;
        }
      }

      .billingTableBox {
        // margin-top: 24px;
        overflow: auto;
        position: relative;
      }
    }
  }
}

.subscriptionTable {
  background-color: #fff;
  width: 100%;
  border: 1px solid #f2f6f8;
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: auto;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 894px;
  thead {
    tr {
      th {
        background-color: #f9fafb;
        padding: 12px 24px;

        div {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          color: #344054;
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
        }
      }
    }
  }
  tbody {
    tr {
      &,
      &:hover {
        td {
          box-shadow: none !important;
        }
      }
      td {
        padding: 0 24px !important;
        height: 56px;

        &:first-child {
          width: 490px;
        }

        &:hover {
          box-shadow: none !important;
        }

        .columnContent {
          font-family: 'DM Sans';
          color: #475467;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          align-items: center;
          height: 100%;
          .text {
            font-weight: 500;
          }

          .invoiceFile {
            font-weight: 500 !important;
            font-size: 14px;
          }

          .status {
            border-radius: 16px;
            border: 1px solid #abefc6;
            background: #ecfdf3;
            mix-blend-mode: multiply;
            display: inline-flex;
            padding: 2px 8px 2px 6px;
            text-transform: capitalize;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: #067647;

            img,
            svg {
              margin-right: 4px;
              display: inline-flex;
            }
          }

          .linkText {
            &,
            &:link,
            &:hover,
            &:visited,
            &:active {
              text-decoration: none;
              color: #6941c6 !important;
              cursor: pointer;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.billingSectionHeadingWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 0;

  .heading {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    &.headingDetails {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .desc {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );

    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.toggleSection {
  text-align: center;
  margin: 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  span {
    color: #334155;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.3px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
}

.outer_container {
  padding: 2rem 1.5rem;
  display: flex;
  width: 100%;
  gap: 1rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

// desktop
@media (min-width: 1024px) {
  .utility_width_plan_detail {
    width: 60%;
  }
  .utility_width_order_summary {
    width: 40%;
  }
}
.newMethodText {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #101828;
}

// Subscription Buttons

.basic_btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));

  /* Headings/sm/Medium */
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  width: 100%;
}
.pro_btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Brand-Colors-Primary-Oxford-900, #1d2939);
  background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(
    --neutral-colors-black-white-white,
    var(--Colors-Base-white, #fff)
  );

  /* Headings/sm/Medium */
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  width: 100%;
  &:hover {
    background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);

    border: 1px solid var(--Brand-Colors-Primary-Oxford-900, #1d2939);
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(
      --neutral-colors-black-white-white,
      var(--Colors-Base-white, #fff)
    );
  }
}
.business_btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Brand-Colors-Primary-Oxford-900, #1d2939);
  background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(
    --neutral-colors-black-white-white,
    var(--Colors-Base-white, #fff)
  );

  /* Headings/sm/Medium */
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  width: 100%;
  &:hover {
    background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);

    border: 1px solid var(--Brand-Colors-Primary-Oxford-900, #1d2939);
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(
      --neutral-colors-black-white-white,
      var(--Colors-Base-white, #fff)
    );
  }
}

.utility_padding_botttom {
  padding-bottom: 16px;
}

.paymentDetailHeading {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'DM Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.heading_billing_payment {
  color: #000;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
}

.footer_container {
  background-color: #fff;
  display: flex;
  position: sticky;
  bottom: 0;
  width: 100%;
}

.footer_container_new_user {
  justify-content: center;
  position: static;
}

.cancel_button {
  color: red;
  &:hover,
  &:focus,
  &:active {
    color: red; // Ensure text color remains red
  }
}

.buttons_div {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
