@import '../../styles/base/fonts';
@import '../../styles/base/typography';
@import '/src/styles/colors/primary.scss';
@import '../../styles/functions/@mixin-module';

.Dashboard__TableContainer_Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #eaecf0;
  background: #eaecf050;
  padding: 12px 16px;
  margin-top: 16px;

  &_TitleContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    &_Title {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  &_Navigation {
    cursor: pointer;
    color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
    text-align: center;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;
    &:hover {
      color: #00a7b7;
    }
  }
}

.counter {
  display: flex;
  min-width: 28px;
  height: 28px;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 9999px;
  background: var(--colors-gray-light-mode-100, #f2f4f7);
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-right: 8px;
}
