@import '/src/styles/colors/primary.scss';
@import '/src/styles/functions/@mixin-module';

.Documents {
  display: flex;
  flex-direction: column;
  gap: 32px;
  // padding: 24px 0;

  &_Wrapper {
    display: flex;
    flex-direction: column;

    &_Navigation {
      display: flex;
      background-color: gray(50);
      align-items: center;
      gap: 20px;
      border-radius: 8px;
      padding: 8px 16px;
      margin-bottom: 8px;

      &_Filter {
        font-weight: 600;
        font-size: 14px;
        color: gray(500);
        display: flex;
        gap: 4px;
        font-family: 'DM Sans';
        cursor: pointer;

        &:hover {
          color: #00a7b7;
          svg {
            color: #00a7b7;
          }
        }
      }

      &_Trash {
        display: flex;
        align-items: center;
        gap: 12px;

        font-weight: 600;
        font-size: 14px;
        color: gray(500);
        cursor: pointer;

        &:hover {
          color: error(700);
          svg {
            stroke: error(700);
          }
        }
      }

      &_Icon {
        a {
          padding: 0;
        }

        a:hover > svg {
          stroke: #00a7b7;
        }

        a[aria-selected='true'] > svg {
          stroke: #00a7b7;
        }
      }
    }
  }
}

.GridView {
  display: flex;
  flex-direction: column;
  &_InfiniteScroll {
    min-height: calc(100vh - 150px);
  }
}

.ListView {
  display: flex;
  flex-direction: column;
}

.ContentPlanner {
  display: flex;
  flex-direction: column;
  gap: 32px;
  &_Toast {
    display: none;
  }
  &_Heading {
    color: gray(900);
  }

  &__Wrapper {
    display: flex;
    flex-direction: column;

    position: relative;

    &_InputSection {
      position: absolute;
      top: -12px;
      right: 0;

      svg {
        stroke: gray(500);
        filter: brightness(100%);
      }

      &_Filters {
        display: flex;
        align-items: center;
      }

      &_DropDown {
        width: 600px;
        padding: 12px;
        animation: OpacityBreak 0.5s ease-in-out;
        @keyframes OpacityBreak {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }

    &_Navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      height: 110px;
      padding: 0 12px 10px;
      border-bottom: 1px solid var(--Neutral-100, #e2e8f0);
      background: var(--Colors-Base-white, #fff);

      &__left {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &__right {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &_Filter {
        font-weight: 700;
        font-size: 14px;
        color: #182230;
        cursor: pointer;
        font-family: 'DM Sans';
        padding: 8px !important;

        &:hover {
          color: #00a7b7;
          svg {
            color: #00a7b7;
          }
        }
      }

      &_Icon {
        a {
          padding: 8px;
        }

        a[aria-selected='true'] {
          stroke: #00a7b7;
          color: #00a7b7 !important;
          border-bottom: 1px solid #00a7b7;
        }
        &__Item {
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(
            --neutral-colors-Gray-neutral-700,
            var(--colors-gray-light-mode-700, #344054)
          );
          text-align: center;
          font-family: 'DM Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          border-bottom: 1px solid transparent;
          height: 60px;
          &:hover {
            border-bottom: 1px solid #00a7b7;
            color: #00a7b7 !important;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    &__Wrapper {
      &_Navigation {
        height: 60px;
        padding: 0 24px;
      }
    }
  }
}

.Dashboard__compaigns {
  padding: 0 24px 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  @media (min-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.Loader {
  margin-top: 64px;
  @media (min-width: 768px) {
    margin-top: 16px;
  }
}

// .Dashboard__compaigns {
//   padding: 0 24px 16px;
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   gap: 16px;
//   @media (min-width: 1600px) {
//     grid-template-columns: repeat(5, 1fr);
//   }
// }
