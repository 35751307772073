.publish_container {
  height: calc(100vh - 205px);
  overflow-y: auto;
}
.row_inline {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

// .row_inline {
//   display: grid;
//   gap: 1rem; /* Similar to g-3 in Bootstrap, adjust as needed */

//   /* Default for mobile screens (1 column) */
//   grid-template-columns: repeat(1, 1fr);
// }

// /* For medium devices and above (e.g., 2 columns) */
// @media (min-width: 768px) {
//   .row_inline {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

// /* For large devices and above (e.g., 3 columns) */
// @media (min-width: 1200px) {
//   .row_inline {
//     grid-template-columns: repeat(3, 1fr);
//   }
// }

// /* For extra-large devices and above (e.g., 4 columns) */
// @media (min-width: 1600px) {
//   .row_inline {
//     grid-template-columns: repeat(4, 1fr);
//   }
// }

.card_channel {
  width: 334px;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  padding: 16px 0;
  &:hover {
    transition: ease-in-out 0.5s;
    box-shadow: 0 8px 16px -4px #1018281f;
    cursor: pointer;
  }
  .d_flex_channel_logo_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 12px;
    .button_dots {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      background-color: unset;
      border: none;
    }
    .channel_logo_name {
      display: flex;
      align-items: center;
      gap: 12px;
      .channel_logo {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .logo_name {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #1d2939;
      font-family: 'Poppins';
      margin: 0;
    }
  }
  .detail_section {
    border-top: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
    padding: 16px;
    height: 82px;
    .detail_list {
      list-style-type: none;
      margin: 0 0 8px 0;
      display: flex;
      justify-content: space-between;
      padding: 0;
      &:last-child {
        margin: 0;
      }
      .list_detail_item {
        list-style-type: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #667085;
        font-family: 'DM Sans';
        margin: 0;
      }
      .list_name_item {
        list-style-type: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #101828;
        font-family: 'DM Sans';
        margin: 0;
      }
    }
  }
  .active_section {
    padding: 12px 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .active_status {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #667085;
      font-family: 'DM Sans';
      margin: 0;
    }
  }
}
.main_channel_container {
  padding: 24px 24px 32px;
}
.available_channel_container {
  padding: 24px 24px 32px;
  background-color: #f2fbfb;
  display: none;
}
.heading_channel {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #101828;
  font-family: 'Poppins';
  margin: 0 0 12px 0;
}

.available_channel {
  padding: 16px;
  width: 334px;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  &:hover {
    transition: ease-in-out 0.5s;
    box-shadow: 0 8px 16px -4px #1018281f;
    cursor: pointer;
  }
  .d_flex_channel_logo_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 12px 0;
    .coming_label {
      color: #067647;
      font-size: 14px;
      font-weight: 500;
      font-family: 'DM Sans';
      margin: 0;
      background-color: #dcfae6;
      border-radius: 999px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 8px;
    }
    .channel_logo_name {
      display: flex;
      align-items: center;
      gap: 12px;
      .channel_logo {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .logo_name {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #1d2939;
      font-family: 'Poppins';
      margin: 0;
    }
  }
  .detail_text_box {
    margin-bottom: 20px;
    height: 76px;
    overflow-y: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .para_avail_channel {
      font-size: 14px;
      font-weight: 500;
      font-family: 'DM Sans';
      line-height: 20px;
      color: #667085;
      margin: 0;
    }
  }
  .button_connect {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 14px 8px 14px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'DM Sans';
    line-height: 20px;
    color: #101828;
    background-color: transparent;
    &:hover {
      background-color: #fcfcfd;
    }
  }
}

.modal_parent {
  .modal_dialog {
    max-width: 450px;
  }
  .modal_content {
    padding: 20px;
    background-image: linear-gradient(#e2fbfb, #ffffff);
  }
  .modal_header {
    padding: 0;
    border: none;
    display: flex;
    justify-content: flex-end;
  }
  .btn_close_custom {
    border: 1px solid #eeeeee;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: #fff;
    padding: 8px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal_body {
    padding: 16px 24px;

    background: linear-gradient(180deg, #e2fbfb 0%, #fff 100%);
    border-radius: 12px;
    border: none;
    margin-top: 12px;
    border: 1px solid;
    border-image-source: linear-gradient(
      180deg,
      rgba(0, 167, 183, 0.2) 0%,
      #ffffff 100%
    );
    border: 1px solid rgba(0, 167, 183, 0.2);
    .modal_custom_body {
      .gif-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 12px;
        width: 100px;
        height: 100px;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .publish_content {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: #101828;
        font-family: 'Poppins';
        margin: 0 0 8px 0;
        text-align: center;
      }
      .congrats_para {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #475467;
        font-family: 'DM Sans';
        margin: 0 0 8px 0;
        text-align: center;
        margin-bottom: 32px;
      }
    }
    .goto_Btn {
      padding: 10px 16px 10px 16px;
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 #1018280d;
      background-color: #1d2939;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
      font-family: 'Poppins';
      margin: 0 auto;
      display: block;
      border: none;
    }
  }
  .modal_footer {
    padding: 0;
    border: none;
  }
}

.outerContainer__card__right__switch {
  & > div {
    & > input {
      min-width: 36px !important;
    }
  }
}
