@import '../../styles/base/fonts';
@import '../../styles/base/typography';
@import '../../styles/variables/colors';
@import '../../styles/functions/@mixin-module';

.Drawer {
  gap: fontsize('s24');
  //   min-width: calc(100% - 400px);
  //   max-width: 100%;
  z-index: 1040;
  margin: 16px;

  &_Header {
    padding: 20px 24px;
    border-bottom: 1px solid #e2e8f0;
    font-family: 'Poppins';
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-size: 20px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 30px;

    &_Title  {
      @include Modal-Title;
      color: font_clr(d-grey-800);
    }
  }

  &_Body {
    padding: 0;
    overflow-y: auto;
  }
}
