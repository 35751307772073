.ImgdropDownBtn {
  .ProfileImage {
    height: 40px;
    width: 40px;
    padding: 4px;
    border-radius: 4px;
  }
}
.profileHeader {
  gap: 12px;
  align-items: center;
  .ProfileImage {
    height: 40px;
    width: 40px;
    padding: 0px;
  }
  &__infoSection {
    span {
      font-size: 14px;
      line-height: 20px;
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &_name {
      color: #344054;
    }
    &_email {
      color: #667085;
    }
  }
}
.profileOptionListItem {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 14px;
  font-size: 14px;
  padding: 10px 16px !important;
}

.user_container {
  display: flex;
  align-items: center;
  gap: 8px;
  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__name {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );

      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    &__user {
      display: flex;
      align-items: center;
      gap: 10px;
      &__email {
        color: var(
          --neutral-colors-Gray-neutral-500,
          var(--colors-gray-light-mode-500, #667085)
        );
        font-family: 'Poppins';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
      &__drop {
        color: #101828;
        height: 20px;
        width: 20px;
        &__icon {
          height: 16px;
          width: 16px;
          margin-top: -5px;
        }
      }
    }
  }
}

.user_name_box {
  display: flex;
  align-items: end;
  gap: 10px;
}
