.onboarding {
  max-width: 650px;
  width: 100%;
  margin: 48px auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.onboarding-step5 {
  max-width: 1288px;
  width: 100%;
  margin: 48px auto;
  padding: 0 24px;
}
