@import '../../styles/base/fonts';
@import '../../styles/base/typography';
@import '../../styles/variables/colors';
@import '../../styles/functions/@mixin-module';

.StyleGuide {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: fontsize('s12');
  margin: fontsize('s32') 0;
  padding: 0 24px;

  &__GenerateWithAiBanner {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: #fffaeb;
    margin-bottom: 20px;

    &_Text {
      color: #101828;
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }

    &_Button {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #00a7b7;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: #00a7b7;
      }
    }
  }

  &__StyleGuideCard {
    gap: 1rem;
    padding: 20px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    background: #f9fafb;
    &_Row {
      min-height: 54px;
      display: flex;
      align-items: stretch;
    }

    &_TitleContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      &_Title {
        @include Heading-md;
        color: #00a7b7;
        font-weight: 500;
      }

      &_HoverIcon {
        display: none;
        color: #00a7b7;
      }
    }

    &_Details {
      @include text-sm-lh18;
      color: font_clr(d-grey-700);
      @media screen and (max-width: 992px) {
        margin-top: 8px;
      }
      & strong {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 12px;
      }
      & span {
        font-family: 'DM Sans';
        font-weight: 400;
        font-size: 12px;
      }
    }

    &:hover {
      // background: font_clr(PressRelease-bg);
      cursor: pointer;
      .StyleGuide__StyleGuideCard_TitleContainer_HoverIcon {
        display: flex;
      }
    }
  }
}

@keyframes loaderSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.LoaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #f2f4f7;
  padding: 20px 24px 20px 24px;
  border-radius: 8px;
  gap: 16px;

  p {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #344054;
  }

  svg {
    animation: loaderSpinner 1.5s infinite linear;
  }
}

.Form {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  gap: fontsize('s24');

  &_InputsWrapper {
    display: flex;
    flex-direction: column;
    padding: 24px 20px;
    gap: fontsize('s20');
    border-radius: 8px;
    background: #f9fafb;
  }
  &_SubmitButtonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: fontsize('s12');

    flex: 1 0 0;
    align-self: stretch;
    &_SubmitButton {
      @include text-md-Regular;
      display: flex;
      width: 109px;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: fontsize('s8');
      border-radius: 8px;
      border: 1px solid #f2fbfb;
      background: #f2fbfb;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: #00a7b7;
      &:disabled {
        border: 1px solid #f2fbfb;
        background: #f2fbfb;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.8;
        border: 1px solid #f2fbfb;
        background: #f2fbfb;
        color: #00a7b7;
      }
    }
  }
}
