.header {
  max-width: 1200px;
  // margin: 0 auto;
}

.layout {
  max-width: 1280px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    max-width: 100%;
  }
  padding: 2rem 1rem;
  @media (min-width: 1024px) {
    padding: 1rem 0;
  }
}

.close_btn {
  position: absolute;
  right: 24px;
  top: 24px;
  border-radius: 8px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: white;
  border: 1px solid var(--colors-gray-light-mode-300, #d0d5dd);
  &:hover {
    background-color: #f8f8f8;
  }
}

.utility_margin {
  margin-top: -499px;
}
