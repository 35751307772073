.Dashboard_header {
  z-index: 999 !important;
  max-height: 68px;
  background-color: #fff;
  position: absolute;
  width: 100%;
  top: 0px;
  @media (min-width: 1024px) {
    margin-top: 8px;
    width: calc(100vw - 257px);
  }
  padding: 14px 24px;
  font-family: 'Poppins' !important;
  font-weight: 400;
  font-size: 16px !important;
  border-radius: 20px 20px 0 0;
  border-bottom: 1px solid #eaecf0;
  .hamburger {
    appearance: none;
    display: inline-block;
    cursor: pointer;
    font-size: 1.5rem;
    margin-right: 1rem;
    border: none;
    background-color: transparent;
    color: black;
    @media (min-width: 1024px) {
      display: none;
    }
  }
  &__DropdownListItem {
    padding: 10px 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 120px;
    span {
      color: #101828;
    }
  }
  &__settings {
    cursor: pointer;
    padding: 11px;
    margin-right: 0.5rem;
    &:hover {
      color: #00a7b7;
    }
  }
  &__avatar_container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: auto;
    @media (min-width: 1024px) {
      margin-left: 0;
    }
  }
}
.AddNewBtn {
  max-height: 40px;
  background-color: #00a7b7;
  border: none;
  button {
    background-color: #00a7b7;
    border: none;
    &:hover {
      background-color: #00a7b7;
    }
  }
}
