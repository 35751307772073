@import '/src/styles/functions/@mixin-module';
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  visibility: none;
  -webkit-appearance: none;
}
input[type='date']::-webkit-calendar-picker-indicator {
  content: url('../.../../../../public/Images/calendar.svg');
  background-color: #fff;
}
.react-datepicker {
  font-family: 'DM Sans' !important;
  font-weight: 400;
  border-color: #00a7b7 !important;
  &__header {
    background-color: #f2fbfb !important;
  }
  &__day {
    &:hover {
      background-color: #f2fbfb !important;
      color: #00a7b7 !important;
    }
    &--selected {
      background-color: #00a7b7 !important ;
    }
  }
  &__input-container {
    position: relative;
    &::after {
      content: url('../.../../../../public/Images/calendar.svg');
      background-color: #fff;
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
}
.Vocable-mpdp {
  position: relative;

  &::after {
    content: url('../.../../../../public/Images/calendar.svg');
    background-color: #fff;
    position: absolute;
    right: 12px;
    top: 14px;
    z-index: 1;
  }

  * {
    font-family: 'Poppins' !important;
    font-weight: 400;
  }
  .rmdp-wrapper {
    .rmdp-header {
      background-color: #f9f5ff !important;
      .rmdp-header-values {
        color: #7f56d9 !important;
        font-family: 'Poppins';
        font-weight: 600;
      }
      .rmdp-arrow-container {
        border-radius: 4px !important;
        align-items: center !important;
        &:hover {
          background-color: #f9f5ff !important;
          color: #7f56d9 !important;
        }
        .rmdp-arrow {
          border-color: #7f56d9 !important;
          margin: 0 !important;
        }
      }
    }
    .rmdp-panel-body {
      li {
        background-color: #7f56d9 !important ;
      }
    }
    .rmdp-day-picker {
      .rmdp-week {
        .rmdp-week-day {
          color: #7f56d9 !important;
        }
        .rmdp-day {
          span {
            &:hover {
              background-color: #f9f5ff !important;
              color: #7f56d9 !important;
            }
          }
        }
        .rmdp-day.rmdp-today {
          span {
            background-color: #f9f5ff !important;
            color: #7f56d9 !important;
          }
        }
        .rmdp-selected {
          span:not(.highlight) {
            background-color: #7f56d9 !important;
            color: #fff !important;
          }
        }
      }
    }
  }
}

input::-webkit-datetime-edit {
  display: none;
}
input[type='date']:before {
  content: attr(placeholder);
}
input[type='date'] {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.input {
  font-family: 'DM Sans' !important;
  font-weight: 400 !important;
  padding: 10px 14px !important;
  border-radius: 8px !important;
  line-height: 20px !important;
  font-size: 14px !important;
  color: #101828 !important;
  z-index: 99;
  &:focus {
    border-color: #00a7b7 !important;
    box-shadow:
      0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px #00a8b715 !important;
  }
}

.react-tagsinput {
  font-family: 'Poppins';
  font-weight: 400;
  padding: 5px;
  border-radius: 8px;

  color: #101828;
  z-index: 99;
  &:focus,
  &:focus-within,
  &:focus-visible {
    box-shadow:
      0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px #00a8b715 !important;
    border-color: #00a7b7 !important;
  }

  input {
    line-height: 24px;
    font-size: 16px;
  }
}

.react-tagsinput--focused {
  border-color: #00a7b7 !important;
  box-shadow:
    0px 1px 2px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px #f4ebff;
}
input,
textarea::placeholder {
  @include place-holder-styles;
}
input[type='password']::placeholder,
input[type='email']::placeholder,
input[type='text']::placeholder,
input[type='number']::placeholder,
.form-control::placeholder,
textarea::placeholder {
  color: var(
    --neutral-colors-Gray-neutral-400,
    var(--colors-gray-light-mode-400, #98a2b3)
  ) !important;
  font-family: 'DM Sans' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}
.input-group-input {
  border-radius: 8px 0px 0px 8px;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none !important;
}

input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.InputIcon {
  text-indent: 24px;
}
.Input_label,
.form-check-label {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.nav-tabs {
  gap: 16px;
  @media screen and (min-width: 340px) and (max-width: 768px) {
    gap: 0px;
  }
}
.documents-tabs.nav-tabs {
  gap: 0px;
  justify-content: flex-end;
  border: none;
}
.nav-tabs .nav-link {
  font-family: 'DM Sans';
  font-weight: 500;
  padding: 8px 4px;
  font-size: 14px;
  border: none;
  color: #667085 !important;
  .badge {
    background-color: #f2f4f7;
    padding: 2px 8px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #344054;
    @include text-sm-lh18;
  }
  &:hover {
    border: none;
  }
}
.nav-tabs .Articles.nav-link.active {
  border: none;
  border-bottom: 2px solid #8b6efe;
  color: #8b6efe !important;
  font-weight: 600;
  .badge {
    background-color: #f9f5ff !important;
    color: #6941c6;
  }
}

.nav-tabs .Descriptions.nav-link.active {
  border: none;
  border-bottom: 2px solid #53b1fd;
  color: #53b1fd !important;
  font-weight: 600;
  .badge {
    background-color: #f9f5ff !important;
    color: #6941c6;
  }
}

.nav-tabs .PressRelease.nav-link.active {
  border: none;
  border-bottom: 2px solid #7f56d9;
  color: #7f56d9 !important;
  font-weight: 600;
  .badge {
    background-color: #f9f5ff !important;
    color: #6941c6;
  }
}

.nav-tabs .AdCopy.nav-link.active {
  border: none;
  border-bottom: 2px solid #f670c7;
  color: #f670c7 !important;
  font-weight: 600;
  .badge {
    background-color: #f9f5ff !important;
    color: #6941c6;
  }
}

.nav-tabs .SocialMedia.nav-link.active {
  border: none;
  border-bottom: 2px solid #6172f3;
  color: #6172f3 !important;
  font-weight: 600;
  .badge {
    background-color: #f9f5ff !important;
    color: #6941c6;
  }
}

.nav-tabs .EmailCampaigns.nav-link.active {
  border: none;
  border-bottom: 2px solid #4e5ba6;
  color: #4e5ba6 !important;
  font-weight: 600;
  .badge {
    background-color: #f9f5ff !important;
    color: #6941c6;
  }
}

.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #00a7b7;
  color: #00a7b7 !important;
  font-weight: 600;
  .badge {
    background-color: #f2fbfb !important;
    color: #00a7b7;
  }
  svg {
    stroke: #00a7b7 !important;
  }
}
.nav-tabs .nav-link:focus-visible,
:focus-within,
:focus {
  outline: none;
}
.custom-health-progressbar {
  height: 4px !important;
}
.custom-health-progressbar .Correctness-bar {
  background-color: #faa7e0;
  height: 4px;
}
.custom-health-progressbar .Engagement-bar {
  background-color: #fedf89;
  height: 4px;
}
.custom-health-progressbar .Clarity-bar {
  background-color: #7cd4fd;
  height: 4px;
}
.custom-health-progressbar .Delivery-bar {
  background-color: #f7a842;
  height: 4px;
}
.custom-health-progressbar .Vocabulary-bar {
  background-color: #fedf89;
  height: 4px;
}
.custom-health-progressbar .Readability-bar {
  background-color: #82d3d0;
  height: 4px;
}
.custom-health-progressbar .Accessibility-bar {
  background-color: #7cd4fd;
  height: 4px;
}
.custom-health-progressbar .Style-bar {
  background-color: #9e77ed;
  height: 4px;
}
.custom-health-progressbar .Term-bar {
  background-color: #8098f9;
  height: 4px;
}
.custom-health-progressbar .SentenceStructure-bar {
  background-color: #7fdb82;
  height: 4px;
}
.custom-health-progressbar .Plagiarism-bar {
  background-color: #f7a842;
  height: 4px;
}
.dot-black {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #344054;
}
.Dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  &__in_progress {
    background-color: #1c8aca;
  }
  &__in_review {
    background-color: #f99806;
  }
  &__not_started {
    background-color: #344054;
  }
  &__published {
    background-color: #f5dc05;
  }
  &__approved {
    background-color: #00a7b7;
  }
}
.linkColor {
  color: #00a7b7 !important;
  font-weight: 600;
}

.file-upload-progress {
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  &-bar {
    background: #f2fbfb;
    height: 70px;
    border-radius: 8px 0px 0px 8px;
  }
}
.button {
  padding: 10px 16px;
  font-family: 'Poppins', sans-serif !important;
  @include text-md;
  &-dark {
    background-color: #000000;
    color: #fff;
    &:active {
      svg {
        path {
          stroke: #000 !important;
        }
      }
    }
  }
  &-light {
    background-color: #fff;
    color: #000;
    &:active {
      svg {
        path {
          stroke: #000 !important;
        }
      }
    }
  }
}

.Custom-Select {
  &__value-container {
    padding: 0px;
  }
  &__single-value {
    span {
      display: none;
    }
  }
  &__menu {
    width: 260px !important;
    background: #ffffff;
    // padding: 0px !important;
    // margin: 0px;
    border: 1px solid #f2f4f7;
    box-shadow:
      0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }
  &__control {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-color: #d0d5dd !important;
    height: 44px;
    min-width: 150px;
    max-width: 145px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    &:hover {
      border: none !important;
      border-color: none;
      box-shadow: none !important;
    }
  }
  &__indicator-separator {
    display: none;
  }
  &__indicator {
    padding: 0px !important;
  }

  &__indicators {
    max-height: 44px;
  }
}
.filter-Select {
  &__value-container {
    padding: 4px !important;
    &:focus,
    :focus-visible,
    :focus-within {
      box-shadow: none !important;
    }
  }
  &__single-value {
    .select-option {
      // padding: 0!important;
      margin: 0 !important;
    }
    input {
      display: none;
    }
    // svg {
    //   display: none;
    // }
  }
  &__menu {
    width: 175px !important;
    background: #ffffff;
    border: 1px solid #f2f4f7;
    box-shadow:
      0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }
  &__control {
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    outline: none !important;
    box-shadow: none !important;
    border-color: #d0d5dd !important;
    height: 44px;
    min-width: 150px;
    // max-width: 145px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    &:hover {
      border: 1px solid #d0d5dd;
      border-radius: 4px;
      box-shadow: none !important;
    }
    @media (min-width: 768px) {
      min-width: 176px;
    }
  }
  &__indicator-separator {
    display: none;
  }
  &__indicator {
    padding: 0 !important;
    margin-right: 4px !important;
  }

  &__indicators {
    max-height: 44px;
  }
}
.fullWidth-Select {
  &__value-container {
    div {
      padding: 0;
    }
    padding: 0px;
    &:focus,
    :focus-visible,
    :focus-within {
      box-shadow:
        0px 1px 2px rgba(16, 24, 40, 0.05),
        0px 0px 0px 4px #00a8b715;
    }
  }
  &__single-value {
    input {
      display: none;
    }
    svg {
      display: none;
    }
  }
  &__menu {
    width: 100% !important;
    background: #ffffff;
    border: 1px solid #e4e7ec;
    box-shadow:
      0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0px 14px;
    max-height: 500px !important;
    &-list {
      min-height: fit-content !important;
      max-height: 150px !important;
      overflow: auto !important;
    }
  }
  &__option {
    line-height: 35px !important;
  }
  &__control {
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    outline: none !important;
    box-shadow: none !important;
    border-color: #d0d5dd !important;
    height: 44px;
    min-width: 112px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    max-height: 500px !important;

    &:hover {
      border: 1px solid #d0d5dd;
      border-radius: 8px;
      box-shadow: none !important;
    }
  }
  &__indicator-separator {
    display: none;
  }

  &__indicators {
    max-height: 44px;
  }
}

.fullWidth-Select-Questionnaire {
  &__value-container {
    div {
      padding: 0;
    }
    padding: 0px;
    &:focus,
    :focus-visible,
    :focus-within {
      box-shadow:
        0px 1px 2px rgba(16, 24, 40, 0.05),
        0px 0px 0px 4px #00a8b715;
    }
  }
  &__single-value {
    input {
      display: none;
    }
    svg {
      display: none;
    }
  }
  &__menu {
    width: 100% !important;
    background: #ffffff;
    border: 1px solid #e4e7ec;
    box-shadow:
      0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0px 14px;
    max-height: 500px !important;
    &-list {
      min-height: fit-content !important;
      max-height: 150px !important;
      overflow: auto !important;
    }
    position: inherit !important;
  }

  &__value-container {
    padding: 0px 4px !important;
  }

  &__option {
    cursor: pointer;
    margin: 5px !important;
  }

  &__menu-list {
    :hover {
      background-color: #e8ecef;
    }
  }

  &__control {
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    outline: none !important;
    box-shadow: none !important;
    border-color: #d0d5dd !important;
    height: 44px;
    min-width: 112px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    max-height: 500px !important;

    &:hover {
      border: 1px solid #d0d5dd;
      border-radius: 8px;
      box-shadow: none !important;
    }
  }
  &__indicator-separator {
    display: none;
  }

  &__indicators {
    max-height: 44px;
  }
}
.optionsDropDown {
  .dropdown-menu.show {
    width: 125px !important;
  }
}
.AddNewDocument_Btn {
  .dropdown-menu.show {
    min-width: 120px;
    padding: 0px;
  }
}
.breadcrumb {
  margin: 0;
  .breadcrumb-item {
    a {
      color: #667085;
      text-decoration: none;
    }
    &.active {
      color: #101828;
    }
  }
}
.tag {
  user-select: none;
  background: #fff;
  border-radius: 16px;
  padding: 4px 12px 4px 12px;
  border: 1px solid #f2f4f7;
  color: #101828;
  cursor: pointer;
  max-width: fit-content;
}
.tag.active {
  padding-right: 24px;
  color: #101828;
  background-color: #fff !important;
  border: 1px solid #42a7b7;
  background: url('../../../public/Images/tick-sm-coloured.svg');
  background-repeat: no-repeat;
  background-position: 94% 55%;
}
.Vocable-Radio-Button {
  .form-check-input:checked {
    background: url('../../../public/Images/Check-bg.svg');
    background-color: #fff;
    border: 1px solid #00a7b7;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}
.react-tagsinput {
  &-tag {
    background-color: #f2fbfb !important;
    border: 1px solid #00a7b7 !important;
    font-family: 'DM Sans' !important;
    font-weight: 400;
    color: #00a7b7 !important;
  }
  &-remove {
    color: #000;
    text-decoration: none;
    &:hover {
      color: #b42318;
    }
  }
}
