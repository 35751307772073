.wraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: auto;
  &__lotie {
    height: 100px;
    width: 100px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__heading {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  &__description {
    color: var(
      --neutral-colors-Gray-neutral-700,
      var(--colors-gray-light-mode-700, #344054)
    );
    text-align: center;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  &__animation {
    margin-top: 24px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    gap: 12px;
    &__logo {
      height: 40px;
      width: 40px;
      border-radius: 8px;
      svg {
        border-radius: 8px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
