.card_container {
  display: flex;
  @media (min-width: 768px) {
    width: 360px;
  }
  width: 100%;
  padding: var(--spacing-xl, 16px) 0;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-lg, 12px);
  border-radius: 12px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  &__heading {
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 0 16px;
  }
  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    padding-bottom: 16px;
    width: calc(100% - 32px);
    margin: 0 auto;

    &__detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &__heading {
        color: var(
          --neutral-colors-Gray-neutral-900,
          var(--colors-gray-light-mode-900, #101828)
        );
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      &__amount {
        color: var(
          --neutral-colors-Gray-neutral-900,
          var(--colors-gray-light-mode-900, #101828)
        );
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 16px;
    &__heading {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &__amount {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );

      font-family: 'Poppins';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }
  }
  &__coupan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-2xl, 20px);
    gap: var(--spacing-xl, 16px);
    align-self: stretch;
    background: var(--colors-gray-light-mode-50, #f9fafb);
    &__heading {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    &__btn {
      color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);

      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      border: none;
      background-color: transparent;
    }
  }
  &__pay_btn {
    width: 100%;
    padding: 0 16px;
    button {
      width: 100%;
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 4px;
      border: 1px solid var(--Brand-Colors-Primary-Oxford-900, #1d2939);
      background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(
        --neutral-colors-black-white-white,
        var(--Colors-Base-white, #fff)
      );
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  &__footer {
    display: flex;
    align-items: start;
    gap: var(--spacing-md, 8px);
    align-self: stretch;
    width: 100%;
    padding: 0 16px;
    div {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      display: flex;
      justify-content: start;
      align-items: start;
      svg {
        height: 16px;
        width: 16px;
      }
    }
    span {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.footer {
  display: flex;
  align-items: start;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  width: 100%;
  @media (min-width: 768px) {
    width: 360px;
  }
  margin: 16px 0;
  &__text {
    color: var(
      --neutral-colors-Gray-neutral-500,
      var(--colors-gray-light-mode-500, #667085)
    );
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    &__underline {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
    }
  }
  div {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    display: flex;
    justify-content: start;
    align-items: start;
    svg {
      height: 16px;
      width: 16px;
    }
  }
}
.textAppiedCoupan {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #101828;
  font-family: 'DM Sans';
}
.coupanValue {
  margin-right: 5px;
  background: #f8f1e4;
  padding: 7px 12px;
  border-radius: 20px;
}
.black_btn {
  background-color: #1d2939;
  border: 1px solid #1d2939;

  &:disabled {
    background: #d0d5dd;
    color: #fff;
    border-color: #d0d5dd;
  }
}
