.container {
  display: flex;
  min-width: 191px;
  padding: var(--spacing-sm, 6px) var(--spacing-2xl, 20px)
    var(--spacing-sm, 6px) var(--spacing-sm, 6px);
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: 999px;
  &__text {
    display: flex;
    flex-direction: column;
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    min-width: max-content;
  }
}

.ai_powered {
  border: 1px solid #fed472;
  background: #fff1d1;
}
.ai_brief {
  border: 1px solid #a6c9ff;
  background: #e3eeff;
}
.seo_keyword {
  border: 1px solid #b5d96c;
  background: #f5ffe2;
}
.content {
  border: 1px solid #e5c6f8;
  background: #f8ecff;
}
.multi_model {
  border: 1px solid #b2c6fe;
  background: #e6edff;
}
.brand {
  border: 1px solid #fed472;
  background: #fff1cf;
}
.ai_content {
  border: 1px solid #b5d96c;
  background: #f3ffdb;
}
