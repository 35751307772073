.container {
  display: flex;
  flex-direction: column;
  padding: 8px var(--spacing-2xl, 20px) 8px 8px;
  align-items: center;
  gap: 8px;
  background: var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
  @media (min-width: 720px) {
    flex-direction: row;
  }
  &__left {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    &__heading {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
    &__body {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 720px) {
        flex-direction: row;
      }
    }
    &__desc {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  &__right {
    & > button {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      border: none;
      background-color: transparent;
    }
  }
}
