.heading {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  text-align: center;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-top: 2rem;
  padding: 0 1.5rem;
}
.descriptions {
  color: var(
    --neutral-colors-Gray-neutral-600,
    var(--colors-gray-light-mode-600, #475467)
  );
  text-align: center;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 664px;
  margin: 8px auto 32px;
  padding: 0 1.5rem;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin: 24px auto;
}

.loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 141px);
  position: relative;
}

.loaging_size {
  width: 637.463px;
  height: 655px;
}

.button_group {
  position: absolute;
  bottom: 10%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.main_page {
  background-image: url('../../assets/svg/loading.svg');
  background-repeat: no-repeat;
  min-height: calc(100vh - 85px);
  background-position: center;
}

.yellowBorder {
  border: 1px solid #f79009;
}

.blueBorder {
  border: 1px solid #2e90fa;
}
