.error_message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  ul {
    list-style: disc;
    margin-left: 20px;
    li {
      margin-bottom: 5px;
    }
  }
}
