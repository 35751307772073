@import '/src/styles/base/typography';
@import '/src/styles/functions/@mixin-module';
@import '/src/styles/variables/colors';

.Container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 18px;
  border-radius: 8px;
  padding: 24px 10px;
  background-color: #f9fafb;
  color: font_clr('d-grey');

  &_Title {
    @include text-18-lh28;
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  &_Title_2 {
    @include text-18-lh28;
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  &_Subtitle {
    @include Heading-md;
    font-weight: 400;
    font-family: 'DM Sans';
    line-height: 20px;
  }

  &_Form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_TwoColumn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      & > * {
        width: 100%;
        min-width: 300px;
      }
    }
    [class$='form-label'] {
      @include Heading-md;
      color: font_clr('d-grey');
      font-weight: 500;
    }
    &_Dropdown {
      [class$='-control'] {
        border-radius: 8px;
        padding: 0 !important;
      }
      [class$='-ValueContainer'] {
        padding: 6px 10px;
      }
    }
  }

  &_KeywordsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 12px;
    &_TagsContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &__item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        span {
          display: block;
          min-width: 100%;
          padding: 12px;
        }
      }
    }
    &_KeywordsSpinner {
      border: none;
    }
    &_ResearchSpinner {
      height: 198px;
    }
    &_ResearchContainer {
      display: flex;
      background-color: #fff;
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
      width: 100%;
      border-radius: 8px;

      @include text-md;
      font-family: 'DM Sans';
      color: font_clr('d-grey-700');

      :global(.tab-content) {
        width: 100%;
      }
      :global(.nav.nav-tabs) {
        border-bottom: none;
        gap: 0.5rem;
      }
      :global(.keyword-result-for) {
        font-weight: 500;
      }
      :global(.nav-link) {
        font-family: 'Poppins';
        padding: 4px 0;
      }
      :global(.nav-link.active) {
        background-color: transparent;
      }
    }
  }

  &_HeadlinesContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    max-height: 55vh;

    &_HeadlineCard {
      display: flex;
      padding: 12px 20px;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      border-radius: 8px;
      background: white;
      &:hover {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
      &_Title {
        @include text-md-lh20;
        color: font_clr('d-grey');
        width: 550px;
        &:hover {
          cursor: pointer;
        }
      }
      &_RadioButton {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 40px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid #d0d5dd;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        cursor: pointer;
        &:checked {
          background-color: #00a7b7;
          box-shadow: inset 0 0 0 4px #fff;
          height: 20px;
          width: 40px;
        }
      }
    }
  }

  &_NextButton {
    padding: 8px 14px;
    gap: 8px;
    width: 132px;
  }

  @media (min-width: 768px) {
    padding: 24px 20px;
    &_HeadlinesContainer {
      &_KeywordsContainer {
        margin: 0;
      }
      &_HeadlineCard {
        &_RadioButton {
          width: 20px;
          &:checked {
            width: 20px;
          }
        }
      }
    }
    &_Form {
      &_TwoColumn {
        flex-direction: row;
      }
    }
    &_KeywordsContainer {
      &_TagsContainer {
        &__item {
          flex-direction: row;
          span {
            min-width: fit-content;
            padding: 8px;
          }
        }
      }
    }
  }
}
