.post {
  padding-top: var(--spacing-xl, 16px);
  border-radius: 8px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--neutral-colors-black-white-white, #fff);
  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 16px;
    padding-right: 16px;
    &__detail {
      display: flex;
      flex-direction: column;
      gap: 6px;
      &__title {
        color: var(
          --neutral-colors-Gray-neutral-900,
          var(--colors-gray-light-mode-900, #101828)
        );
        font-family: 'DM Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
      &__title {
        color: var(
          --neutral-colors-Gray-neutral-900,
          var(--colors-gray-light-mode-900, #101828)
        );
        font-family: 'DM Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
      &__desc {
        display: flex;
        align-items: center;
        gap: 6px;
        button {
          color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);

          font-family: 'DM Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          border: none;
          background: none;
          display: flex;
          align-items: center;
          gap: 6px;
        }
        span {
          color: var(
            --neutral-colors-Gray-neutral-500,
            var(--colors-gray-light-mode-500, #667085)
          );
          font-family: 'DM Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }
  &__input {
    padding: 16px 20px;
    & > input {
      border: none;

      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      width: 100%;
      &::placeholder {
        color: var(
          --neutral-colors-Gray-neutral-400,
          var(--colors-gray-light-mode-400, #98a2b3)
        );
      }
    }
  }
  &__ai_writing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 16px;
    &__iocn {
      border: none;
      background: none;
    }
    &__button {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      border: none;
      background: none;
    }
  }
  &__image {
    height: 245px;
    width: 437px;
    position: relative;
    &:hover {
      .post__image__delete {
        border: none;
        background: white;
        position: absolute;
        padding: 5px;
        border-radius: 4px;
        top: 5%;
        right: 3%;
        visibility: visible;
      }
    }

    & > img {
      height: 100%;
    }
    &__delete {
      visibility: hidden;
    }
  }
  &__bottom_link {
    display: flex;
    padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
    align-items: center;
    gap: var(--spacing-lg, 12px);
    align-self: stretch;
    background: var(--colors-gray-light-mode-100, #f2f4f7);
    &__button {
      border: none;
      background: none;
    }
    &__desc {
      display: flex;
      flex-direction: column;
      &__title {
        color: var(
          --neutral-colors-Gray-neutral-900,
          var(--colors-gray-light-mode-900, #101828)
        );

        font-family: 'Poppins';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
      }
      &__website {
        color: var(
          --neutral-colors-Gray-neutral-600,
          var(--colors-gray-light-mode-600, #475467)
        );
        font-family: 'DM Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
}

.icon_image {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  object-fit: cover;
}
