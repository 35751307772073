ol > li {
  counter-increment: item;
}

ol > li:first-child {
  counter-reset: item;
}

ol ol > li {
  display: block;
}

ol ol > li:before {
  content: counters(item, '.') '. ';
  margin-left: -20px;
}
