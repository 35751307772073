.centered_container {
  height: calc(100vh - 70px) !important;
  margin: auto auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.OuterContainer {
  display: flex;
  width: 100%;
  min-height: 100vh;

  &__left {
    width: 50%;
    padding: 20px 32px;
    @media (max-width: 900px) {
      width: 100%;
    }
    &__logo {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
      margin-bottom: 0.5rem;
    }
    &__form_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      flex: 1 0 0;
      min-width: 400px;
      max-width: 400px;
      width: 100%;
      height: calc(100vh - 96px);
      overflow-y: auto;
      margin: auto auto;
      margin-top: 26px;

      @media (max-width: 470px) {
        min-width: 100%;
        max-width: 100%;
      }
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      &__welcome {
        width: 100%;
        p {
          color: var(
            --neutral-colors-Gray-neutral-900,
            var(--colors-gray-light-mode-900, #101828)
          );
          font-family: 'Poppins';
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px; /* 126.667% */
        }
        span {
          color: var(
            --neutral-colors-Gray-neutral-600,
            var(--colors-gray-light-mode-600, #475467)
          );
          font-family: 'DM Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
  &__right {
    width: 50%;
    padding: 8px;
    height: 100vh;
    @media (max-width: 900px) {
      display: none;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }
}

.formik_form {
  width: 100%;
}

.forgot_pass {
  display: flex;
  justify-content: end;
  align-items: center;
  a {
    color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
    text-align: right;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
  }
}

.or_container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  &__line {
    width: 178px;
    height: 1px;
    background: var(--colors-gray-light-mode-200, #eaecf0);
  }
  &__or {
    display: flex;
    padding: var(--spacing-sm, 6px) var(--spacing-lg, 12px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);
    color: var(
      --neutral-colors-Gray-neutral-500,
      var(--colors-gray-light-mode-500, #667085)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.logos_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  button {
    border: none;
    background: none;
  }
}

.trial_account {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  &__dont_have {
    color: var(
      --neutral-colors-Gray-neutral-500,
      var(--colors-gray-light-mode-500, #667085)
    );
    text-align: center;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  &__have {
    color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
    text-align: right;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;
  }
}

.password_field {
  margin-top: 16px;
  margin-bottom: 8px;
}

.btn_container {
  margin-top: 20px;
  margin-bottom: 20px;
}

// Sign Up page
.input_label {
  color: var(
    --neutral-colors-Gray-neutral-700,
    var(--colors-gray-light-mode-700, #344054)
  );
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 6px;
}

.parallel_field {
  display: flex;
  gap: 16px;
  margin-bottom: 14px;
}

.OuterContainer__right_signup {
  width: 50%;
  margin: 8px;
  height: calc(100vh - 16px);
  border-radius: 20px;
  background: var(--Brand-Colors-Secondary-SandStone-900, #f8f1e4);
  box-shadow: 12px -12px 24px 0px rgba(255, 213, 134, 0.15) inset;
  display: flex;
  flex-direction: column;
  padding: 10% 0 40px;
  @media (max-width: 900px) {
    display: none;
  }
  &__avatars {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 94px 140px 100px;
    &__active_users {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  &__text {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 133.333% */
    letter-spacing: -0.6px;
    padding: 0 140px 20px;
    min-height: 376px;
    display: flex;
    align-items: end;
    @media (max-width: 1440px) {
      padding: 0 95px 20px;
    }
  }
  &__desc {
    color: var(--Brand-Colors-Secondary-Periwinkle-900, #282b33);

    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0 140px;
    @media (max-width: 1440px) {
      padding: 0 95px;
    }
  }
  &__user {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 32px 140px;
    @media (max-width: 1440px) {
      padding: 32px 95px;
    }
    &__img {
      display: flex;
      width: 56px;
      height: 56px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
        width: 100%;
        border-radius: 100%;
      }
      &__name_container {
        display: flex;
        flex-direction: column;
        gap: 6px;
        &__name {
          color: var(
            --neutral-colors-Gray-neutral-900,
            var(--colors-gray-light-mode-900, #101828)
          );
          font-family: 'DM Sans';
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
      }
    }
  }
  &__ticker_container {
    margin-top: auto;
    &__heading {
      color: var(
        --neutral-colors-Gray-neutral-700,
        var(--colors-gray-light-mode-700, #344054)
      );
      font-family: 'Poppins';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      padding: 0 40px;
      margin-bottom: 16px;
    }
  }
}

.OuterContainer__right_signup__user__name_container__name {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'DM Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}

.email_send_box {
  margin-bottom: 20px;
  display: flex;
  padding: var(--spacing-xl, 16px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xl, 16px);
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
  background: var(--Colors-Base-white, #fff);
  &__inner_box {
    display: flex;
    padding: var(--spacing-md, 8px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-lg, 12px);
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
    background: var(--Brand-Colors-Secondary-Sunset-100, #fff9f0);
    &__top {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      font-family: 'Poppins';
    }
    &__bottom {
      display: flex;
      padding: var(--spacing-xl, 16px) var(--spacing-3xl, 24px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-3xl, 24px);
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
      background: var(--Colors-Base-white, #fff);
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.google_btn {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd !important;
  background: var(--neutral-colors-black-white-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  &:hover {
    background-color: #fff;
    border: 1px solid #d0d5dd;
    color: #344054;
  }
}
