.outerContainer__card {
  display: flex;
  height: 80px;
  padding: 0px var(--spacing-3xl, 24px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  margin-top: 8px;
  flex-direction: row;

  &__left {
    p {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      text-align: center;
      font-family: 'Poppins';
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-right: 4rem;
    &__switchContainer {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    &__switch {
      width: 100%;
      & > input {
        width: 2.3em !important;
      }
    }
    span {
      color: var(
        --neutral-colors-Gray-neutral-700,
        var(--colors-gray-light-mode-700, #344054)
      );
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    &__badge {
      display: flex;
      height: 28px;
      padding: 0px var(--spacing-md, 8px);
      justify-content: center;
      align-items: center;
      gap: var(--spacing-xs, 4px);
      border-radius: 999px;
      background: var(--Colors-Warning-100, #fef0c7);
      color: var(
        --Semantic-Colors-Yellow-600,
        var(--Colors-Warning-600, #dc6803)
      );
      text-align: center;
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    &__right {
      flex-direction: column-reverse;
      padding-top: 1rem;
      padding-right: 0;
    }
  }
}
