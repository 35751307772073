.card {
  display: flex;
  padding: var(--spacing-lg, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xl, 16px);
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--neutral-colors-black-white-white, #fff);
  &:hover {
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);
    box-shadow: 0px 8px 16px -4px rgba(16, 24, 40, 0.12);
  }
  &__header {
    display: flex;
    align-items: center;
    gap: 12px;
    &__heading {
      color: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  &__button {
    width: 100%;
    button {
      width: 100%;
    }
  }
}
