@import '../../styles/functions/@mixin-module';
.container {
  background-color: #00a7b7;
  margin-top: 550px;
  border-radius: 24px;
  min-height: 552px;
  position: relative;
  display: flex;
  align-items: center;
  &__textSection {
    padding: 92px 0 92px 92px;
    @media (max-width: 1200px) {
      width: 100% !important;
      padding: 92px;
    }
    @media (max-width: 625px) {
      width: 100% !important;
      padding: 40px;
    }
    &__title {
      font-size: 16px;
      font-family: 'DM Sans';
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #004147;
      padding-bottom: 12px;
    }
    &__heading {
      font-size: 64px;
      font-family: 'Poppins';
      font-weight: 600;
      line-height: 71.68px;
      letter-spacing: -4%;
      color: #fff;
      @media (max-width: 625px) {
        font-size: 40px;
        line-height: 60.68px;
      }
      span {
        position: relative;
        display: inline-block;
        z-index: 0;
        &::after {
          content: url('../../../public/Images/nowFree.svg');
          position: absolute;
          left: 4%;
          @include background-position;
          @media (max-width: 625px) {
            content: '';
          }
        }
      }
    }
    &__button {
      margin-top: 64px;
      border: 1px solid #ffffff;
      padding: 16px 32px;
      border-radius: 100px;
      font-size: 14px;
      font-family: 'Poppins';
      line-height: 20.3px;
      font-weight: 700;
      color: #ffffff;
      background-color: transparent;
      &:hover {
        border: 1px solid #ffffff;
        color: #ffffff;
        background-color: transparent;
      }
    }
  }
  &__image_bg {
    height: 400px;
    position: absolute;
    top: 0;
    right: 0;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  &__image_section {
    background-image: url('../../../public/Images/getAccess.svg');
    background-position: end;
    position: absolute;
    right: 0px;
    height: 452px;
    bottom: 0px;
    background-repeat: no-repeat;
    background-position-x: right;
    @media (max-width: 1200px) {
      display: none;
    }
  }
}
