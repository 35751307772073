@import '../../../../styles/variables/colors';
@import '../../../../styles/functions/@mixin-module';
@import '../../../../styles/base/fonts';
@import '../../../../styles/base/typography';

.documentHeathSection {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  gap: fontsize('s16');
  z-index: 99;
  position: relative;
  background: font_clr('white');
}

.section {
  &__Heading {
    @include Heading-sm;
    color: font_clr(d-grey-600);
    position: sticky;
    top: 0;
    background-color: font_clr('white');
    z-index: 99;
  }
}

.OverallScore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  &__info {
    * {
      line-height: fontsize('s20');
      color: font_clr(d-grey-600);
    }
    &_heading {
      font-family: 'Inter';
      font-weight: 600;
      font-size: fontsize('s16');
    }
    &_value {
      font-family: 'Inter';
      font-weight: 400;
      font-size: fontsize('s12');
    }
  }
  &__ProgressBar {
    height: 40px;
    width: 40px;
  }
}

.Correction {
  background: font_clr('white');
  padding: 12px;
  border: 1px solid #f2f4f7;
  box-shadow:
    0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 4px;
  cursor: pointer;
  margin-top: 12px;
  &__textWrapper {
    gap: 8px;
    img {
      width: 4px;
    }
  }
  &__text {
    @include text-sm;
    color: #2c3e50;
  }
  &__category {
    @include text-sm;
    color: #98a2b3;
  }
  &__ExpandedView {
    padding: 16px 0px;
    border-bottom: 1px solid font_clr('l-grey');
    flex-wrap: wrap;
    gap: 20px;
  }
  &__CorrectionText {
    @include text-lg;
    color: #2c3e50;
    text-decoration-line: line-through;
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;
  }
  &__CorrectedContent {
    @include text-md;
    cursor: pointer;
    color: font_clr('white');
    padding: 6px 12px;
    background: #444ce7;
    border-radius: 4px;
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;
  }
  &__footer {
    &_content {
      cursor: pointer;
      gap: 10px;
      user-select: none;
    }
    span {
      @include text-md;
      color: font_clr('light-grey');
    }
    padding: 13px 8px;
  }
}

.inCorrectSpelling {
  @include dot;
  background-color: #ee46bc;
}

.sentenceStructure {
  @include dot;
  background-color: #32d583;
}

.grammer {
  @include dot;
  background-color: #fec84b;
}

.HealthParameter {
  display: flex;
  flex-direction: column;
  gap: 6px;
  &__accrodoan_heading_p {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }
  &__great {
    display: inline-flex;
    max-width: fit-content;
    height: 24px;
    padding: 0px var(--spacing-md, 8px);
    align-items: center;
    gap: 8px;
    border-radius: 9999px;
    background: var(--Colors-Success-100, #dcfae6);
    color: var(
      --neutral-colors-Gray-neutral-600,
      var(--colors-gray-light-mode-600, #475467)
    );
    font-family: 'DM Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  &__poor {
    display: flex;
    max-width: fit-content;
    height: 24px;
    padding: 0px var(--spacing-md, 8px);
    align-items: center;
    gap: 8px;
    border-radius: 9999px;
    background: var(--Semantic-Colors-Red-100, #fee4e2);
    color: var(
      --neutral-colors-Gray-neutral-600,
      var(--colors-gray-light-mode-600, #475467)
    );
    font-family: 'DM Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
  &__average {
    display: flex;
    max-width: fit-content;
    height: 24px;
    padding: 0px var(--spacing-md, 8px);
    align-items: center;
    gap: 8px;
    border-radius: 9999px;
    background: var(--Colors-Warning-100, #fef0c7);
    color: var(
      --neutral-colors-Gray-neutral-600,
      var(--colors-gray-light-mode-600, #475467)
    );
    font-family: 'DM Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
  &__count {
    color: var(
      --neutral-colors-Gray-neutral-600,
      var(--colors-gray-light-mode-600, #475467)
    );
    font-family: 'DM Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.load {
  width: 22px;
  height: 22px;
  margin: 3px 3px 3px 0;
  border: solid 3px #00a7b7;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.Suggestions {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  margin-top: 6px;

  ul {
    margin-left: 1rem;

    li {
      list-style: disc;
      margin-bottom: 0.1rem;
      color: var(
        --neutral-colors-Gray-neutral-800,
        var(--colors-gray-light-mode-800, #182230)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
.Suggestions__great {
  background-color: #ecfdf3;
}
.Suggestions__average {
  background-color: #fffaeb;
}
.Suggestions__poor {
  background-color: #fef3f2;
}

// New code
.menuBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  &__heading {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  &__refresh {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    svg {
      height: 16px;
      width: 16px;
    }
    span {
      color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.ProgressBar_design {
  height: 8px;
  background-color: #eaecf0 !important;
  & > div {
    background-color: #00a7b7 !important;
  }
}

.roteIcon {
  transform: rotate(180deg);
}

// overall scroll container

.overallScore_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__heading {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }
    &__score {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }
  }
}

// Accrodian
.accordian_secton {
  border: none !important;
  padding: 0 24px;
  &__item {
    border: none !important;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &__left {
        display: flex;
        align-items: center;
        gap: 4px;
        span {
          color: var(
            --neutral-colors-Gray-neutral-900,
            var(--colors-gray-light-mode-900, #101828)
          );
          font-family: 'Poppins';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
    &__body {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__heading {
          color: var(
            --neutral-colors-Gray-neutral-600,
            var(--colors-gray-light-mode-600, #475467)
          );
          font-family: 'DM Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
        &__desc {
          color: var(
            --neutral-colors-Gray-neutral-900,
            var(--colors-gray-light-mode-900, #101828)
          );
          font-family: 'Poppins';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }
      }
    }
    button {
      padding: 0 !important;
      border: none !important;
      background: none !important;
      &::after {
        display: none !important;
      }
    }
  }
}

// progress Catogoris
.progress__great > div {
  background-color: #17b26a !important;
}

.progress__average > div {
  background-color: #f79009 !important;
}

.progress__poor > div {
  background-color: #f04438 !important;
}

.ContentPlanner {
  display: flex;
  flex-direction: column;
  gap: 32px;
  &_Toast {
    display: none;
  }
  &_Heading {
    color: gray(900);
  }

  &__Wrapper {
    display: flex;
    flex-direction: column;

    position: relative;

    &_InputSection {
      position: absolute;
      top: -12px;
      right: 0;

      svg {
        stroke: gray(500);
        filter: brightness(100%);
      }

      &_Filters {
        display: flex;
        align-items: center;
      }

      &_DropDown {
        width: 600px;
        padding: 12px;
        animation: OpacityBreak 0.5s ease-in-out;
        @keyframes OpacityBreak {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }

    &_Navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      height: 60px;
      padding: 0 24px;
      border-bottom: 1px solid var(--Neutral-100, #e2e8f0);
      background: var(--Colors-Base-white, #fff);

      &__left {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &__right {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &_Filter {
        font-weight: 700;
        font-size: 14px;
        color: #182230;
        cursor: pointer;
        font-family: 'DM Sans';
        padding: 8px !important;

        &:hover {
          color: #00a7b7;
          svg {
            color: #00a7b7;
          }
        }
      }

      &_Icon {
        a {
          padding: 8px;
        }

        a[aria-selected='true'] {
          stroke: #00a7b7;
          color: #00a7b7 !important;
          border-bottom: 1px solid #00a7b7;
        }
        &__Item {
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(
            --neutral-colors-Gray-neutral-700,
            var(--colors-gray-light-mode-700, #344054)
          );
          text-align: center;
          font-family: 'DM Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          border-bottom: 1px solid transparent;
          height: 60px;
          min-width: 85px;
          justify-content: center;
          &:hover {
            border-bottom: 1px solid #00a7b7;
            color: #00a7b7 !important;
          }
        }
      }
    }
  }
}

.coming_soon {
  padding: 12px 24px;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 600;
}

.btns__dots {
  border: none;
  background: none;
}
