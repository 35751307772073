.Container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.5rem;
  max-height: 65vh;
  overflow-y: scroll;
}

.DocumentCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 1.5rem 1rem;
  cursor: pointer;

  transition: all 0.1s ease;

  &[data-loading='true'] {
    cursor: not-allowed;
    background-color: #e5e7eb;
    border-color: #d0d5dd;
  }

  &_Title {
    color: #101828;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-top: 1.5rem;
  }

  &_Description {
    color: #667085;
    font-size: 12px;
    line-height: 18px;
  }
}

.DocumentCard:hover {
  border-color: #00a7b7;
  transform: scale(1.05);

  &[data-loading='true'] {
    transform: scale(1);
    border-color: #d0d5dd;
  }
}

@media (min-width: 768px) {
  .Container {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
  }
}
