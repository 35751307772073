@keyframes ticker-left {
  0% {
    transform: translateX(0);
  }
  100% {
    /* Adjust to the total width of all logos combined */
    transform: translateX(-60rem);
  }
}
@keyframes ticker-right {
  0% {
    transform: translateX(-60rem);
  }
  100% {
    transform: translateX(0);
  }
}
.leftToRight {
  animation: ticker-left 10s linear infinite;
}

.rightToLeft {
  animation: ticker-right 10s linear infinite;
}

.Fog {
  position: relative;
  width: 100%;
  overflow-x: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      90deg,
      #fff 15%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 60%,
      #fff 85%
    );
  }

  &__imgTicker {
    position: relative;
    z-index: 0;
    animation: ticker-left 10s linear infinite;
  }
  &__imgTicker_2 {
    position: relative;
    z-index: 0;
    animation: ticker-right 10s linear infinite;
  }

  &_Channels {
    min-width: 184px;
    height: 84px;
    display: inline-block;
    border-radius: 8px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.section_heading {
  position: relative;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1.2px;

  @media (min-width: 1200px) {
    font-size: 60px !important;
    line-height: 72px;
  }
  @media (max-width: 625px) {
    font-size: 32px;
    line-height: 40px !important;
  }
  &__publish {
    &::before {
      content: url('../../../assets/landingpage/Vector.svg');
      position: absolute;
      top: -23px;
      left: -34px;
      @media (max-width: 625px) {
        top: -32px;
        left: -85px;
        transform: scale(0.5);
      }
    }
  }
}

.description {
  color: var(
    --neutral-colors-Gray-neutral-700,
    var(--colors-gray-light-mode-700, #344054)
  );
  text-align: center;
  font-family: 'DM Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.get_started_btn {
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
  color: var(--Colors-Text-text-white, #fff);
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border: none;
}

.section_container {
  padding: 80px 24px;
  @media (max-width: 625px) {
    padding: 32px 24px;
  }
}
