.card_wrapper {
  border-radius: 8px;
  padding: 8px;
  background: linear-gradient(180deg, #f8c864 0%, #ffe7b3 100%);
  margin: 16px 0 0;
  &__profile {
    display: flex;
    gap: 12px;
    &__picture {
      height: 32px;
      width: 32px;
      border-radius: 8px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      & > img {
        border-radius: 4px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &__name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      & > p {
        color: var(
          --neutral-colors-Gray-neutral-900,
          var(--colors-gray-light-mode-900, #101828)
        );
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin: 0;
        padding: 0;
      }
      & > span {
        color: var(
          --neutral-colors-Gray-neutral-500,
          var(--colors-gray-light-mode-500, #667085)
        );
        font-family: 'DM Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  &__divider {
    background-color: #fff;
    height: 1px;
    margin: 12px 0;
    opacity: 0.3;
  }
  &__trial {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'DM Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    span {
      margin-left: 4px;
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );

      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }
  &__manage_btn {
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-mode-900, #101828);
    background: var(--colors-gray-light-mode-900, #101828);

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-top: 12px;
    &__link {
      color: var(
        --neutral-colors-black-white-white,
        var(--Colors-Base-white, #fff)
      );
      padding: 8px 14px;
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-decoration: none;
      cursor: pointer;
      width: 100%;
      text-align: center;
    }
  }
}
