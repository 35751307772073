.DocumentView {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
  grid-template-columns: repeat(1, 1fr);
  padding: 0 24px 16px;
  @media (min-width: 1660px) {
    grid-template-columns: repeat(5, 1fr);
  }

  animation: OpacityBreak 0.7s ease-in-out;
  @keyframes OpacityBreak {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@media (min-width: 768px) {
  .DocumentView {
    grid-template-columns: repeat(4, 1fr);
  }
}
