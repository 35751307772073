.Drawer {
  &_Form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_InputWrapper {
      border-radius: 8px;
      background: #f9fafb;
      display: flex;
      padding: 24px 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      &_Input {
        width: 100%;
      }

      &_Dates {
        display: flex;
        flex-direction: column;
        gap: 24px;
        @media (min-width: 768px) {
          flex-direction: row;
        }
      }
    }
  }
}

.tabs_container {
  display: flex;
  margin: 16px 0;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background: var(--colors-gray-light-mode-50, #f9fafb);
  &__header {
    display: flex;
    flex-direction: column;
    gap: 6px;
    &__heading {
      color: #000;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
    &__description {
      color: var(
        --neutral-colors-Gray-neutral-500,
        var(--colors-gray-light-mode-500, #667085)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }
}

.form4_wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 32px;
  &__tags_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__label {
      color: var(
        --neutral-colors-Gray-neutral-800,
        var(--colors-gray-light-mode-800, #182230)
      );
      font-family: 'DM Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &__outer {
      display: flex;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;

      &__tag {
        display: flex;
        padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
        gap: 8px;
        align-items: center;
        border-radius: 8px !important;
        border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
        background: var(--neutral-colors-black-white-white, #fff);
      }
    }
  }
}
