.TilesContainer {
  margin-top: 32px;
}

.TileGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding-bottom: 10px;
}

.EmptyGrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  &_Text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
}

@media (max-width: 500px) {
  .TileGrid {
    grid-template-columns: 1fr;
  }
}

// .Dashboard__compaigns {
//   padding: 20px 24px;
//   display: grid;
//   grid-template-columns: repeat(5, 1fr);
//   gap: 16px;
// }

.Dashboard__compaigns {
  padding: 20px 24px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  @media (min-width: 521px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
  }
}
