.Modal {
  max-width: 100%;
  &__Heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #2c3e50;
  }
  &__Body {
    gap: 32px;
  }
}
.dividerSection {
  gap: 10px;
  span {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }
  &__line {
    background: #e4e7ec;
    width: 50%;
  }
}
.DragDropArea {
  background: #ffffff;
  border: 1px dashed #e4e7ec;
  border-radius: 8px;
  height: 126px;
  position: relative;
  padding: 16px 0px;
  img {
    width: 44px;
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
  }
  &__fileMsg {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    &_highlight {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #00a7b7;
    }
    &_format {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #667085;
    }
  }
}
.file_UploadStatusWrapper {
  height: 72px;

  position: relative;
  .progress-bar {
    background-color: #f9f5ff !important;
  }
}
.CrossBtn_ProgressBar {
  cursor: pointer;
}
.ProgressBarLabel {
  position: absolute;
  left: 16px;
  right: 32px;
  top: 0;
  bottom: 0;
  &__fileSection {
    gap: 14px;
  }
  &__filename {
    color: #344054;
  }
}

@media (min-width: 768px) {
  .Modal {
    max-width: 600px;
  }
}
