.Banner {
  display: flex;
  padding: var(--spacing-lg, 12px) var(--spacing-4xl, 32px);
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--Brand-Colors-Secondary-Sunset-500, #fddcab);
  color: var(--Brand-Colors-Secondary-Sunset-900, #553402);
  text-align: center;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  @media (max-width: 625px) {
    font-weight: 500;
    padding: 12px;
  }
}

.banner_header {
  color: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
  text-align: center;
  font-family: 'Poppins';
  // desktop
  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 60px !important;
  }
  font-size: 32px;
  line-height: 40px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;
}

.baner_description {
  color: var(
    --neutral-colors-Gray-neutral-800,
    var(--colors-gray-light-mode-800, #182230)
  );
  text-align: center;
  font-family: 'DM Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.watch_demo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  span {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-decoration-line: underline;
    text-transform: capitalize;
  }
  &__play_btn {
    rotate: 180deg;
  }
  &__get_started_btn {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: var(--Colors-Text-text-white, #fff);
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    border-radius: 8px;
    background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
    border: none;
  }
}

.banner_ticker_video {
  display: flex;
  flex-direction: column;
}

.mobile_watch_started {
  @media (max-width: 625px) {
    flex-direction: column-reverse !important;
  }
}

.banner_video {
  position: relative;
  height: 572px;
  width: 100%;
  @media (max-width: 625px) {
    height: 100%;
    width: 100%;
  }
}

.Video {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 20px;
  border: 1px solid #fddcab;
  box-shadow: 0px 6px 8px 0px rgba(222, 222, 222, 0.5);
}

.video_controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  visibility: hidden;
}
.visible {
  visibility: visible !important;
}
.video_controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  visibility: hidden;
}

.play_button,
.pause_button {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play_button::before,
.pause_button::before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
}

.play_button::before {
  border-left: 20px solid white;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.pause_button::before {
  border-left: 8px solid white;
  border-right: 8px solid white;
  height: 20px;
}

.banner_video:hover .video_controls {
  visibility: visible;
}

.banner_video.paused .video_controls {
  visibility: visible;
}

//
.paused video {
  background: url('../../../assets/landingpage/poster.jpg') center center
    no-repeat;
  background-size: cover;
}
