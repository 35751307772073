.ListWrapper {
  background: #f2f4f7;
  border-radius: 8px;
  min-width: 100%;
  animation: slideDown 0.5s ease-in-out;
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  &__Headline {
    padding: 16px;
    p {
      color: #101828;
    }
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.25rem;
    }
    h3 {
      font-size: 1rem;
    }
  }
  &__Actions {
    gap: 24px;
    padding: 12px 24px;
    border-top: 1px solid #e4e7ec;
    row-gap: 10px;
    div {
      min-width: 92px;
      cursor: pointer;
      user-select: none;
    }
    span {
      color: #344054;
    }
  }
}
