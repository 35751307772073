@keyframes ticker-left {
  0% {
    transform: translateX(0);
  }
  100% {
    /* Adjust to the total width of all logos combined */
    transform: translateX(-60rem);
  }
}

@keyframes ticker-right {
  0% {
    transform: translateX(-60rem);
  }
  100% {
    transform: translateX(0);
  }
}

.overflowHidden {
  overflow: hidden;
  width: 100%;
}

.imgTicker {
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;
  gap: 8px;
}

.tickerlogo {
  flex: none;
  align-self: flex-start;
  max-width: 100%;
  height: auto;
}

.leftToRight {
  animation: ticker-left 10s linear infinite;
}

.rightToLeft {
  animation: ticker-right 10s linear infinite;
}
