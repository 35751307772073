.dropdown {
  position: relative;
  width: 320px;
}

.selected {
  display: flex;
  padding: var(--spacing-sm, 6px);
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Brand-Colors-Secondary-Moonstone-200, #e2fbfb);
  max-width: fit-content;
  overflow: hidden;
  color: var(
    --neutral-colors-Gray-neutral-500,
    var(--colors-gray-light-mode-500, #667085)
  );
  text-overflow: ellipsis;
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}

.optionsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: 14px;
  margin-top: 5px;
  border-radius: 12px;
  border: 1px solid var(--colors-gray-light-mode-100, #f2f4f7);
  background: var(--Colors-Base-white, #fff);
  box-shadow: 0px 12px 24px -8px rgba(16, 24, 40, 0.2);
  padding: 16px;
}

.search {
  display: flex;
  height: 36px;
  padding: 0 12px 0 36px;
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  margin: 0 auto 12px;
  width: 100%;
}

.options {
  max-height: 200px;
  overflow-y: auto;
}

.option {
  display: flex;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  cursor: pointer;
}

.icon_selected {
  height: 20px;
  width: 20px;
  svg {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.active_option {
  display: flex;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--colors-gray-light-mode-50, #f9fafb);
  border-radius: 8px;
}
.check {
  margin-left: auto;
}
.option_left {
  display: flex;
  align-items: center;
  gap: 8px;
}
.option_text {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.option_description {
  color: var(
    --neutral-colors-Gray-neutral-600,
    var(--colors-gray-light-mode-600, #475467)
  );

  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.text_container {
  display: flex;
  flex-direction: column;
}

.modal_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  color: var(
    --neutral-colors-Gray-neutral-700,
    var(--colors-gray-light-mode-700, #344054)
  );
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: var(
      --neutral-colors-Gray-neutral-500,
      var(--colors-gray-light-mode-500, #667085)
    );
  }
}

.search_icon {
  position: absolute;
  z-index: 999;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.disabled_option {
  pointer-events: none;
  opacity: 0.5;
}
