.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 140px;
  @media (max-width: 1440px) {
    padding: 0 95px;
  }
  &__container {
    display: flex;
    align-items: center;
    gap: 6px;
    &__step {
      height: 8px;
      flex: 1 0 0;
      border-radius: 999px;
      background: var(--Colors-Base-white, #fff);
      width: 26px;
    }
    .active {
      background-color: #1d2939;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    button {
      display: flex;
      width: 40px;
      height: 40px;
      padding: var(--spacing-sm, 6px) var(--spacing-lg, 12px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 9999px;
      border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
      background: var(--Colors-Base-white, #fff);
      border: none;
    }
  }
}
