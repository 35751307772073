.ModalFooter {
  padding: 0px 16px 16px 16px;
  border: none;
}
.Button {
  padding: 10px 18px;
  border-radius: 8px;
}
.Button__light {
  border: 1px solid #d0d5dd;
}
