.tabs_container {
  display: flex;
  gap: 12px;
}

.tab {
  display: flex;
  padding: var(--spacing-2xl, 20px) var(--spacing-xl, 16px);
  align-items: start;
  gap: var(--spacing-xl, 16px);
  flex: 1 0 0;
  border-radius: 8px;
  border: 1.5px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  cursor: pointer;
  &.disabled {
    pointer-events: none; // Prevent clicking
    opacity: 0.5; // Visually indicate disabled state
  }
}

.active_tab {
  border: 1.5px solid var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
  background: var(--Brand-Colors-Secondary-Moonstone-100, #f2fbfb);
}

.tab_data {
  display: flex;
  flex-direction: column;
  gap: 4px;
  label {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  p {
    color: var(
      --neutral-colors-Gray-neutral-500,
      var(--colors-gray-light-mode-500, #667085)
    );
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.radioButton {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #d0d5dd;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  cursor: pointer;
  &:checked {
    background-color: #00a7b7;
    border: 1px solid #00a7b7;
    box-shadow: inset 0 0 0 4px #fff;
    height: 20px;
    width: 20px;
  }
}
