.dropbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0 8px 8px 0;
  button {
    width: 40px;
    height: 40px;
    &:focus {
      color: #fff !important;
    }
  }
}

.button {
  display: flex;
  padding: 10px 14px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.option {
  display: flex;
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  background: var(--neutral-colors-black-white-white, #fff);
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.modal_container {
  position: relative;
}

.modal_class {
  position: absolute;
  z-index: 10;
  display: inline-flex;
  padding: var(--spacing-xl, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xl, 16px);
  border-radius: 8px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  box-shadow: 0px 12px 24px -8px rgba(16, 24, 40, 0.2);
  min-width: 303px;
  right: 0;
}
