.SearchInputWrapper {
  display: flex;

  font-weight: 700;
  font-size: 14px;
  color: #182230;
  font-family: 'DM Sans';
  cursor: pointer;

  &_Handler {
    display: flex;
    align-items: center;

    &:hover {
      color: #00a7b7;
    }
  }

  input {
    height: fit-content;
    font-size: 14px;
    padding: 0 0.5rem;
    border: none;
  }

  input:focus,
  input:focus-visible {
    box-shadow: none;
    border: none;
  }
}
