.contact_us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 50px;
  padding-top: 60px;

  @media (min-width: 1200px) {
    padding-top: 120px;
    margin: 0 auto 0 auto;
  }
  // mobile
  @media (max-width: 768px) {
    gap: 10px;
  }
  &__heading {
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 64px;
    line-height: 71.68px;
    text-align: center;
    text-transform: capitalize;
    color: #1d2939;
    letter-spacing: -4%;
    text-wrap: nowrap;
    @media (max-width: 768px) {
      font-size: 48px;
    }
  }

  &__cta {
    color: #334155;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.3px;
    font-family: 'DM Sans';
  }

  &__btn {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
    color: var(--Colors-Text-text-white, #fff);
    text-align: center;
    font-family: 'DM Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    border: none;
    min-width: max-content;
    margin-top: 10px;
    text-transform: uppercase;
  }
}
