.TabWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 136px;
  padding: 16px;
  border-radius: 12px;
  background: #f2fbfb;
}

.tabTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: transparent;
  justify-content: center;
  align-items: center;

  svg {
    filter: saturate(100%) brightness(50%) contrast(0%);
  }

  p {
    font-family: 'Inter';
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #667085;
  }
}

.TitleImage {
  transition: all 0.5s;
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 32px;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentWrapper {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  max-height: 319px;
  overflow-y: auto;
}

.ModalFooter {
  padding: 0px 16px 16px 16px;
  border: none;
}

.Button {
  padding: 10px 18px;
  border-radius: 8px;
}

.Button__light {
  border: 1px solid #d0d5dd;
}
