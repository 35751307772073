@import '/src/styles/colors/primary.scss';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  background: linear-gradient(
    90deg,
    rgba(0, 167, 183, 0.16) 0.83%,
    rgba(248, 200, 100, 0.18) 99.98%
  );

  &_Step {
    width: 100%;
    padding: 8px 1rem;

    &[data-step='active'] {
      background-color: white;
      border-radius: 6px;
      box-shadow: 0px 8px 16px -4px #1018281f;
    }

    h5 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      font-family: 'Poppins';
      color: gray(900);
    }

    p {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: 'DM Sans';
      color: gray(500);
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }

    &_Completed {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d1fadf;
      border-radius: 50%;
      width: 20px;
      height: 20px;
    }
  }
}
