.breadcrumb {
  display: flex;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: 999px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  margin-bottom: 0 !important;
  & .breadcrumb_item + .breadcrumb_item::before {
    content: '' !important;
    width: 16px;
    height: 16px;
    background-image: url('../../assets/svg/breadcrumb_sep.svg') !important;
    background-position: center;
    background-size: cover;
    margin-top: 2px;
    margin-right: 6px;
  }
}

.breadcrumb_item {
  text-align: center;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  a {
    color: #101828 !important;
  }
}
.active_breadcrumb_item {
  a {
    color: #00a7b7 !important;
  }
}
