.AIPlanLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 68px;
  z-index: 10;
  background-color: white;
  padding: 20px 0 10px 0;
}

.AIPlanLayout_new {
  position: sticky;
  top: -68px !important;
  margin-bottom: 10px;
  padding: 0 8px;
}

.AIPlanTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  font-family: 'Poppins';
}

.aiFinishedText {
  max-width: 728px;
  text-align: justify;
  text-justify: inter-word;
  padding-bottom: 32px;
}

.approvalMenu {
  padding: 32px 16px 48px 16px;
  border-width: 1px 0 0 0;
  display: flex;
  flex-direction: column;
  bottom: 0;
  z-index: 10;
  background-color: white;
  width: 100%;
}
.approvalMenu_footer {
  bottom: 9px;
}

.approvalMenuTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  font-family: 'Poppins';
}

.approvalMenuDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 4px;
  margin-bottom: 20px;
  font-family: 'DM Sans';
}

.approvalMenuButtons {
  display: flex;
  gap: 24px;
}

.cardsTable {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: 1fr;
  grid-auto-flow: row dense;
  gap: 24px;
  padding-bottom: 50px;
  // min-height: 485px;
}

.ProjectDetail {
  &_titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 24px 8px;
    border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);

    &_arrow {
      cursor: pointer;
      width: 16px;
    }

    &_title {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      width: 100%;
    }

    &_description {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      color: #667085;
      font-size: 12px;
      line-height: 20px;

      width: 75%;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @supports (-webkit-line-clamp: 1) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    &_editors {
      cursor: pointer;
      display: flex;
      height: fit-content;
      margin-right: 1rem;
    }

    &_editorCount {
      width: 32px;
      height: 32px;
      color: #98a2b3;
      position: relative;
      background: #f9f5ff;
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 200px;
      color: #7f56d9;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
    }

    &_editorAdd {
      cursor: pointer;
      width: 32px;
      height: 32px;
      color: #98a2b3;
      margin-top: 4px;

      border-radius: 200px;
      border: 1px dotted #d0d5dd;

      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (min-width: 768px) {
      &_titleContainer {
        padding: 24px;
      }
    }
  }
}
