.thankYouWrapper {
  max-width: 528px;
  border-radius: 32px;
  border: 1px solid #fef1dc;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
  &__footer {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 8px;
    color: var(
      --neutral-colors-Gray-neutral-500,
      var(--colors-gray-light-mode-500, #667085)
    );
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    &__icon {
      height: 16px;
      width: 16px;
      transform: rotate(180deg);
    }
  }
}

.heading {
  margin-top: 28px;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  text-align: center;
  font-family: 'Poppins';
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.desc {
  color: var(
    --neutral-colors-Gray-neutral-600,
    var(--colors-gray-light-mode-600, #475467)
  );
  text-align: center;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(
    --neutral-colors-Gray-neutral-700,
    var(--colors-gray-light-mode-700, #344054)
  );
  font-family: 'DM Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.outer_card {
  padding: 48px 8px 8px 8px;
  border: 1px solid #fef1dc;
  background-color: #fff9f0;
  border-radius: 16px;
  width: 100%;
  text-align: center;
  margin-top: 32px;

  p {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  span {
    color: var(
      --neutral-colors-Gray-neutral-700,
      var(--colors-gray-light-mode-700, #344054)
    );
    text-align: center;
    font-family: 'DM Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .inner_card {
    background-color: #fff;
    border: 1px solid #fef1dc;
    padding: 16px;
    border-radius: 12px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: var(
          --neutral-colors-Gray-neutral-600,
          var(--colors-gray-light-mode-600, #475467)
        );
        font-family: 'DM Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      span {
        color: var(
          --neutral-colors-Gray-neutral-900,
          var(--colors-gray-light-mode-900, #101828)
        );
        font-family: 'DM Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

.button_section {
  max-width: 528px;
  margin: 24px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
