.score_card {
  display: flex;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--Brand-Colors-Secondary-Moonstone-100, #f2fbfb);
  &__heading {
    color: var(
      --neutral-colors-Gray-neutral-600,
      var(--colors-gray-light-mode-600, #475467)
    );
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  &__score {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}
