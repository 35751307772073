.ModalTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #2c3e50;
}
.ProfileFormHeading {
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}
.innerModalSection {
  font-family: 'Inter';
  font-weight: 400;
  width: 680px !important;
  min-height: 320px !important;
  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
  border: none !important;
}
.profileImage {
  width: 96px;
  height: 96px;
  border: 4px solid #fff;
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 200px;
  object-fit: cover;

  &_Input {
    z-index: 99;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 200px;
    cursor: pointer;
    opacity: 0;
    &_text {
      z-index: 1;
      opacity: 0.5;
      font-size: 12px;
      font-family: 'Inter';
      font-weight: 600;
      background-color: #ffffff;
    }
  }
}
.tabFlexWrapper {
  gap: 24px;
}
.ProfileModalBody {
  padding: 24px 24px 86px 24px;
}
.ProfileModalFooter {
  border-top: none;
  padding: 0px 24px 24px 24px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.modalFooterBtn {
  height: 40px;
  border-radius: 8px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  &_Light {
    background-color: transparent;
    color: #000;
    border-color: #e5e6e7;
    &:hover {
      background-color: transparent;
    }
  }
  &_Dark {
    background-color: #000;
    color: #fff;
    border-color: #000;
    &:active {
      background-color: #000;
      color: #fff !important;
      border-color: #000 !important;
    }
  }
}
.ProfileImageSection {
  width: fit-content;
}

.btn_container_add_card {
  position: absolute;
  bottom: 30px;
  right: 20px;
}
