.block_section {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
  position: relative;
  &__btn {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    background-color: white;
  }
}

.divider {
  width: 1px;
  height: 24px;
  background: var(--colors-gray-light-mode-200, #eaecf0);
}

.ai_assistance_box {
  position: relative;
}

.content {
  max-height: 226px;
  overflow-y: auto;
}

.assitance_box {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  // min-width: 600px;
  padding: var(--spacing-lg, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  box-shadow: 0px 8px 16px -4px rgba(16, 24, 40, 0.12);
  @media (max-width: 1440px) {
    left: -180px;
  }

  &__header {
    display: flex;
    gap: 8px;
    &__heading {
      color: var(
        --neutral-colors-Gray-neutral-600,
        var(--colors-gray-light-mode-600, #475467)
      );
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
    &__desc {
      overflow: hidden;
      color: var(
        --neutral-colors-Gray-neutral-600,
        var(--colors-gray-light-mode-600, #475467)
      );
      text-overflow: ellipsis;
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      flex: 1 0 0;
    }
  }
  &__input_section {
    display: flex;
    gap: 8px;
    &__icon {
    }
    &__input {
      input {
        overflow: hidden;
        color: #101828;
        text-overflow: ellipsis;
        font-family: 'DM Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border: none;
        min-width: 430px;
        &::placeholder {
          opacity: 1;
          color: #98a2b3;
        }

        &::-ms-input-placeholder {
          color: #98a2b3;
        }
      }
    }
  }
  &__drop_section {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__btns {
      display: flex;
      align-items: center;
      gap: 14px;
      &__send {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
        border: none;
      }
      &__dots {
        border: none;
        background: none;
      }
      &__window {
        border: none;
        background: none;
      }
    }
  }
}
