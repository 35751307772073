.Modal {
  min-width: 100%;
}

.modalFooterBtn {
  height: 40px;
  border-radius: 8px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  &_Light {
    background-color: transparent;
    color: #000;
    border-color: #e5e6e7;
    &:hover {
      background-color: transparent;
    }
  }
  &_Dark {
    background-color: #000;
    color: #fff;
    border-color: #000;
    &:active {
      background-color: #000;
      color: #fff !important;
      border-color: #000 !important;
    }
  }
}
.profileImage {
  width: 96px;
  height: 96px;
  border: 4px solid #fff;
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 200px;
  object-fit: cover;
  &_Input {
    z-index: 99;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 200px;
    cursor: pointer;
    opacity: 0;
    &_text {
      z-index: 1;
      opacity: 0.5;
      font-size: 12px;
      font-family: 'Inter';
      font-weight: 600;
      background-color: #ffffff;
    }
  }
}
.ProfileImageSection {
  width: fit-content;
}
.GroupedInputs {
  justify-content: flex-start;
  max-width: 100%;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-top: 4px;
  flex-wrap: nowrap;
}
.Input__Icon {
  background-color: transparent;
  border: none;
}
.InviteInputSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  input {
    border: none;
    width: 363px;
  }
}
.SelectLabel {
  padding: 10px 14px;
  p,
  span {
    color: #101828;
  }
}
.fontsPoppins {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

@media (min-width: 768px) {
  .Modal {
    min-width: 680px;
  }
}
