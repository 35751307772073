@import '/src/styles/functions/@mixin-module';
@import '/src/styles/variables/colors';

.ScoreWrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 32px;
  border-bottom: 1px solid #f2f4f7;

  p {
    @include text-md;
    color: #344054;
  }
}

.progressBarWrapper {
  svg {
    height: 79px;
    width: 79px;
  }
}

.progressBar {
  &-text {
    font-size: 29px;
  }
}

.progressBarModal {
  text {
    fill: #2c3e50 !important;
    font-size: 18px !important;
    font-weight: 700;
    font-family: 'Inter';
  }
}

.ScoreProperties {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &_card {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 8px;

    &_property {
      display: flex;
      align-items: center;
      gap: 4px;
      p {
        @include text-sm-lh18;
        color: font_clr(d-grey-700);
      }
    }
  }

  &_Heading {
    color: font_clr(d-grey-800);
  }
}
