%StarPosition {
  &[data-position='absolute'] {
    display: flex;
    align-items: center;

    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }

  &[data-position='flex'] {
    display: flex;
    align-items: center;
  }
}

.Star {
  @extend %StarPosition;
  cursor: pointer;

  svg:hover {
    fill: #ffcf51;
    stroke: #ffcf51;
  }

  &_Active {
    // @extend %StarPosition;
    cursor: pointer;

    svg {
      fill: #ffcf51;
      stroke: #ffcf51;
    }
  }
}
