@import '/src/styles/colors/primary.scss';
@import '/src/styles/base/typography';
@import '/src/styles/variables/colors';
@import '../../styles/functions/@mixin-module';

.FolderViewCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // min-width: 258px;
  border-radius: 12px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--neutral-colors-black-white-white, #fff);
  padding: 12px;
  cursor: pointer;

  &:hover {
    border-radius: 12px;
    border: 1px solid #fddcab !important;
    background: var(--Colors-Base-white, #fff);
    box-shadow: 0px 8px 16px -4px rgba(16, 24, 40, 0.12);
  }

  &:hover {
    border: 1px solid gray(200);
  }

  &_Details {
    font-family: 'DM Sans';
    display: flex;
    gap: 0.5rem;

    &_Info {
      display: flex;
      flex-direction: column;
    }

    &_Name {
      min-width: 135px;
      max-width: 140px;
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      word-break: break-word;
    }

    &_Description {
      color: var(
        --neutral-colors-Gray-neutral-500,
        var(--colors-gray-light-mode-500, #667085)
      );
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.listItem_sm {
  gap: 10px;
  max-width: 187px;
  align-items: center;

  p {
    @include text-md;
    color: #101828;
  }
  img {
    width: 14px;
  }
}
