.Image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.box_container {
  display: flex;

  @media (min-width: 1200px) {
    padding: 83.82px 92px 83.18px 92px;
  }
  justify-content: space-between;
  align-items: center;
  border-radius: var(--spacing-3xl, 24px);
  border: 1px solid var(--Brand-Colors-Secondary-Sunset-500, #fddcab);
  background: #fff9f0;
  margin: 0 20px;
  gap: 32px !important;
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    padding: 83.82px 88px;
    gap: 62px;
  }
  @media (max-width: 625px) {
    flex-direction: column-reverse;
    padding: 1.5rem;
  }
  &__left_container {
    width: 632px;
    @media (max-width: 768px) {
      width: 100%;
    }
    &__heading {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'Poppins' !important;
      // desktop
      @media (min-width: 1200px) {
        font-size: 32px;
        line-height: 40px !important;
      }
      font-size: 32px;
      line-height: 40px !important;

      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.96px;
    }
    &__description {
      color: var(
        --neutral-colors-Gray-neutral-600,
        var(--colors-gray-light-mode-600, #475467)
      );
      font-family: 'DM Sans' !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin-top: 20px;
    }
    &__btn {
      display: flex;
      padding: 16px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-left: auto;
      margin-right: auto;
      // desktop
      @media (min-width: 1200px) {
        margin-left: 0;
        margin-right: 0;
      }
      align-self: stretch;
      border-radius: 8px;
      background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
      color: var(--Colors-Text-text-white, #fff);
      font-family: 'Inter' !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      border: none;
      margin-top: 48px;
      max-width: fit-content;
    }
  }

  &__video_section {
    width: 522px;
    height: 557px;
    border-radius: 16px;
    border: 1px solid var(--Brand-Colors-Secondary-Sunset-300, #feeacd);
    background: var(--neutral-colors-black-white-white, #fff);
    @media (max-width: 625px) {
      width: 100%;
      height: 100%;
    }
    &__img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
}
