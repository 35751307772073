.card {
  max-width: 1104px;
  border-radius: 8px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  background: #fff;
  &_HoverIcon {
    display: none;
    color: #00a7b7;
  }
  &:hover {
    cursor: pointer;
    .card_HoverIcon {
      display: flex;
    }
  }
}
.card_onboarding {
  max-width: 1104px;
  border-radius: 8px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  background: #fff;
  &_HoverIcon {
    display: none;
    color: #00a7b7;
  }
  &:hover {
    cursor: pointer;
  }
}

.upperCard {
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px 24px 14px;
  // min-height: 168px;
}

.title {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-right: 8px;
  font-family: 'Poppins';
  font-weight: 600;
}
.content_type {
  color: #6c737f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Poppins';
}
.description {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 8px;
}

.iconGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
  padding-bottom: 8px;
}

.priorityTag {
  border-radius: 16px;
  display: flex;
  height: 24px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: 'DM Sans';
}
.highPriority {
  background: #fef3f2;
  color: #f04438;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
}
.mediumPriority {
  border-radius: 12px;
  background: #fffaeb;
  color: #f79009;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
}
.lowPriority {
  border-radius: 12px;
  border-radius: 16px;
  background: #ecfdf3;
  color: #17b26a;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
}

.groups {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
}

.actionsGroup {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #98a2b3;
  padding: 14px 24px;
  display: flex;
  justify-content: space-between;
}

.card_divider {
  width: 90%;
  margin: 0 auto;
  border-radius: 12px;
  border-top: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
}

.creater_link {
  color: #00a7b7;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.assign_btn {
  color: #1d2939;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.User_icon {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background: #f2fbfb;
}
