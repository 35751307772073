.modalFooterBtn {
  height: 40px;
  border-radius: 8px;
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  &_Light {
    background-color: transparent;
    color: #000;
    border-color: #e5e6e7;
    &:hover {
      background-color: transparent;
    }
  }
  &_Dark {
    background-color: #00a7b7;
    color: #fff;
    border-color: #00a7b7;
    &:active {
      background-color: #00a7b7;
      color: #fff !important;
      border-color: #00a7b7 !important;
    }
  }
}

.no_focus {
  margin-bottom: 5px;
  &:focus {
    border-color: #00a7b7;
    box-shadow:
      0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px #00a8b718;
  }
}

.labelClass {
  color: var(
    --neutral-colors-Gray-neutral-700,
    var(--colors-gray-light-mode-700, #344054)
  );
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.add_coupan_btn {
  color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: none;
  background: transparent;
}

.title_email {
  color: var(--Brand-Colors-Primary-Oxford-900, #1d2939);

  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
