.avatar_group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar {
  position: relative;
}
.avatar:not(:first-child) {
  margin-left: -8px;
}
.avatar_sm:not(:first-child) {
  margin-left: -8px;
}
.avatar_lg:not(:first-child) {
  margin-left: -12px;
}
.avatar img {
  width: 32px;
  height: 32px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  border: 1.5px solid #fff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}
.avatar_sm img {
  width: 24px;
  height: 24px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  border: 1.5px solid #fff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}
.avatar .avatar__img {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 50%;
  border: 1.5px solid #fff;
  // box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}
.avatar_sm .avatar__img {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 50%;
  border: 1.5px solid #fff;
  // box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}
.avatar_lg .avatar__img {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 50%;
  border: 1.5px solid #fff;
  // box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}

.avatar p {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 50%;
  border: 1.5px solid #fff;
  background-color: #fff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
  color: #00a7b7;
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.avatar_sm p {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 50%;
  border: 1.5px solid #fff;
  background-color: #fff;
  // box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
  color: #344054;
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
.avatar_lg p {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #1d2939;
  color: var(
    --neutral-colors-black-white-white,
    var(--Colors-Base-white, #fff)
  );
  text-align: center;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.avatar_heading {
  color: #101828;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}

.avatar_name {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  text-align: center;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-left: 6px;
}

.avatar_description {
  color: var(
    --neutral-colors-Gray-neutral-500,
    var(--colors-gray-light-mode-500, #667085)
  );
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.avatar_sm {
  svg {
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }
}
