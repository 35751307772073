.form1_wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  margin-bottom: 32px;
  &__btn {
    color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: none;
    background-color: transparent;
    margin-top: 8px;
  }

  &__analyze_btn {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--Brand-Colors-Primary-Oxford-900, #1d2939) !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(
      --neutral-colors-black-white-white,
      var(--Colors-Base-white, #fff)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    height: 40px;
    width: 88px;
    &:hover,
    &:focus,
    &:active,
    &:visited {
      background: var(--Brand-Colors-Primary-Oxford-900, #1d2939) !important;
      color: var(
        --neutral-colors-black-white-white,
        var(--Colors-Base-white, #fff)
      ) !important;
    }
    &:disabled {
      background: #e4e4e4;
      color: #fff;
      border-color: #d0d5dd;
    }
  }

  &__undo_redo {
    display: flex;
    justify-content: space-between;
    padding: 12px 14px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--colors-gray-light-mode-50, #f9fafb);
    span {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    button {
      color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);

      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      border: none;
      background: transparent;
    }
  }
  &__uploaders {
    display: flex;
    width: 100%;
    &__left {
      display: flex;
      flex-direction: column;
      width: 50%;
      &__label {
        color: var(
          --neutral-colors-Gray-neutral-900,
          var(--colors-gray-light-mode-900, #101828)
        );
        font-family: 'Poppins';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 6px;
      }
      &__image_container {
        display: flex;
        align-items: center;
        gap: 12px;
        &__text {
          display: flex;
          flex-direction: column;
          color: var(
            --neutral-colors-Gray-neutral-400,
            var(--colors-gray-light-mode-400, #98a2b3)
          );
          font-family: 'DM Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
        }
      }
    }
  }
}

.form2_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}

.form3_wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
  &__field {
    display: flex;
    align-items: center;
    gap: 8px;
    &__button {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
      background: var(--Colors-Base-white, #fff);
      color: #667085;
    }
  }
  &__another_field_btn {
    display: flex;
    align-items: center;
    gap: var(--spacing-md, 8px);
    align-self: stretch;
    color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: fit-content;
    border: none;
    background: transparent;
  }
}
.form4_wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 32px;
  &__tags_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__label {
      color: var(
        --neutral-colors-Gray-neutral-800,
        var(--colors-gray-light-mode-800, #182230)
      );
      font-family: 'DM Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &__outer {
      display: flex;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;

      &__tag {
        display: flex;
        padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
        gap: 8px;
        align-items: center;
        border-radius: 8px !important;
        border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
        background: var(--neutral-colors-black-white-white, #fff);
      }
    }
  }

  &__timeframe {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
    &__label {
      color: var(
        --neutral-colors-Gray-neutral-800,
        var(--colors-gray-light-mode-800, #182230)
      );
      font-family: 'DM Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &__dates {
      display: flex;
      gap: 16px;
      width: 100%;
      & > div {
        width: 100%;
        & > div {
          width: 100%;
        }
      }
    }
  }
}

// Business Summary
.textarea_wrapper {
  padding: 16px;
  border-radius: var(--spacing-md, 8px);
  background: var(--Brand-Colors-Secondary-Periwinkle-100, #ecf2ff);

  &__label {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--colors-gray-light-mode-25, #fcfcfd);
    margin-bottom: 16px;
    width: 100%;
  }
  &__tags {
    display: flex;
    height: 40px;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);
  }
}

.tag_red {
  background: var(--Semantic-Colors-Red-100, #fee4e2);
}

.tag_green {
  background: var(--Colors-Success-100, #dcfae6);
}

.tag_purpel {
  background: var(--Brand-Colors-Secondary-Periwinkle-100, #ecf2ff);
}

.tag_orange {
  background: var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
}

// card Backgrounds

.bg_purpel {
  background: var(--Brand-Colors-Secondary-Periwinkle-100, #ecf2ff);
}
.bg_yellow {
  background: var(--Colors-Warning-50, #fffaeb);
}
.bg_green {
  background: #e2fbfb;
}
.bg_green_light {
  background: var(--Brand-Colors-Secondary-Moonstone-100, #f2fbfb);
}
.bg_green_light_2 {
  background: var(--Colors-Success-50, #ecfdf3);
}

.progress_wraper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Colors-Base-white, #fff);
}

// Board

@keyframes background-slide {
  from {
    background: var(--Colors-Success-50, #ecfdf3);
  }
  to {
    background: var(--Colors-Success-200, #abefc6);
  }
}

.board {
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__heading {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 24px;
  }
  &__labels {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__label {
      display: flex;
      padding: var(--spacing-xs, 4px) var(--spacing-lg, 12px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 999px;
      background: var(--Colors-Success-50, #ecfdf3);
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      background-size: 200% 100%;
      background-position: right left;
      transition: all 1s ease-out;
      &.active {
        animation: background-slide 2s forwards;
        background-position: left right;
        .board__labels__label__icon {
          visibility: visible;
        }
      }
      &__icon {
        visibility: hidden;
      }
    }
  }
  &__timer {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    text-align: center;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 24px;
  }
}

.field_wraper {
  width: 1700px !important;
}
.field_wraper > div {
  width: 1900px !important;
}

.button_margin_top {
  margin-top: 84px;
}

.step3_button {
  margin-top: 20px;
}

.giff_img {
}

.input_content {
  display: flex;
  padding: 10px 14px;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  &:focus {
    border-color: #00a7b7 !important;
    box-shadow:
      0 1px 2px #1018280d,
      0 0 0 4px rgba(0, 168, 183, 0.082) !important;
  }
}

.heading_topics {
  color: var(
    --neutral-colors-Gray-neutral-800,
    var(--colors-gray-light-mode-800, #182230)
  );
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
