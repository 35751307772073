.IdeateCard {
  display: flex;
  padding: var(--spacing-xl, 16px) var(--spacing-md, 8px) var(--spacing-md, 8px)
    var(--spacing-md, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-md, 8px);
  border-radius: 12px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--neutral-colors-black-white-white, #fff);
  &_Top {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 8px;
    &_Title {
      color: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 40px;

      @supports (-webkit-line-clamp: 2) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    &_Desc {
      color: var(
        --neutral-colors-Gray-neutral-900,
        var(--colors-gray-light-mode-900, #101828)
      );
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 40px;
      margin-bottom: 12px;

      @supports (-webkit-line-clamp: 2) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  &_Bottom {
    padding: 8px;
    width: 100%;
    border-top: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    &__btn {
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding: 2px 0;
      cursor: pointer;
    }
  }
}
