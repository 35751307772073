.notificationToggleContainer {
  @media (min-width: 1024px) {
    position: relative;
  }
}
.toastBodyWrapper {
  position: absolute;
  @media (min-width: 1024px) {
    left: auto;
    right: 0;
    width: 400px;
  }
  left: auto;
  right: 0;
  max-width: 400px;
  max-height: 438px;
  overflow-y: auto;
  top: 51px;
  background-color: #fff;
  // mobile
  @media (max-width: 600px) {
    right: 50%;
    transform: translateX(50%);
  }
}
.ToastHeaderNotifications {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #ffff;
}
.notificationCategory {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-right: 4px;
}
.CategoryWrapper {
  padding: 0px 14px 12px 0px;
}
.textContentSection {
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}
.upgradeBtn {
  background: linear-gradient(63.44deg, #53389e 16.72%, #7f56d9 83.39%);
  padding: 10px 16px !important;
  &:hover {
    background: linear-gradient(63.44deg, #53389e 16.72%, #7f56d9 83.39%);
  }
}
.textContent {
  &::before {
    content: '“';
  }
  &::after {
    content: '”';
  }
}
.verticalLineDivider {
  border: 1px solid;
  position: absolute;
  left: 23px;
  height: 35%;
  top: 76px;
}
.notificationPost {
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #00a7b7;
}
.NotificationsInfo {
  gap: 8px;
}
.ownerName {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.Duration {
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}
.Notification_badge {
  position: absolute;
  background: #f9f5ff;
  color: #6941c6;
  margin-left: 5px;
}
.ToastHeading {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #101828;
}
.NotificationsContent {
  padding-top: 16px;
  padding-bottom: 16px;
  &__Right {
    padding-left: 54px;
  }
  border-bottom: 1px solid #e4e7ec;
  &:last-of-type {
    border-bottom: 0px;
  }
}
.marker {
  font-size: 14px;
  font-family: 'DM Sans';
  font-weight: 500;
  line-height: 20px;
  color: #7f56d9;
}
.NotificationsBody {
  padding: 0px 24px;
}
.notificationOwnerAvatar {
  position: absolute;
  // height: 48px;
  width: 40px;
  padding: 0;
  border-radius: 99px;
}
.activeStatus {
  height: 8px;
  width: 8px;
  background-color: #12b76a;
  border-radius: 50%;
}
