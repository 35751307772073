.ModalHeader {
  border-bottom: 0;
  padding: 1.5rem;

  &_Title {
    color: #2c3e50;
  }
}

.ModalBody {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
