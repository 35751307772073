.FloatingButtonWrapper {
  position: fixed;
  bottom: 6vh;
  right: 4vw;
  z-index: 1000;
  cursor: pointer;

  width: 65px;
  height: 65px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  box-shadow: 0px 12px 16px -4px #1018281a;
}
