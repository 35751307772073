.card_wrapper {
  border-radius: 8px;
  padding: 8px;
  background-color: #334155;
  margin: 16px 0;
  &__profile {
    display: flex;
    align-items: center;
    gap: 12px;
    &__picture {
      height: 36px;
      width: 36px;
      border-radius: 4px;
      & > img {
        border-radius: 4px;
      }
    }
    &__name {
      display: flex;
      flex-direction: column;
      & > p {
        color: #fff;
        font-family: 'DM Sans';
        font-weight: 500;
        font-size: 16px;
        // line-height: 24px;
        margin: 0;
        padding: 0;
      }
      & > span {
        color: #94a3b8;
        font-family: 'DM Sans';
        font-weight: 400;
        font-size: 12px;
        line-height: 16.2px;
      }
    }
  }

  &__divider {
    background-color: #475569;
    height: 1px;
    margin: 8px 0;
  }
  &__search_container {
    position: relative;
    &__input {
      background-color: #1e293b;
      color: #94a3b8;
      font-size: 14px;
      font-weight: 400;
      font-family: 'DM Sans';
      line-height: 20.3px;
      border-radius: 6px;
      padding: 8px 40px 8px 34px;
      border: none;
      width: 100%;
      &::placeholder {
        color: #94a3b8;
        font-size: 14px;
        font-weight: 400;
        font-family: 'DM Sans';
        line-height: 20.3px;
      }
    }
    &__search_icon {
      height: 16px;
      width: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }
    & > button {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid #475569;
      background-color: #334155;
      border-radius: 4px;
      padding: 4px;
      height: 24px;
      width: 24px;
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      font-family: Inter;
      line-height: 16.2px;
    }
  }
}
