@import '../functions/@mixin-module';
@import '/src/styles/colors/primary.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.DashboardHeaderSelect {
  &__placeholder {
    margin-top: -2px;
  }
  &__control {
    border-radius: 16px !important;
    color: gray(700);
    border: 1px solid gray(300) !important;
    min-height: 28px !important;
    box-shadow: none !important;
  }
  &__value-container {
    padding: 0px !important;
  }

  &__option {
    cursor: pointer;
    margin: 5px !important;
  }

  &__menu-list {
    :hover {
      background-color: #e8ecef;
    }
  }
  &__indicator {
    margin-right: 12px;
    padding: 0px !important;
    color: #000 !important;
    margin-bottom: 0px;
    > svg {
      color: gray(700);
      width: 12px;
      height: 12px;
    }
  }
  &__single-value {
    color: gray(800) !important;
    @include text-md;
  }
  &__indicator-separator {
    display: none;
  }
}

.DashboardCardsSelect {
  &__control {
    border-radius: 16px !important;
    color: gray(700);
    background-color: gray(50) !important;
    border: 1px solid gray(300) !important;
    max-height: 28px !important;
    min-height: 28px !important;
    box-shadow: none !important;
    width: 200px;
    padding: 0px 12px;
  }
  &__value-container {
    display: flex;
    justify-content: center;
    padding: 0px !important;
  }

  &__option {
    cursor: pointer;
    margin: 5px !important;
  }

  &__menu-list {
    background-color: gray(50);
    :hover {
      background-color: #e8ecef;
    }
  }
  &__indicators {
    background-color: gray(50);
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
  &__indicator {
    background-color: gray(50);
    margin-right: 12px;
    padding: 0px !important;
    color: #000 !important;
    margin-bottom: 0px;
    > svg {
      color: gray(700);
      width: 12px;
      height: 12px;
    }
  }
  &__single-value {
    color: gray(800) !important;
    @include text-md;
  }
  &__indicator-separator {
    display: none;
  }
}
.DashboardCardsSelect_all_projects {
  &__control {
    border-radius: 16px !important;
    color: gray(700);
    background-color: gray(50) !important;
    border: 1px solid gray(300) !important;
    max-height: 28px !important;
    min-height: 28px !important;
    box-shadow: none !important;
    width: fit-content !important;
    padding: 0px 12px;
  }
  &__value-container {
    display: flex;
    justify-content: center;
    padding: 0px !important;
  }

  &__option {
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 5px !important;
  }

  &__menu-list {
    background-color: gray(50);
    :hover {
      background-color: #e8ecef;
    }
  }
  &__indicators {
    background-color: gray(50);
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
  &__indicator {
    background-color: gray(50);
    margin-left: 8px;
    padding: 0px !important;
    color: #000 !important;
    margin-bottom: 0px;
    > svg {
      color: gray(700);
      width: 12px;
      height: 12px;
    }
  }
  &__single-value {
    color: gray(800) !important;
    @include text-md;
  }
  &__indicator-separator {
    display: none;
  }
}
.drop-down-projects {
  .DashboardCardsSelect {
    &__control {
      border-radius: 16px !important;
      color: gray(700);
      background-color: #fff !important;
      border: 1px solid gray(300) !important;
      max-height: 28px !important;
      min-height: 28px !important;
      box-shadow: none !important;
      width: 200px;
      padding: 0px 12px;
    }
    &__value-container {
      display: flex;
      justify-content: center;
      padding: 0px !important;
    }

    &__option {
      cursor: pointer;
      margin: 5px !important;
    }

    &__menu-list {
      background-color: gray(50);
      :hover {
        background-color: #e8ecef;
      }
    }
    &__indicators {
      background-color: #fff;
      border-top-right-radius: 16px !important;
      border-bottom-right-radius: 16px !important;
    }
    &__indicator {
      background-color: #fff;
      margin-right: 0px;
      padding: 0px !important;
      color: #000 !important;
      margin-bottom: 0px;
      > svg {
        color: gray(700);
        width: 12px;
        height: 12px;
      }
    }
    &__single-value {
      color: #101828 !important;
      @include text-md;
    }
    &__indicator-separator {
      display: none;
    }
  }
}
.Personal-View-Select {
  &__indicator {
    padding: 0 8px 0 0 !important;
  }
  &__value-container {
    padding: 0px;
    &:focus,
    :focus-visible,
    :focus-within {
      box-shadow: none !important;
    }
  }
  &__single-value {
    margin: 0 !important;
    .label__View {
      padding: 0 !important;
    }
    input {
      display: none;
    }
    svg {
      display: none;
    }

    .team_name {
      display: none;
    }
  }
  &__menu {
    width: 100% !important;
    background: #ffffff;
    border: 1px solid gray(100);
    box-shadow:
      0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0 !important;
    overflow: hidden !important;
    min-width: 175px;
    left: auto;
    right: 0;
  }
  &__option {
    background-position: 95% 50% !important;
    .label__View {
      .team_value {
        display: none;
      }
    }
  }
  &__value-container {
    padding-right: 0 !important;
    .label__View {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  &__control {
    border: 1px solid gray(300);
    border-radius: 8px;
    outline: none !important;
    box-shadow: none !important;
    border-color: gray(300) !important;
    height: 40px;
    // min-width: 175px;
    max-width: fit-content !important;
    overflow: hidden;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    &:hover {
      border: 1px solid gray(300);
      border-radius: 8px;
      box-shadow: none !important;
    }
  }
  &__indicator-separator {
    display: none;
  }
  &__indicator {
    svg {
      fill: #000 !important;
      width: 13.8px;
    }
  }

  &__indicators {
    max-height: 44px;
  }
}
.custom-Tab {
  .nav-item {
    .nav-link {
      padding: 8px !important;
    }
  }
}
.custom-Tab.nav-tabs {
  border: none;
}
.Documents-Library-Tabs {
  &.nav-tabs {
    border: none;
    gap: 24px;
    border-bottom: 1px solid #ececec;
  }
}

.templates-tabs-wrapper {
  .tab-content {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
  }
}
.addBtn {
  background: #00a7b7;
  border: 1px solid #00a7b7;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #fff;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  .dropdown-toggle {
    &:active {
      background: #00a7b7 !important;
      color: #fff !important;
    }
  }
  &:active {
    background: #00a7b7 !important;
    color: #fff !important;
  }
  button {
    color: #fff;
    padding: 4px 12px !important;
    border-color: transparent !important;
    &:active {
      background-color: #00a7b7 !important;
    }
  }
  &:hover {
    background: #00a7b7;
    color: #fff;
    border-color: #00a7b7;
  }
  &:disabled {
    background: primary(200);
    color: #fff;
    border-color: primary(200);
  }
}
.dashboardHeaderBtn {
  button {
    height: 36px;
    &::after {
      display: none !important;
    }
  }
}

.copy-link {
  cursor: copy;
}
.fadeIn {
  animation: OpacityBreak 0.7s ease-in-out;
  @keyframes OpacityBreak {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
#suggestions-accordion {
  button {
    &::after {
      height: 8px;
      width: 10px;
      background-image: url('../../../public/Images/chevronDown.svg') !important;
    }
  }
}

.searchBTN {
  &:active {
    svg {
      fill: transparent !important;
      stroke: #000 !important;
    }
  }
}
.link {
  color: #00a7b7;
  cursor: pointer;
  &:hover {
    color: #00a7b7;
  }
}
td {
  .link {
    &::after {
      content: url('../../../public/Images/external-link.svg');
      margin-left: 4px;
    }
  }
}
.rounded-table {
  border-radius: 8px;
  overflow: hidden;
}
.text-wrapper {
  @media screen and (min-width: 340px) and (max-width: 768px) {
    .TextPara {
      margin-top: 24px;
    }
    .TextParaCollab {
      margin-top: 32px;
    }
  }
}
.content-approach-wrapper {
  @media screen and (min-width: 340px) and (max-width: 768px) {
    align-items: center !important;
    text-align: center !important;
    h2 {
      font-size: 19px !important;
      text-align: center !important;
    }
    span {
      font-size: 20px;
      text-align: center !important;
    }
  }
}

.Animate-Heading {
  animation: SlideDown 0.5s ease-in-out;
  @keyframes SlideDown {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.Animate-Op {
  animation: Opacity-Break 0.5s ease-in-out;
  @keyframes Opacity-Break {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.doc-history-item {
  margin: 6px -24px;
  padding: 17px 24px;
  margin-bottom: 2px;
  &.active {
    background-color: #f2fbfb;
  }
}

.version-diff-container {
  color: #484b4e;

  del {
    background: #edc8c8;
  }

  ins {
    background-color: #d2edd4;
    text-decoration: none;
  }

  li {
    display: list-item;
    list-style: initial;
  }
}
.notification-badge {
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  color: primary(50);
  background: primary(700);
  min-width: 18px;
  min-height: 18px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #00a7b7;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 300px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #00a7b7;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow:
    15px 15px 15px 0 rgb(0 0 0 / 10%),
    0 1px 10px 0 rgb(0 0 0 / 5%);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
}

.brief-tags {
  .react-tagsinput-input {
    height: 45px !important;
    width: 100%;
  }
}

.Poppins {
  font-family: 'Poppins', sans-serif !important;
}

// .table-hover>tbody>tr:hover>td{
//   background-color: #F9FAFB;
// }

.table-hover > tbody > tr:hover > * {
  --bs-table-bg-state: #f9fafb;
}
