.Dashboard_header {
  z-index: 999 !important;
  max-height: 68px;
  background-color: #fff;
  position: sticky;
  top: 0;
  padding: 14px 24px;
  font-family: 'DM Sans';
  font-size: 16px !important;
  border-bottom: 1px solid #e4e7ec;
  &__DropdownListItem {
    padding: 10px 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 120px;
    span {
      color: #101828;
    }
  }
}
.AddNewBtn {
  max-height: 36px;
}
