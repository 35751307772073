.Dashboard__Header_Navigation_DocumentsDetails {
  display: flex;
  align-items: center;
  gap: 12px;
  @media screen and (max-width: 1440px) {
    gap: 8px;
  }

  &_Status {
    padding: 16px;
    background: #fff;
    min-width: 264px;
    border-radius: 12px;
    width: 100%;
    @media screen and (max-width: 1440px) {
      min-width: 230px;
      padding: 8px;
      svg {
        height: 35px;
        width: 35px;
      }
    }
    cursor: pointer;

    > * {
      color: inherit;
      text-decoration: none;
      &:hover {
        color: inherit;
      }
    }

    &_Documents {
      background-color: #fff;
      display: flex;
      align-items: center;
      gap: 12px;
      &__card {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        & > strong {
          color: #101828;
          font-family: 'Poppins';
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
        }
        & > span {
          color: #101828;
          font-family: 'DM Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }
  }
}
