@import './../../styles/functions/@mixin-module';
@import './../../styles/variables/colors';
@import '/src/styles/colors/primary.scss';

.text-center {
  text-align: center;
}

.editor {
  position: relative;
  overflow: hidden;
  max-width: 800px;
  padding: 8px;
  margin: 48px auto;
  background-color: #dae0e9;
}

.editor-content {
  width: 100%;
  height: 480px;
  font-size: 18px;
  padding: 8px;
  background-color: transparent;
  box-sizing: border-box;
}

.selections {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 3px;
  margin: 8px;
  overflow: hidden;
  pointer-events: none;
}

.selection-indicator {
  position: absolute;
  background-color: #333;
  width: 2px;
  height: 20px;
}

.selection-flag {
  position: absolute;
  bottom: 18px;
  font-size: 8px;
  height: 10px;
  padding: 0 2px;
}

.editor {
  background: white;
  box-shadow: rgba(14, 23, 31, 0.15) 0px 1px 3px 1px;
  padding: 2em;
  margin-top: 1em;
}

.BtnGroup {
  margin-bottom: 0.5em;
}

.description {
  font-style: italic;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror .comment {
  background-color: #e2fbfb;
}

.version-diff-container {
  p {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #475467;
  }

  strong {
    span {
      font-weight: inherit !important;
    }
  }

  em {
    span {
      font-style: inherit !important;
    }
  }

  span {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #475467;

    span {
      font-family: inherit !important;
      color: inherit !important;
      font-size: inherit !important;
      font-weight: inherit !important;
    }
  }
  h1 {
    font-family: 'Inter' !important;
    font-style: normal;
    font-size: 32px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;

    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }
  h2 {
    font-family: 'Inter' !important;
    font-style: normal;
    font-size: 24px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;

    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }
  h3 {
    font-family: 'Inter' !important;
    font-style: normal;
    font-size: 18.72px;
    line-height: 38px;
    color: #101828;

    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }
}

.ProseMirror {
  background: white;
  border-radius: 0 0 5px 5px;
  font-family: 'Inter' !important;
  max-width: 800px;
  min-width: 800px;
  min-height: 500px;
  border: 0;
  padding: 10px;
  outline: none;
  @media (max-width: 1600px) {
    min-width: 100%;
    width: 100%;
  }

  p {
    font-style: normal;
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'DM Sans' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  strong {
    span {
      font-weight: inherit !important;
    }
  }

  em {
    span {
      font-style: inherit !important;
    }
  }

  span {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'DM Sans' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    span {
      font-family: inherit !important;
      color: inherit !important;
      font-size: inherit !important;
      font-weight: inherit !important;
    }
  }
  h1 {
    font-family: 'Poppins' !important;
    font-weight: 600 !important;
    font-style: normal;
    font-size: 32px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;

    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }
  h2 {
    color: var(--Black, #000);
    font-family: 'Poppins' !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;

    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }
  h3 {
    font-family: 'Inter' !important;
    font-style: normal;
    font-size: 18.72px;
    line-height: 38px;
    color: #101828;

    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }
}

.ProseMirror ul {
  padding-left: 1.2em;
}

.ProseMirror ul li {
  list-style: disc;
}
.ProseMirror ol {
  padding-left: 1.2em;
  list-style: auto;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: 'Inter' !important;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}

.ProseMirror:focus {
  border: none;
}

.ProseMirror > * + * {
  font-family: 'Inter' !important;
}

.ProseMirror code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
  font-family: 'Inter' !important;
  padding: 0;
  font-size: 0.8rem;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: 'Inter' !important;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 3px solid #999999;
  font-family: 'Inter' !important;
}

.ProseMirror hr {
  border: none;
  border-top: 3px solid #999999;
  margin: 2rem 0;
}

.ProseMirror [contenteditable='false'] {
  white-space: normal;
}

/* ............................ */

.editor-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  // padding-bottom: 300px;
  width: 100%;

  ::selection {
    background-color: rgba(192, 232, 255, 255);
    color: rgba(64, 117, 157, 255);
  }

  .second {
    width: 100%;
    transition: 0.4s ease-in;
    border-right: 1px solid #e4e7ec;

    .document-header {
      display: flex;
      height: 48px;
      @media (max-width: 1665px) {
        height: auto;
      }
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      align-self: stretch;
      border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
      background: var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);

      @media screen and (min-width: 340px) and (max-width: 1280px) {
        flex-wrap: wrap;
      }

      row-gap: 16px;
      column-gap: 32px;
      align-items: center;
      padding: 0 24px;
      @media (max-width: 1660px) {
        padding: 10px;
      }

      .document-name {
        padding: 0 10px;

        input {
          border: 1px solid transparent;
          &:focus,
          :focus-visible,
          :focus-within {
            border: 1px solid #e5e5e5;
            border-radius: 4px;
          }
        }

        input :focus {
          border: 1px solid blue;
        }
      }

      .last-changed {
        font-family: 'Inter';
        font-size: 12px;
        line-height: 18px;
        color: #101828;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 8px;
      }
      .flex-d-column {
        @media (max-width: 1300px) {
          flex-direction: column;
        }
      }

      .flex-d-row {
        @media (max-width: 1660px) {
          flex-direction: column;
        }
      }

      .settings-menu {
        margin-right: 10px;

        > * {
          margin: 10px;
        }
      }
    }
  }

  .third {
    width: 330px;
    max-width: 330px;
    min-width: 200px;
    overflow: auto;
    font-family: 'Inter';
    height: calc(100vh - 146px);
    padding-bottom: 100px;
  }

  .full-width {
    height: calc(100vh - 146px);
    overflow-y: auto;
  }

  .width_editor_text_area {
    width: 80%;
    height: calc(100vh - 146px);
    overflow-y: auto;
    @media (max-width: 1440px) {
      width: 72%;
    }
  }

  .container-closed {
    display: none;
  }

  .first-border-left {
    border: 1px solid font_clr(l-grey);
    background-color: #fff;
    z-index: 1;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.text-editor {
  margin-top: 1rem;
  font-family: 'Inter' !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 100px;
}

.menuBar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray(25);
  border-radius: 8px;
  padding: 8px;
  gap: 4px;
  position: sticky;
  top: 0px;
  z-index: 9;
  div {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    span {
      border-left: 1px solid lightgray;
      height: 30px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.menuBar button {
  font-size: 14px;
  outline: none;
  border: none;
  background: none;
  color: rgb(70, 70, 70);
  cursor: pointer;
  border-radius: 8px;
  min-height: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuBar button:last-child {
  margin-right: 7px;
}

.heading3 {
  font-size: 15px;
}

button.is_active {
  background: #f2fbfb;
  svg {
    stroke: #00a7b7;
  }
}

.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.tiptap-editor ::selection {
  background-color: lightblue;
  color: black;
  border: 1px solid black !important;
  padding: 4px !important;
}

.dimension-menu ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: #1d2939;
  background-color: transparent;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #e2fbfb;
  }
}
.comments-list {
  background-color: #fff;
  width: 323px;
  overflow: hidden;
}
.CommentWrapper {
  border-bottom: 1px solid font_clr(l-grey);
  padding: 12px 18px;
}
.Comment {
  max-width: 100%;
  word-break: break-all;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}
.comment-container {
  position: absolute;
  box-shadow: 0px 3px 9px -2px rgba(16, 24, 40, 0.14);
  border-radius: 21px 21px 21px 0px;
  z-index: 9999;
  border-radius: 50px !important;
}
.container_comment {
  border-radius: 50px !important;
  li {
    padding: 15px !important;
  }
}

.profile-Avatar {
  position: relative;
  img {
    position: relative;
    border-radius: 200px 200px 200px 0px;
  }
}
.tiptap-editor-selection {
  background-color: lightblue;
  color: black;
  border: 1px solid black !important;
  padding: 4px !important;
}

.searchbox-wrap {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  .comment-info {
    display: flex;
    flex-direction: column;
    .author-info {
      display: flex;
      font-size: small;
      .author-name {
        font-family: 'Inter';
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
      .comment-date {
        margin-left: 5px;
        font-family: 'Inter';
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #667085;
      }
    }
  }
  .input-field {
    border: 1px solid lightgray;
    margin-top: 10px;
    border-radius: 10px;
    padding: 5px;
    align-items: center;
    input {
      outline: none;
      border: none;
    }
    button {
      border: none;
      padding: 0;
      height: 20px;
    }
    :focus-visible {
      border: 1px solid blue;
    }
  }
}

#tippy-2 {
  width: 700px;
  left: -20px !important;
  z-index: 0 !important;
}

.searchbox-wrap li {
  list-style: none;
}

.addBlock {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}
.add-new {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #98a2b3;
}
#addBlockDropDown {
  padding: 10px 8px;
  &:focus,
  :focus-visible,
  :focus-within {
    border: none;
    outline: none;
  }
}
.dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.form-select {
  display: inline;
  width: auto;
  border: none;
}

.comment-sidebar {
  .comment-header {
    display: flex;
    justify-content: space-between;
    margin: 10px;

    .comment-menu {
      button {
        font-size: 18px;
        margin: 7px;
        margin-right: 15px;
        outline: none;
        border: none;
        background: none;
        color: rgb(70, 70, 70);
        cursor: pointer;
      }
    }
  }

  .comment-list {
    .comment-box {
      border-bottom: 1px solid black;
      padding: 20px;

      .comment-box-header {
        display: flex;
        justify-content: space-between;

        .comment-author {
          display: flex;

          .author-image {
            img {
              width: 40px;
              height: 40px;
            }
          }

          .author-name {
            display: flex;
            flex-direction: column;
            margin-left: 5px;

            :nth-child(2) {
              font-size: small;
              color: gray;
            }
          }
        }

        .comment-menu {
          cursor: pointer;
        }
      }
    }
  }
}

#popover-basic {
  .menu {
    ul {
      li {
        list-style: none;
        cursor: pointer;
        height: 30px;

        :hover {
          color: red;
        }
      }
    }
  }
}

.hide {
  transition: all 0.5s ease-out;
  opacity: 0;
  height: 0;
  padding: 0;
  margin: 0;
  display: none;
}
//////Styles
.accordion-header {
  button {
    @include text-md;
    color: #2c3e50;
  }
}

.starred {
  svg {
    fill: #ffcf51;
    stroke: #ffcf51 !important;
  }
}
.comment-adder-section {
  padding: 4px 12px 12px 12px;
  p {
    color: #344054;
  }
  input {
    padding-right: 34px;
  }
  .btn-close {
    width: 10px;
    height: 10px;
    padding: 0;
    color: #667085;
  }
  input:focus {
    .arrow_circle_input {
      svg {
        rect {
          fill: #7f56d9 !important;
        }
      }
    }
  }
  .arrow_circle_input {
    position: absolute;
    top: 17%;
    right: 7px;
    background: #fff;
    cursor: pointer;
  }
  .arrow_circle_input.activeSVG {
    svg {
      fill: #00a7b7;
    }
  }
}

.circle-picker {
  background-color: white;
}

.bubble-menu-light {
  border: 2px solid var(--color-gray-4);
  background-color: white;
  color: var(--color-gray-2);
  display: flex;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  padding: 5px 10px;
}

.bubble-menu-light button {
  outline: none;
  border: none;
  background: none;
  color: rgb(70, 70, 70);
  cursor: pointer;
  border-radius: 8px;
  min-height: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.bubble-menu-light button:hover {
  background-color: gray;
  color: white;
}

.PhoneInputInput {
  border: none;
}

.title-placeholder {
  font-family: 'Inter';
  min-width: 250px;
  height: 28px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 2px;
}

.title-placeholder:hover {
  border: 1px solid lightgrey;
}

//
.text-container_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // margin-bottom: 226px;
  @media (max-width: 1600px) {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
}

.editor_select_text_formates {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
}
