@import '../../styles/base/fonts';
@import '../../styles/base/typography';
@import '../../styles/variables/colors';
@import '../../styles/functions/@mixin-module';

.Drawer {
  gap: fontsize('s24');
  width: 100%;
  z-index: 1040;
  border-radius: 20px;
  margin: 0.5rem 0.25rem 3.5rem;

  @media (min-width: 992px) {
    min-width: 720px;
    max-width: 750px;
  }

  &_Header {
    padding: 20px 24px;
    border-bottom: 1px solid #e2e8f0;
    font-family: 'Poppins';
    color: var(--Brand-Colors-Primary-Oxford-900, #1d2939);

    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    &_Title  {
      @include Modal-Title;
      color: font_clr(d-grey-800);
    }
  }

  &_Body {
    padding: 0;
    overflow-y: auto;
    max-height: calc(100% - 160px);
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00a7b7;
    }
  }
}
