@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
.container {
  margin: 80px 84px 74px 84px;
  @media (max-width: 768px) {
    margin: 80px 10px 74px 10px;
  }
  &__top {
    border-bottom: 1px solid #e2e8f0;
  }
  &__left {
    padding-right: 120px;
    @media (max-width: 980px) {
      padding-right: 0;
    }
  }
  &__heading {
    margin-top: 12px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    font-family: 'DM Sans';
    color: #64748b;
  }
  &__inputSec {
    position: relative;
    margin-top: 36px;
    input {
      padding: 4px;
      width: 100%;
      border: 1px solid #e2e8f0;
      border-radius: 1000px;
      padding: 16px 220px 16px 24px !important;
      &::placeholder {
        color: #94a3b8;
        font-size: 14px;
        line-height: 20.3px;
        font-weight: 400;
        font-family: 'DM Sans';
      }
    }
    &__button {
      position: absolute;
      right: 3px;
      color: #1d2939;
      background-color: #c7d6fe;
      font-size: 14px;
      font-weight: 600;
      font-family: 'Poppins';
      line-height: 20.3px;
      top: 50%;
      transform: translateY(-50%);
      padding: 16px 24px !important;
      border-radius: 1000px;
      &:hover {
        color: #1d2939;
        background-color: #c7d6fe;
      }
    }
  }
  &__right {
    border-left: 1px solid #e2e8f0;
    padding-left: 48px;
    padding-bottom: 80px;
    @media (max-width: 1200px) {
      border-left: none;
      padding-left: 0;
      padding-top: 40px;
      margin-top: 40px;
      border-top: 1px solid #e2e8f0;
    }
    &__heading {
      color: #94a3b8;
      font-family: 'DM Sans';
      font-weight: 400;
      font-size: 14px;
      line-height: 20.3px;
      margin-bottom: 16px;
    }
    &__links {
      color: #334155;
      font-family: 'DM Sans';
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }
  &__bottom {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-family: 500;
    line-height: 24px;
    font-family: 'DM Sans';
    color: #334155;
    @media (max-width: 625px) {
      flex-direction: column-reverse;
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 24px;
      &__logos {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }
}
