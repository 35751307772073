.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--colors-gray-light-mode-25, #fcfcfd);
  width: 100%;
  border-radius: 10px;
  position: sticky;
  top: 24px;

  &__top {
    padding: var(--spacing-3xl, 12px);
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    &__header {
      display: flex;
      gap: 12px;
      &__icon {
        display: flex;
        width: 60px;
        height: 60px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 7749.225px;
        border: 0.775px solid var(--colors-gray-light-mode-200, #eaecf0);
        background: var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
      }
      &__user {
        display: flex;
        flex-direction: column;
        gap: 2px;
        &__name {
          color: var(
            --neutral-colors-Gray-neutral-900,
            var(--colors-gray-light-mode-900, #101828)
          );
          font-family: 'Poppins';
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
        }
        &__last_updated {
          color: var(
            --neutral-colors-Gray-neutral-600,
            var(--colors-gray-light-mode-600, #475467)
          );
          font-family: 'DM Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }

    &__badge {
      display: flex;
      padding: var(--spacing-md, 8px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: var(--spacing-md, 8px);
      background: var(--Brand-Colors-Secondary-Periwinkle-50, #f4f7ff);

      &__left {
        display: flex;
        align-items: center;
        gap: 8px;
        &__icon {
          display: flex;
          padding: var(--spacing-sm, 6px);
          justify-content: center;
          align-items: center;
          gap: 4.286px;
          border-radius: var(--radius-full, 9999px);
          border: 1px solid var(--Brand-Colors-Secondary-Sunset-200, #fef1dc);
          background: var(--Colors-Base-white, #fff);
        }
        &__text {
          color: var(
            --neutral-colors-Gray-neutral-600,
            var(--colors-gray-light-mode-600, #475467)
          );
          font-family: 'DM Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
      &__right {
        display: flex;
        padding: var(--spacing-lg, 12px);
        align-items: flex-start;
        border-radius: 8px;
        button {
          color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
          font-family: 'Poppins';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          border: none;
          background: transparent;
        }
      }
    }
  }
  &__navigation {
    display: none;
    color: var(
      --neutral-colors-Gray-neutral-700,
      var(--colors-gray-light-mode-700, #344054)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 100%;
    & > li {
      border-bottom: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
      &:last-child {
        border-bottom: none;
        border-radius: 0 0 10px 10px !important;
      }
    }

    &__list {
      list-style: none;
      text-decoration: none;
      color: #344054;
      background: white;
      display: flex;
      padding: var(--spacing-3xl, 24px) var(--spacing-4xl, 32px)
        var(--spacing-3xl, 24px) var(--spacing-3xl, 24px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      cursor: pointer;
      border-radius: 0 0 10px 10px;

      &__item {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      &__arrow {
        visibility: hidden;
      }
    }
    &__list_active {
      background: var(--colors-gray-light-mode-50, #f9fafb);
      display: flex;
      padding: var(--spacing-3xl, 24px) var(--spacing-4xl, 32px)
        var(--spacing-3xl, 24px) var(--spacing-3xl, 24px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 0 0 10px 10px;
      &__item {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .container__navigation__list__arrow {
        visibility: visible;
      }
      .svg_icon > svg > path {
        fill: #00a7b7;
      }
      &:nth-child(3) {
        .svg_icon > svg > path {
          stroke: #fff;
        }

        .svg_icon > svg > circle {
          fill: #00a7b7;
        }
      }
    }
  }

  @media (min-width: 768px) {
    width: 388px;
    &__top {
      padding: var(--spacing-3xl, 24px);
    }
    &__navigation {
      display: block;
    }
  }
}

.drawers_container {
  padding: var(--spacing-xl, 16px);
  border-radius: var(--spacing-lg, 12px);
  background: var(--Brand-Colors-Secondary-Periwinkle-100, #ecf2ff);
  &__label {
    color: var(
      --neutral-colors-Gray-neutral-600,
      var(--colors-gray-light-mode-600, #475467)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;
  }
}

.delete_button {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  background: var(--Colors-Base-white, #fff);
  color: #667085;
}
