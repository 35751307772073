.dropdownMenu {
  padding: 0 8px;
  transition: all 0.5;

  @media screen and (min-width: 340px) and (max-width: 768px) {
    padding: 12px;
  }

  &_Expanded {
    display: flex;
    padding: 0 8px;
    transition: all 0.5s;

    @media screen and (min-width: 340px) and (max-width: 768px) {
      padding: 6px;
    }
  }
}

.sublist {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
  padding-left: 8px;
  background: transparent;
  width: 50%;
  border-left: 1px solid #e4e7ec;

  @media screen and (min-width: 340px) and (max-width: 768px) {
    align-items: flex-start;
    padding: 12px;
    top: 16%;
    height: 84%;
  }
}
