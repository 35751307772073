.OverviewGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 0 24px;

  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  .Info {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    color: var(--gray-500, #667085);

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: flex-start;
      gap: 0.5rem;
      color: #667085;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    svg {
      color: #00a7b7;
    }

    p {
      align-self: flex-start;
      color: var(--gray-900, #101828);
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  > div {
    padding: 24px;
    border-radius: 8px;
    background-color: #f9fafb;
  }

  &_Objectives {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    grid-column: 1 / -1;
  }

  &_KeyMessage {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    grid-column: 1 / -1;
  }
}

.OverviewTop {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(6, 1fr);
  gap: 12px;
  padding: 0 24px;

  font-family: 'DM Sans';
  font-size: 14px;
  line-height: 20px;

  margin-top: 32px;
  margin-bottom: 12px;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3rem;

    padding: 24px;
    border-radius: 8px;
    background-color: #f2fbfb;
    // background-color: var(--blue-50, #eff8ff);
  }

  &_Information {
    grid-column: auto / span 4 !important;
  }

  &_Dates {
    grid-column: auto / span 2 !important;
  }
}

.Info {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  color: var(--gray-500, #667085);

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    gap: 0.5rem;
    color: #667085;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  svg {
    color: #00a7b7;
  }

  p {
    align-self: flex-start;
    color: var(--gray-900, #101828);
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.EditButton {
  display: flex;
  justify-content: flex-end;
  padding: 0 24px;
  margin-top: 12px;
}
