.scroller {
  max-width: 40%;
}
.scroller__inner {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.scroller[data-animated='true'] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}
.scroller[data-animated='true'] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 3s)
    var(--_animation-direction, forwards) linear infinite;
}
.scroller[data-direction='right'] {
  --_animation-direction: reverse;
}
.scroller[data-direction='left'] {
  --_animation-direction: forwards;
}
.scroller[data-speed='fast'] {
  --_animation-duration: 3s;
}
.scroller[data-speed='slow'] {
  --_animation-duration: 3s;
}
@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.tag_list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}
.tag_list li {
  // padding: 1rem;
  background: var(--clr-primary-400);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
}

.gap {
  gap: 6px !important;
}
