@import '../../../styles/variables/colors';
.fixwidthModal {
  max-width: 476px;
}
.headingSection {
  gap: 23px;
}
.modal_img {
  max-width: 151px;
}
.modalBodyWrapper {
  background-color: font_clr('white');
}
.ModalInputSection {
  gap: 16px;
  padding: 32px 0px;
}
.dividerSection {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 8px 0px;
  align-items: center;
  &__line {
    width: 45%;
  }
}
.linkSection {
  gap: 4px;
}
.ModalBody {
  padding: 24px 24px 16px 24px;
  border: none;
}
.ModalInnerWrapper {
  padding-bottom: 24px;
}
.AuthModalHeader {
  border: none;
  padding: 32px 24px;
}
