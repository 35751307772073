.Navbar {
  padding-top: 0.25rem;
  padding-bottom: 1rem;

  // border: 1px solid;
  // border-image-slice: 1;

  // border-top: 0;
  // border-left: 0;
  // border-right: 0;

  // border-image-source: linear-gradient(
  //   90deg,
  //   rgba(255, 255, 255, 0) 20%,
  //   #fddcab 50%,
  //   rgba(255, 255, 255, 0) 80%
  // );

  &_Container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.NavLinks {
  display: flex;
  flex: 1;
  justify-content: center;
  gap: 2rem;
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 400;
  border: 1px solid;
  border-image-slice: 1;

  border-top: 0;
  border-left: 0;
  border-right: 0;

  border-image-source: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 20%,
    #fddcab 50%,
    rgba(255, 255, 255, 0) 80%
  );
  padding-bottom: 7px;
}

.NavActions {
  display: flex;
  gap: 12px;
  position: static;
  @media (min-width: 1200px) {
    position: absolute;
    right: 44px;
  }
}

// Subscription header
.nav_btn {
  padding: 0;
  background-color: transparent;
  color: #1d2939;
  // font-size: 14px;
  // font-weight: 400;
  // line-height: 20.3px;
  // font-family: 'DM Sans';
  &:hover {
    background-color: transparent;
    border: none;
  }
}

.signIn_btn {
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );

  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.signup_btn {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--Brand-Colors-Primary-Oxford-900, #1d2939);
  color: var(
    --neutral-colors-black-white-white,
    var(--Colors-Base-white, #fff)
  );
  text-align: center;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.brand_logo {
  @media (min-width: 1200px) {
    position: absolute;
    left: 44px;
  }
}
