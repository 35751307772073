@import '/src/styles/variables/colors';

.IntegrationBtn {
  position: relative;
  display: inline-block;
  padding: 10px 10px;
  text-align: center;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #007ab9;
    transition: width 0.2s ease;
  }
  transition: transform 0.2s ease;
  &:hover{
    transform: scale(1.2);
  }

}
.linkedinBtn {
  background-color: #0073b1; /* LinkedIn blue color */
  color: white;
  border: none;
  padding: 12px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.linkedinBtn:hover {
  background-color: #005f85; /* Slightly darker blue for hover effect */
}

.linkedinBtn:focus {
  outline: none;
}

.linkedinBtnIcon {
  margin-right: 10px;  /* Space between icon and text */
  width: 20px;
}


.linkedinBtnText {
  flex-grow: 1; /* Center the text */
  text-align: center;
}

